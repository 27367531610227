import { connect } from "react-redux"
import { Link } from "react-router-dom"
import LazyLoadImage from "../LazyLoadImage";
import MessageBox from "../MessageBox";
import PlayButton from "../Button/PlayButton";
const MusicCard = ({ data, PropsType, languageDetailsData, currentPlaying, currentAudio, playlistData }) => {

    return (
        <div className='listContainer position-relative rounded-3 theme-bg-color theme-border-color  w-100 p-2' key={data?.id} style={{ borderStyle: "solid", borderWidth: "1px" }}>
            <div className='position-relative overflow-hidden rounded-2 ' >
                <LazyLoadImage key={data?.title} src={data?.image_url ? data?.image_url : data?.Thumbnail} alt={data?.title} title={data?.title ? data?.title : data?.artist_name} imageSize={`rounded-2  w-100  overflow-hidden   musicDetailsImage object-fit-cover`} classNameOptional={`rounded-2   musicImageDetailsHeight`} />
            </div>
            <Link to={`/${languageDetailsData?.Language_Code}/${PropsType == "AUDIO" ? "track" : PropsType == "PLAYLIST" ? "playlist" : PropsType == "STATION" ? "station" : PropsType == "GENRE" ? "genre" : "artist"}/${data?.artist_slug ? data?.artist_slug : data?.slug}`} ><MessageBox classname='artistsCardTitle theme-text-color mt-2 position-relative toolTipParent' text={data?.artist_name ? data?.artist_name : data?.albumname ? data?.albumname : data?.title ? data?.title : data?.name} /></Link>
            <MessageBox classname='d-block musicDescriptions theme-text-color text-wrap mt-1 opacity-75' text={data?.description ? data?.description : "Lorem In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."} />
            {/* <button onClick={() => handlePlay(PropsType, data?.slug ? data?.slug : data?.artist_slug, data?.id)} className={`position-absolute musicPlayButton theme-button-bg-color mx-auto my-0 rounded-circle d-flex align-items-center justify-content-center ${(PropsType === "AUDIO" ? currentAudio?.id : playlistData?.id) == data?.id ? 'visible' : ""}`}>
                {!currentPlaying || (PropsType === "AUDIO" ? currentAudio?.id : playlistData?.id) !== data?.id ? <IconMyPlay styled={{ width: "20px", height: "20px", className: "triangle" }} /> : <IconMyPause styled={{ width: "20px", height: "20px", className: "doublecode" }} />}
            </button> */}
            <PlayButton type={PropsType} store={data} />
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.musicLanguage.languageDetailsData,
    currentAudio: state.currentMusic.currentAudio,
    currentPlaying: state.currentMusic.currentPlaying,
    playlistData: state.currentMusic.playlistData
})
export default connect(mapStateToProps)(MusicCard)