import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Navigation } from "swiper/modules";
import MusicBannerPanelHorizontal from './MusicBannerPanelHorizontal';
import MusicSuggestion from './MusicSuggestion';
import { MusicDetailsAction } from '../../Redux/Actions/MusicDetailsAction';


const ArtistsDetails = ({ settings, topArtists, playlistShow }) => {
    const { artistname } = useParams()
    const dispatch = useDispatch()
    const [containerWidth, setContainerWidth] = useState(992);
    const containerRef = useRef(null);
    const location = useLocation();

    const calculateSlidesPerView = () => {
        if (containerWidth < 320) { return 1; }
        else if (containerWidth < 450) { return 2; }
        else if (containerWidth < 650) { return 3; }
        else if (containerWidth < 820) { return 4; }
        else if (containerWidth < 992) { return 5; }
        else if (containerWidth < 1400) { return 7; }
        else { return 7; }
    };

    const swiperConfig = {
        slidesPerView: calculateSlidesPerView(),
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: calculateSlidesPerView(),
        modules: [Navigation],
    }

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
            }
        };
        handleResize();
        calculateSlidesPerView()
    }, [playlistShow, containerRef?.current?.clientWidth]);

    useEffect(() => {
        dispatch(MusicDetailsAction('ARTIST', artistname))
    }, [location])

    return (
        <div ref={containerRef} className='pb-5'>
            <MusicBannerPanelHorizontal propsType="ARTIST" />
            <MusicSuggestion swiperOptions={swiperConfig} PropsType="ARTIST" />
        </div>

    )
}
const mapStateToProps = state => ({
    settings: state.settingsDefault.settings,
    topArtists: state.musicDetails.topArtists,
    playlistShow: state.toggleState.playlistShow,
})
export default connect(mapStateToProps)(ArtistsDetails)