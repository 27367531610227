import { PLAYLIST_SUCCESS, PLAYLIST_FAILED } from '../ActionTypes';
const PlaylistReducer = (state = { playlists: [], isLoading: true }, action) => {
    switch (action.type) {
        case PLAYLIST_SUCCESS: return {
            isLoading: false,
            playlists: action.payload
        }
        case PLAYLIST_FAILED: return {
            isLoading: false,
            error: action.payload
        }

        default:
            return state
    }
}

export default PlaylistReducer