import { combineReducers, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import PlaylistReducer from "../Reducers/PlaylistReducer";
import { MusicHomeReducer } from "../Reducers/MusicHomeReducer";
import { MusicSettingsReducer } from "../Reducers/MusicSettingsReducer";
import { MusicCurrentReducer } from "../Reducers/MusicCurrentReducer";
import { MusicDetailsReducer } from "../Reducers/MusicDetailsReducer";
import { MusicViewAllReducer } from "../Reducers/MusicViewAllReducer";
import { MusicLanguageReducer } from "../Reducers/MusicLanguageReducer";
import { MusicDefaultSettingReducer } from "../Reducers/MusicDefaultSettingReducer";
import { CommonThumbnailReducer } from "../Reducers/CommonThumbnailReducer";
import { ToggleReducer } from "../Reducers/ToggleReducers";
import { HeaderReducer } from "../Reducers/HeaderReducer";
import { ProfileDetailsReducer } from "../Reducers/ProfileDetailsReducer";
import { LanguagePreferenceReducer } from "../Reducers/LanguagePreferenceReducer";
const finalReducers = combineReducers({
    playlists: PlaylistReducer,
    toggleState: ToggleReducer,
    homeLists: MusicHomeReducer,
    musicSettings: MusicSettingsReducer,
    currentMusic: MusicCurrentReducer,
    musicDetails: MusicDetailsReducer,
    musicViewAll: MusicViewAllReducer,
    musicLanguage: MusicLanguageReducer,
    settingsDefault: MusicDefaultSettingReducer,
    commonThumbnail: CommonThumbnailReducer,
    headerDetails: HeaderReducer,
    profileDetails: ProfileDetailsReducer,
    languageList:LanguagePreferenceReducer
});
const initialState = {};
const composeEnhancers = composeWithDevTools({});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["currentMusic", "playlists"],
};
const persistedReducer = persistReducer(persistConfig, finalReducers);
const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
);

let persistor = persistStore(store);
persistor.subscribe(() => {
    store.dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: false });
    const state = store.getState();
    if (state.currentMusic.currentAudio?.id) {
        store.dispatch({ type: "OPEN_PLAYLIST", payload: true });
    }
});

export { persistor, store };