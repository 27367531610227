export const MusicDetailsReducer = (state = { songDetailsData: [], topArtists: null, otherAudios: null, otherPlaylist: null, settings: null, thumbnailSettings: null, currency: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_SONG_DETAILS_ACTION":
            return {
                ...state,
                songDetailsData: action.payload,
                topArtists: action.TopArtist,
                otherAudios: action.otherAudios,
                otherPlaylist: action.otherPlaylist,
                settings: action.payload.setting,
                thumbnailSettings: action.payload.ThumbnailSetting,
                currency: action.payload.currency,
                isLoading: false
            };
        case "GET_SONG_DETAILS_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};