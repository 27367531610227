import axios from "axios";
import { getItem, getItemToken } from "../../Utils/localStorageUtils";
import { apiGenerate, demoUser } from "../../Method/CommonMethod";

export const MusicViewAllAction = (type, api, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${api}`, {
            Login_user_type: parseInt(getItem('role'), 10) || demoUser.userRole,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
            [apiGenerate(type, "slug")]: slug,
        }, { headers: getItemToken('access_token') || null })
        const ListRes = response?.data?.data
        dispatch({ type: "GET_MUSIC_VIEWALLPAGE_ACTION", payload: ListRes });
    }
    catch (error) {
        dispatch({ type: "GET_MUSIC_VIEWALLPAGE_FAILED", payload: error });
    };
}
