export const HeaderReducer = (state = {
    headerMenus: null, themeMode: {
        body: '#1f222a',
        secondarycolor: '#2d3038',
        text: '#fdfcfc',
        button: '#fd0d0d',
        buttonText: '#fff',
        currentMode: 'dark',
        logoUrl: ''
    }, headerSetting: null, error: null, isLoading: true
}, action) => {
    switch (action.type) {
        case "GET_HEADERINDEX_ACTION":
            return {
                ...state,
                headerMenus: action.payload.Menu,
                userDetails: action.payload.user_details,
                themeMode: {
                    body: action.payload.theme_mode_details.bg_color,
                    text: action.payload.theme_mode_details.text_color,
                    button: action.payload.theme_mode_details.button_color,
                    buttonText: action.payload.theme_mode_details.button_text_color,
                    secondarycolor: action.payload.theme_mode_details.bg_secondary_color,
                    currentMode: action.payload.theme_mode_details.current_theme_mode,
                    logoUrl: action.payload.theme_mode_details.logo_url
                },
                headerSetting: action?.payload?.Setting,
                isLoading: false
            };
        case "GET_HEADERINDEX_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
}