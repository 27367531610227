import React, { useEffect, useState } from 'react'
import { MdClose } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { connect, useDispatch } from 'react-redux';
import { musicAddPlaylist, musicPlaylistImageApi, musicPlaylistPageList, musicPlaylistStore } from '../../Config/Api/MusicApi';
import axios from 'axios';
import ButtonWithLoader from '../CommonComponents/Loaders/ButtonWithLoader';
import { toast } from 'react-toastify';
import { CiImageOn } from "react-icons/ci";
import MessageBox from '../CommonComponents/MessageBox';
import { useTranslation } from 'react-i18next';
import { getItem, getItemToken } from '../../Utils/localStorageUtils';
import { demoUser } from '../../Method/CommonMethod';
import { MusicViewAllAction } from '../../Redux/Actions/MusicViewAllAction';
import { OPEN_PLAYLIST_MODAL, PLAYLIST_CREATE_PAGE } from '../../Redux/ActionTypes';
import { IconMyClose } from '../../Assets/Icons/IconComponents/MyIcons';

const PlaylistModal = ({ createModal, currentAudio, playlistMusicId }) => {
  const userRoleLocal = getItem('role')
  const userIdLocal = getItem('user_id')
  const userAccessToken = getItemToken('access_token');

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [playlistData, setPlaylistData] = useState({
    playlistName: null,
    playlistImage: null,
    playlistStore: [],
    createResponse: null,
    playlistMoveName: null,
  })

  const [formDataError, setFormDataError] = useState({})
  const [loading, setLoading] = useState(false)

  const handleInput = (e) => {
    const { name, value } = e.target;
    setPlaylistData((prev) => ({
      ...prev,
      [name]: value
    }))
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      [name]: "",
    }));
  }

  let selectedFile = null;
  const handleFile = (event) => {
     selectedFile = event.target.files[0];
    if (selectedFile) {
      setPlaylistData((prev) => ({
        ...prev,
        playlistImage: selectedFile
      }));
      console.log("Selected file:", selectedFile);
      console.log("PlaylistData after update:", { ...playlistData, playlistImage: selectedFile });
    } else {
      return null;
    }
    setFormDataError((prevFormData) => ({
      ...prevFormData,
      playlistImage: "",
    }));
  };


  const handleImageUpload = async (id) => {
    try {
      const formDataAdd = new FormData();
      formDataAdd.append("Playlist_id", id);
      formDataAdd.append("image", playlistData.playlistImage);
      console.log("FormData before API call:", formDataAdd.get("image"));

      const response = await axios.post(musicPlaylistImageApi, formDataAdd, { headers: userAccessToken });
      setPlaylistData((prev) => ({
        ...prev,
        createResponse: response?.data,
        playlistImage: null,
        playlistName: '',
      }))
      if (!response?.data?.status) {
        toast.error(t(response?.data?.message))
      }
    } catch (err) {
      console.error(err)
    }
  }

  const createPlaylist = async () => {
    setLoading(true);
    try {
      const formDataAdd = new FormData();
      formDataAdd.append("Login_user_type", userRoleLocal || demoUser?.userRole)
      formDataAdd.append("Login_user_id", userIdLocal || demoUser?.userId)
      formDataAdd.append("Login_device_type", "web");
      formDataAdd.append("source_type", 5);
      formDataAdd.append("title", playlistData?.playlistName);

      const response = await axios.post(`${musicPlaylistStore}`, formDataAdd, { headers: userAccessToken });
      const result = response?.data;

      if (result?.status) {
        await handleImageUpload(result?.Playlist_id)
        setLoading(false);
        toast.success(t(result?.message))
        dispatch({ type: "PLAYLIST_CREATE_PAGE", payload: false })
      }
      else {
        setLoading(false);
        toast.error(t(result?.message))
      }
    }
    catch (err) {
      setLoading(false);
      const errorMessage = err?.response?.data?.message
      toast.error(t(errorMessage))
      console.error("error", err)
    }
  }

  const addAudioPlaylist = async () => {
    setLoading(true);
    try {
      const formDataAdd = new FormData();
      formDataAdd.append("Login_user_type", userRoleLocal || demoUser?.userRole)
      formDataAdd.append("Login_user_id", userIdLocal || demoUser?.userId)
      formDataAdd.append("Login_device_type", "web");
      formDataAdd.append("source_type", 5);
      formDataAdd.append("source_id", playlistMusicId !== null ? playlistMusicId : currentAudio ? currentAudio?.id : 0);
      formDataAdd.append("playlist_id", playlistData?.playlistMoveName);
      const response = await axios.post(`${musicAddPlaylist}`, formDataAdd, { headers: userAccessToken });
      const result = response?.data;
      if (result?.status) {
        setLoading(false);
        dispatch({ type: OPEN_PLAYLIST_MODAL, payload: false })
        toast.success(t(result?.message))
      }
      else {
        setLoading(false);
        toast.error(t(result?.message))
      }
    }
    catch (err) {
      setLoading(false);
      const errorMessage = err?.response?.data?.message
      toast.error(t(errorMessage))
      console.error("error", err)
    }
  }

  const handleStore = async () => {
    if (formValidation()) {
      await createPlaylist();
    } else {
      console.log("Form validation failed ");
    }
  }

  const handleAudioToPlayListStore = () => {
    let formIsValid = true;
    if (!playlistData?.playlistMoveName) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        playlistMoveName: "Please choose the playlist name",
      }));
      formIsValid = false;
    }
    if (formIsValid) {
      addAudioPlaylist()
    }
    else {
      console.log("Form validation failed ");
    }
  }

  const formValidation = () => {
    let formIsValid = true;
    if (!playlistData?.playlistName) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        playlistName: "Please give the playlist name",
      }));
      formIsValid = false;
    }
    if (!playlistData?.playlistImage) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        playlistImage: "Please give the playlist image",
      }));
      formIsValid = false;
    }

    return formIsValid;
  }



  const closeModal = () => {
    dispatch({ type: OPEN_PLAYLIST_MODAL, payload: false })
    dispatch({ type: PLAYLIST_CREATE_PAGE, payload: false })
  }
  const createPlaylistModal = () => {
    dispatch({ type: PLAYLIST_CREATE_PAGE, payload: !createModal })
  }

  useEffect(() => {
    const PlaylistGet = async () => {
      try {
        const formDataAdd = new FormData();
        formDataAdd.append("Login_user_type", userRoleLocal || demoUser?.userRole)
        formDataAdd.append("Login_user_id", userIdLocal || demoUser?.userId)
        formDataAdd.append("Login_device_type", "web");
        const response = await axios.post(`${musicPlaylistPageList}`, formDataAdd, { headers: userAccessToken });
        const result = response?.data?.data;
        setPlaylistData((prev) => ({
          ...prev,
          playlistStore: result
        }))
      }
      catch (err) {
        console.error("error", err)
      }
    }
    PlaylistGet()
    if (playlistData?.createResponse !== null) {
      dispatch(MusicViewAllAction("PLAYLIST", musicPlaylistPageList, "playlist"))
    }
  }, [playlistData?.createResponse])


  return (
    <div className='playlistModal w-100 h-100 d-flex align-items-center justify-content-center position-fixed'>
      <div className='theme-bg-color playlistWrapper'>
        <div className='d-flex align-items-center justify-content-between'>
          <MessageBox classname="p-0 playlistHeadingModal" text={createModal ? "Create Playlist" : "Choose Playlist"} />
          <button className='bg-transparent p-0' type='button' onClick={closeModal}><IconMyClose styled={{ className: "playlistIcon theme-text-color" }} /></button>
        </div>
        {createModal ?
          <>
            <input type="text" name="playlistName" value={playlistData?.playlistName} onChange={handleInput} placeholder={t("Playlist name")} className="rounded-2 playlistInput w-100 theme-border-color-reverse  theme-bg-color-secondary theme-text-color  my-3" />
            <MessageBox classname='text-danger-custom' text={formDataError?.playlistName} />
            <div className='position-relative musicDropZone  my-3 rounded-2 theme-border-color'>
              <input type="file" onChange={(event) => handleFile(event)} className="rounded-2 h-100 opacity-0 playlistInput w-100 theme-border-color-reverse  theme-bg-color theme-text-color " accept="image/*" />
              <CiImageOn className='musicDropZoneIcon' />
            </div>
            <MessageBox classname='theme-text-color fileNameSize' text={playlistData?.playlistImage?.name} />
            <MessageBox classname='text-danger-custom' text={formDataError?.playlistImage} />
          </>
          :
          <> <select className="playlistInput w-100 mt-3 rounded-2 mb-4 theme-border-color-reverse theme-bg-color-secondary theme-text-color border-0" onChange={handleInput} name="playlistMoveName" >
            <option value="0" className="theme-bg-color theme-text-color" >{t("Choose an playlist name")}</option>
            {playlistData?.playlistStore?.map((data, index) => { return <option value={data?.id} key={data?.id} className="theme-bg-color-secondary theme-text-color">{data?.title}</option> })}
          </select>
            <MessageBox classname='text-danger-custom' text={formDataError?.playlistMoveName} />
          </>}
        <div className='d-flex align-items-center justify-content-between'>
          <button className='bg-transparent p-0' type='button' onClick={createPlaylistModal}>  {createModal ? <><IoIosArrowBack className='theme-text-color arrowIconModal' /><MessageBox text="Go back" classname='theme-text-color playlistSub' /> </> : <><MessageBox text="Create" classname='theme-text-color playlistSub' /><IoIosArrowForward style={{ marginLeft: "-4px" }} className='theme-text-color arrowIconModal' /></>} </button>
          <div className='d-flex align-items-center gap-2'>
            <button className='bg-transparent py-2 rounded-2 ' type='button' onClick={closeModal} ><MessageBox text="Close" classname='theme-text-color playlistSub' /></button>
            {createModal ? <ButtonWithLoader text="Upload" loading={loading} action={handleStore} /> : <ButtonWithLoader text="Add" loading={loading} action={handleAudioToPlayListStore} />}
          </div>
        </div>

      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  createModal: state.toggleState.createModal,
  currentAudio: state.currentMusic.currentAudio,
  playlistMusicId: state.currentMusic.playlistMusicId
});
export default connect(mapStateToProps)(PlaylistModal)

