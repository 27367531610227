import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

const LazyLoadImage = ({ src, alt, title, classNameOptional, imageSize, bgColor }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  }
  const { t } = useTranslation();

  return (
    <div className={`h-100 overflow-hidden ${classNameOptional}`} style={{ backgroundColor: `${bgColor}` }} ref={ref}>
      {isLoading && (
        <div className="square d-flex align-items-center justify-content-center p-3 h-100">
          <p className='text-secondary loaderText theme-text-color mb-0'>{t(title)}</p>
        </div>
      )}
      <img
        ref={ref}
        onLoad={handleImageLoad}
        src={inView ? src : ''}
        alt={alt}

        className={`loading position-relative  w-100 h-100 ${isLoading ? 'd-none' : 'd-block'} ${imageSize}`}
      />
    </div>
  )
};

export default LazyLoadImage;