
//store playlist
export const PLAYLIST_SUCCESS = 'PLAYLIST_SUCCESS';
export const PLAYLIST_FAILED = 'PLAYLIST_FAILED';

//playlist modal 

export const OPEN_PLAYLIST_MODAL = 'OPEN_PLAYLIST_MODAL'
export const PLAYLIST_CREATE_PAGE = 'PLAYLIST_CREATE_PAGE';

// station modal 

export const OPEN_STATION_MODAL = 'OPEN_STATION_MODAL'
export const CHOOSE_STATION_PAGE = 'CHOOSE_STATION_PAGE';

// purchase modal

export const OPEN_PURCHASE_MODAL = 'OPEN_PURCHASE_MODAL';

// Toggle 
export const OPEN_PLAYLIST = 'OPEN_PLAYLIST';

export const MINIMIZE_PLAYER = 'MINIMIZE_PLAYER';

export const LAYOUT_SWITCHER = 'LAYOUT_SWITCHER';

export const CLOSE_PLAYER = 'CLOSE_PLAYER';

// home page 

export const GET_MUSIC_HOMEPAGE = 'GET_MUSIC_HOMEPAGE';
export const GET_MUSIC_HOMEPAGE_FAILED = 'GET_MUSIC_HOMEPAGE_FAILED';


//theme 

export const musicSettings = 'musicSettings';
export const musicSettings_FAILED = 'musicSettings_FAILED';

// auth 

export const AUTH_MODAL_SIGNIN = 'AUTH_MODAL_SIGNIN';
export const AUTH_MODAL_SIGNUP = 'AUTH_MODAL_SIGNUP';


