import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getItem, getItemToken } from '../../Utils/localStorageUtils';
import { MusicViewAllAction } from '../../Redux/Actions/MusicViewAllAction';
import { URLGenerater } from '../../Method/CommonMethod';
import { musicDeletePlaylist, musicStationDeleteApi } from '../../Config/Api/MusicApi';
import EmptyLoaderStatic from '../../Components/CommonComponents/Loaders/EmptyLoaderStatic';
import PageError from '../NotFound/PageError';
import MusicLayoutViewAll from './MusicLayoutViewAll';
import { MusicHomePageAction } from '../../Redux/Actions/MusicHomeAction';
import { useTranslation } from 'react-i18next';
const MusicFormatViewAll = ({ isLoading, viewAllData, musicHomePage, settings, error }) => {
    const { source } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const [response, setResponse] = useState({
        deleteResponse: null,
        homeListerror: ""
    })


    useEffect(() => {
        try {
            const dataList = musicHomePage.find((item) => URLGenerater(item?.source) === source);
            if (dataList) {
                const requestApi = dataList.Redirection_PageList_URL;
                console.log(requestApi);
                if (settings && Object.keys(settings).length > 0) {
                    dispatch(MusicViewAllAction("AUDIO", requestApi));
                }
            } else {
                setResponse((prev) => ({
                    ...prev,
                    homeListerror: `No ${source} Found`
                }))
            }
        } catch (err) {
            setResponse((prev) => ({
                ...prev,
                homeListerror: `No ${source} Found`
            }))
        }
    }, [location, response]);

    useEffect(() => {
        if (response.deleteResponse !== null || response?.deleteResponse !== '') {
            dispatch(MusicHomePageAction())
        }
    }, [response])

    const handleDeletePlayList = (deleteId) => {
        return axios.delete(`${musicDeletePlaylist}/${deleteId}`)
            .then((res) => {
                toast.success(t(res?.data?.message))
                setResponse((prev) => ({
                    ...prev,
                    deleteResponse: res?.data?.message
                }))
            })
            .catch((err) => {
                toast.error(t(err?.response?.data?.message))
            });
    }

    const handleDeleteStation = (deleteId) => {
        return axios.delete(`${musicStationDeleteApi}/${deleteId}`)
            .then((res) => {
                toast.success(t(res?.data?.message))
                setResponse((prev) => ({
                    ...prev,
                    deleteResponse: res?.data?.message
                }))
            })
            .catch((err) => {
                toast.error(t(err?.response?.data?.message))
            });
    }
    return response?.homeListerror ? <PageError error={response?.homeListerror} /> : isLoading ? <EmptyLoaderStatic /> : error ? <PageError /> : <MusicLayoutViewAll mapData={viewAllData} source={source} path='' headingClass='pb-4 text-center' methodAction={source == "playlist" ? handleDeletePlayList : handleDeleteStation} />
}

const mapStateToProps = (state) => ({
    musicHomePage: state.homeLists.musicHomePage,
    viewAllData: state.musicViewAll.viewAllData,
    isLoading: state.musicViewAll.isLoading,
    error: state.musicViewAll.error,
    settings: state.settingsDefault.settings,
});
export default connect(mapStateToProps)(MusicFormatViewAll)