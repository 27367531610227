// External Libraries and Packages
import { connect, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { BsFillPlayFill, BsFillPauseFill, } from "react-icons/bs";
import { MdMoreVert, MdMusicNote, MdOutlinePlaylistAdd } from "react-icons/md";
import { FiMinimize } from "react-icons/fi";
import { TfiLoop } from "react-icons/tfi";
import { Link, useNavigate } from "react-router-dom"
import { MdQueueMusic } from "react-icons/md";

// Internal Styles
import "../Player/MusicMiniTrack.css";
import "../Player/ProgressBar.css";
// Internal Components
import defaultAudioImage from "../../Assets/Images/default-music.png";
import ToolTip from "../CommonComponents/ToolTip";
import { getItemToken, getItem } from "../../Utils/localStorageUtils"
import { musicViewsCount } from "../../Config/Api/MusicApi"
import { LikeButtonAction, DisLikeButtonAction, WishListButtonAction } from "../../Method/CommonMethod";
import MiniPrograssBar from "../Player/MiniPrograssBar";
import { useMyContext } from "../../Context/CommonProvider";
import axios from "axios";
import MessageBox from "../CommonComponents/MessageBox";
import { IconMyAddPlaylist, IconMyLoop, IconMyMic, IconMyMini, IconMyPause, IconMyPlay, IconMyPlayPrev, IconMyShuffle, IconMyVolumn, IconMyVolumnMute } from "../../Assets/Icons/IconComponents/MyIcons";
import { OPEN_PLAYLIST_MODAL } from "../../Redux/ActionTypes";
import LazyLoadImage from "../CommonComponents/LazyLoadImage";


const MusicFloatTrack = ({ playlists, currentAudio, currentToggle, currentPlaying, currentIndex, currentRepeat, getMusicData, initialModal, currentUserInteract, languageDetailsData, playlistShow }) => {

    const { shufflePlaylist, handlePlayPause, handleVolume, handlePlayNext, handlePlayPrev, handleSeek, handleEnableLoop, minimizePlayerToggle, prograssBarPercentage, duration, currentTime, readyState, volume, audioReference } = useMyContext();

    const userRoleLocal = getItem('role');
    const userIdLocal = getItem('user_id');
    const userAccessToken = getItemToken('access_token');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [audioCalled, setAudioCalled] = useState(false);
    const [playerMin, setPlayerMin] = useState(false)


    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const handleLyrics = () => {
        if (currentAudio) {
            dispatch({
                type: "GET_TOGGLE_SUCCESS",
                toggle: !currentToggle,
            });
            if (currentToggle && window.location.pathname !== `${languageDetailsData?.Language_Code}/`) {
                navigate(-1);
            } else {
                navigate(`/${languageDetailsData?.Language_Code}/lyrics`);
            }
        }
        else {
            return null
        }
    };

    const handlePlaylistModal = () => {
        if (currentAudio?.id) {
            dispatch({ type: OPEN_PLAYLIST_MODAL, payload: true })
            dispatch({ type: "GET_PLAYLIST_STORE_ID", payload: null })
        }
        else {
            return null
        }
    };




    const handleShowPlaylist = () => {
        if (currentAudio?.id) {
            dispatch({ type: "OPEN_PLAYLIST", payload: !playlistShow })
        }
        else {
            return null
        }

    }

    useEffect(() => {
        const roundedTime = Math.floor(currentTime);
        if (roundedTime >= 30000) {
            setAudioCalled(true);
        }
        else {
            setAudioCalled(false);
        }
        if (roundedTime >= 30000 && !audioCalled) {
            const data = {
                Login_user_type: userRoleLocal || 9,
                Login_device_type: "web",
                Login_user_id: userIdLocal || null,
                source_type: 5,
                source_id: currentAudio?.id
            };
            axios.post(musicViewsCount, data)
                .then(response => {
                    console.log('API call success:', response);
                    setAudioCalled(true);
                })
                .catch(error => {
                    console.error('API call error:', error);
                });
        } else if (roundedTime < 30000) {
            console.log("error audio content");
        }
    }, [currentTime, audioCalled]);

    // const handleVolumeChange = (event) => {
    //     const newVolume = parseFloat(event.target.value);
    //     setVolume(newVolume);
    //     audioReference.current.volume = newVolume;
    // };

    return (
        <div className={`music-player theme-bg-color slide-bottom ${currentAudio?.id}`}>
            <div className={`w-100`}> <MiniPrograssBar handleTime={handleSeek} progressValue={prograssBarPercentage} duration={duration} readyState={readyState} /> </div>
            <div className="d-flex flex-wrap align-items-center justify-content-between px-2 px-lg-4 py-2 w-100">
                <div className="col-6 col-sm-5 col-md-3">
                    <div className="d-flex align-items-center justify-content-between gap-3">
                        {getMusicData?.enable_track_image ? <LazyLoadImage key={'logo'} src={currentAudio?.image_url ? currentAudio?.image_url : currentAudio?.Thumbnail ? currentAudio?.Thumbnail : defaultAudioImage} alt={'logo'} title={currentAudio?.title?.slice(0, 1)} imageSize="rounded-2  albumArtImage overflow-hidden  object-fit-cover" classNameOptional="rounded-2 albumArtImage" /> : null}
                        <div className="col-4 col-lg-4 overflow-hidden flex-fill  ">
                            {getMusicData?.enable_track_title ? <MessageBox classname={`${currentAudio?.title ? '' : 'theme-bg-color-secondary rounded-2'}  playerTrackTitle theme-text-color mb-0 mt-0 fw-bold`} text={currentAudio?.title ? currentAudio?.title : ""}> </MessageBox> : null}
                            {getMusicData?.enable_track_artists ? <span className={`${currentAudio?.id ? 'miniPlayerText' : 'theme-bg-color-secondary miniPlayerTextNone rounded-2'} `}>{currentAudio?.artists && currentAudio?.artists.map((item, index) => <Link to={`/${languageDetailsData?.Language_Code}/${item.artist_slug}`} className="audioLink theme-text-color audioListNameTrack" >{item.name}</Link>)}</span> : null}
                        </div>
                        <div className={`d-sm-block d-none ${currentAudio?.id ? "" : 'opacity-75'}`}>
                            <WishListButtonAction source={currentUserInteract} sourceType={'5'} />
                        </div>
                    </div>
                </div>
                <div className="col-6 col-sm-5 col-md-8 col-lg-6">
                    <div className="d-flex align-items-center justify-content-center ">
                        <button className={`bg-transparent theme-text-color  playButton border-0 p-0 marginER d-none d-md-flex  toolTipParent ${currentToggle ? "yes " : "no"}  ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handleLyrics} >
                            <IconMyMic styled={{ width: "20px", height: "20px", className: "musicSideBarIcons " }} className={`likeIcon`} />
                            <ToolTip bgColor="#000" text="Lyrics" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
                        </button>
                        <LikeButtonAction source={currentUserInteract} sourceType={'5'} status={currentUserInteract?.like ? 0 : 1} className='d-none d-md-block theme-text-color p-0 marginER' />
                        <DisLikeButtonAction source={currentUserInteract} sourceType={'5'} status={currentUserInteract?.dislike ? 0 : 1} className='d-none d-md-block theme-text-color p-0 marginER' />

                        {getMusicData?.enable_shuffle ? <button className={`bg-transparent theme-text-color  marginER playButton p-0 d-none d-md-block toolTipParent  ${currentAudio?.id ? "" : 'opacity-75'} `} onClick={shufflePlaylist}   >
                            <IconMyShuffle styled={{ width: "20px", height: "20px", className: "theme-text-color" }} />
                            <ToolTip bgColor="#000" text="Shuffle" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
                        </button> : null}

                        <button className={`${currentIndex == 0 ? 'opacity-75' : ''} bg-transparent theme-text-color playButton  p-0 marginER ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handlePlayPrev} disabled={currentIndex == 0 || !getMusicData?.enable_track_controls} >
                            <IconMyPlayPrev styled={{ width: "20px", height: "20px", className: "musicSideBarIcons playerPrev" }} />
                        </button>

                        {!currentPlaying ? (
                            <button className="playButtonMain theme-button-bg-color p-0 triangle d-flex align-items-center justify-content-center" onClick={handlePlayPause}><IconMyPlay styled={{ width: "20px", height: "20px", className: "" }} /></button>
                        ) : (
                            <button className="playButtonMain theme-button-bg-color p-0 " onClick={handlePlayPause}><IconMyPause styled={{ width: "20px", height: "20px", className: "" }} /></button>
                        )}

                        <button className={`${currentIndex + 1 >= playlists?.length ? 'opacity-75' : ''} bg-transparent theme-text-color playButton p-0 marginER`} onClick={handlePlayNext} disabled={currentIndex + 1 >= playlists?.length || !getMusicData?.enable_track_controls}>
                            <IconMyPlayPrev styled={{ width: "20px", height: "20px", className: "musicSideBarIcons rotate playerNext" }} />
                        </button>

                        {getMusicData?.enable_loop ? <button className={`bg-transparent theme-text-color playButton marginER p-0 toolTipParent ${currentRepeat == "enabled" || currentRepeat == "currentloop" ? "yes " : "no"} ${currentRepeat == "currentloop" && 'one'}  ${currentAudio?.id ? "" : 'opacity-75'} d-none d-md-block`} onClick={handleEnableLoop}>
                            <IconMyLoop styled={{ width: "20px", height: "20px", className: "musicSideBarIcon" }} />
                            <ToolTip bgColor="#000" text="Loop" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
                        </button> : null}
                        <button type="button" className={`bg-transparent theme-text-color playButton p-0 marginER  d-none d-md-block toolTipParent ${playerMin ? "d-sm-none" : ""} ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handlePlaylistModal}>
                            <IconMyAddPlaylist styled={{ width: "18px", height: "18px", className: "" }} />
                        </button>
                        {getMusicData?.enable_minimize_button ? <button type="button" className={`bg-transparent theme-text-color playButton marginER p-0 d-none d-md-block toolTipParent`} onClick={() => minimizePlayerToggle()}>
                            <IconMyMini styled={{ width: "15px", height: "15px", className: "" }} />
                        </button> : null}
                        <div className={`dropup  moreMobileButton d-md-none ${playerMin ? "d-none" : ""} ${currentAudio?.id ? "" : 'opacity-75'}`} id="mobile-view-option-start">
                            <button className="dropdown-toggle bg-transparent " type="button" id="dropdownMenuButton" aria-haspopup="true" data-bs-toggle="dropdown" aria-expanded="false">
                                <MdMoreVert className={`likeIcon theme-text-color`} />
                            </button>
                            <ul class="dropdown-menu py-3 theme-bg-color theme-text-color" aria-labelledby="dropdownMenuButton">
                                <li className={`${currentUserInteract?.like == 1 && "theme-button-bg-color"}`}>
                                    <LikeButtonAction />
                                </li>
                                <li className={`mt-2 ${currentUserInteract?.dislike == 1 && "theme-bg-color-reverse"}`}>
                                    <DisLikeButtonAction />
                                </li>
                                <li className="mt-2">
                                    <WishListButtonAction source={currentUserInteract} sourceType={'5'} />
                                </li>
                                <li className={`mt-2 ${currentRepeat =="enabled" || currentRepeat == "currentloop" && "theme-bg-color-reverse"} ${currentAudio?.id ? "" : 'opacity-75'}`}>
                                    <button type="button" className={`px-3 d-flex dropdown-item bg-transparen`} onClick={handleEnableLoop}  >
                                        <TfiLoop className={`playerIcon mobile  ${currentRepeat ? 'theme-text-color-body' : 'theme-text-color'}  `} />
                                        <MessageBox classname={`${currentRepeat ? 'theme-text-color-body ' : 'theme-text-color'} smallText`} text="Loop" />
                                    </button>
                                </li>
                                {/* <li className={`mt-2  ${currentAudio?.id ? "" : 'opacity-75'}`}>
                  <button type="button" className={`px-3 d-flex dropdown-item bg-transparen`} onClick={handleEnableLoop}  >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className={`theme-text-color`}>
                      <path opacity="0.4" d="M21.75 18.2299C21.75 18.2099 21.74 18.1899 21.74 18.1699C21.73 18.0899 21.72 18.0099 21.69 17.9399C21.65 17.8499 21.6 17.7799 21.54 17.7099C21.54 17.7099 21.54 17.6999 21.53 17.6999C21.46 17.6299 21.38 17.5799 21.29 17.5399C21.2 17.4999 21.1 17.4799 21 17.4799L16.33 17.4999C16.33 17.4999 16.33 17.4999 16.32 17.4999C15.72 17.4999 15.14 17.2199 14.78 16.7399L13.56 15.1699C13.31 14.8399 12.84 14.7799 12.51 15.0399C12.18 15.2999 12.12 15.7599 12.38 16.0899L13.6 17.6599C14.25 18.4999 15.27 18.9999 16.33 18.9999H16.34L19.19 18.9899L18.48 19.6999C18.19 19.9899 18.19 20.4699 18.48 20.7599C18.63 20.9099 18.82 20.9799 19.01 20.9799C19.2 20.9799 19.39 20.9099 19.54 20.7599L21.54 18.7599C21.61 18.6899 21.66 18.6099 21.7 18.5199C21.73 18.4199 21.75 18.3199 21.75 18.2299Z" fill="currentColor" />
                      <path opacity="0.4" d="M8.42001 6.93976C7.77001 6.03976 6.73 5.50977 5.62 5.50977C5.61 5.50977 5.61001 5.50977 5.60001 5.50977L2.98999 5.51978C2.57999 5.51978 2.23999 5.85978 2.23999 6.26978C2.23999 6.67978 2.57999 7.01978 2.98999 7.01978L5.60001 7.00977H5.60999C6.23999 7.00977 6.83 7.30976 7.19 7.81976L8.26999 9.31976C8.41999 9.51976 8.65 9.62976 8.88 9.62976C9.03 9.62976 9.19001 9.57975 9.32001 9.48975C9.66001 9.24975 9.72999 8.77976 9.48999 8.43976L8.42001 6.93976Z" fill="currentColor" />
                      <path d="M21.74 6.32974C21.74 6.30974 21.75 6.28976 21.75 6.27976C21.75 6.17976 21.73 6.07972 21.69 5.98972C21.65 5.89972 21.6 5.81973 21.53 5.74973L19.53 3.74973C19.24 3.45973 18.76 3.45973 18.47 3.74973C18.18 4.03973 18.18 4.51972 18.47 4.80972L19.18 5.51975L16.45 5.50974C16.44 5.50974 16.44 5.50974 16.43 5.50974C15.28 5.50974 14.2 6.07971 13.56 7.04971L7.17001 16.6297C6.81001 17.1697 6.19999 17.4997 5.54999 17.4997H5.54001L2.98999 17.4897C2.57999 17.4897 2.23999 17.8197 2.23999 18.2397C2.23999 18.6497 2.56999 18.9897 2.98999 18.9897L5.54001 18.9997C5.55001 18.9997 5.55 18.9997 5.56 18.9997C6.72 18.9997 7.78999 18.4297 8.42999 17.4597L14.82 7.87973C15.18 7.33973 15.79 7.00974 16.44 7.00974H16.45L21 7.02976C21.1 7.02976 21.19 7.0097 21.29 6.9697C21.38 6.9297 21.46 6.87972 21.53 6.80972C21.53 6.80972 21.53 6.79971 21.54 6.79971C21.6 6.72971 21.66 6.65973 21.69 6.56973C21.72 6.48973 21.73 6.40974 21.74 6.32974Z" fill="currentColor" />
                    </svg>
                    <span className="theme-text-color smallText">Shuffle</span>
                  </button>
                </li> */}
                                <li className={`mt-2 ${currentToggle && "theme-bg-color-reverse"} ${currentAudio?.id ? "" : 'opacity-75'}`}>
                                    <button type="button" className={`px-3 d-flex dropdown-item bg-transparent`} onClick={handleLyrics}  >
                                        <MdMusicNote className={`playerIcon mobile ${currentToggle ? 'theme-text-color-body' : 'theme-text-color'} `} />
                                        <MessageBox classname={`${currentToggle ? 'theme-text-color-body ' : 'theme-text-color'} smallText`} text="Lyrics" />
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={`col-2 col-sm-2 col-md-1 col-lg-3 d-none d-md-block`}>
                    <div className=" d-flex gap-2 align-items-center justify-content-between">
                        <div className="d-none d-lg-block ">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="audioTime theme-text-color" >{formatTime(currentTime / 1000)} </span>
                                <span className="audioTime theme-text-color">/</span>
                                <span className="audioTime theme-text-color">{formatTime(duration)}</span>
                            </div>
                        </div>
                        {getMusicData?.enable_volume ? <div className="d-flex align-items-center">
                            <button className="playButton toolTipParent bg-transparent p-0 theme-text-color marginER" onClick={handleVolume} >
                                {volume ? <IconMyVolumn styled={{ width: "20px", height: "20px", className: "" }} />
                                    : <IconMyVolumnMute styled={{ width: "20px", height: "20px", className: "" }} />}
                                <ToolTip bgColor="#000" text="Volumn" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
                            </button>
                            <input type="range" min="0" max="1" step="0.01" value={volume} className="playerRange d-none d-lg-block" onChange={handleVolume} />
                        </div> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    playlists: state.playlists.playlists,
    isLoading: state.playlists.isLoading,
    error: state.playlists.error,
    currentIndex: state.currentMusic.currentIndex,
    currentAudio: state.currentMusic.currentAudio,
    currentTimes: state.currentMusic.currentTime,
    currentToggle: state.currentMusic.currentToggle,
    currentTrackId: state.currentMusic.currentTrackId,
    currentDuration: state.currentMusic.currentDuration,
    currentPlaying: state.currentMusic.currentPlaying,
    currentRepeat: state.currentMusic.currentRepeat,
    getMusicData: state.musicSettings.getMusicData,
    initialModal: state.toggleState.initialModal,
    currentUserInteract: state.currentMusic.currentUserInteract,
    playlistShow: state.toggleState.playlistShow,
    homepage: state.toggleState.homepage,
    languageDetailsData: state.musicLanguage.languageDetailsData
});
export default connect(mapStateToProps)(MusicFloatTrack)