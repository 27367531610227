// External Libraries and Packages
import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import EmptyLoaderCard from './EmptyLoaderCard'
import EmptyLoaderNav from './EmptyLoaderNav';
import { connect } from 'react-redux';

const EmptyLoaderSwiper = ({ playlistShow }) => {
    const [containerWidth, setContainerWidth] = useState(1024);
    const containerRef = useRef(null);

    const calculateSlidesPerView = () => {
        if (containerWidth < 320) { return 1; }
        else if (containerWidth < 376) { return 2; }
        else if (containerWidth < 576) { return 3; }
        else if (containerWidth < 768) { return 4; }
        else if (containerWidth < 1200) { return 4; }
        else if (containerWidth < 1400) { return 7; }
        else { return 7; }
    };

    const swiperConfig = {
        slidesPerView: calculateSlidesPerView(),
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: calculateSlidesPerView(),
        modules: [Navigation],
        className: 'musicHomePageSwiper'
    }

    useEffect(() => {
        const handleResize = () => {
            if (containerRef?.current?.clientWidth) {
                setContainerWidth(containerRef?.current?.clientWidth);
            }
        };
        handleResize();
        calculateSlidesPerView()
    }, [playlistShow, containerRef?.current?.clientWidth]);
    return (
        <div className='mb-4' ref={containerRef}>
            <EmptyLoaderNav />
            <Swiper {...swiperConfig}>
                {Array.from({ length: 15 }).map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <EmptyLoaderCard />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

const mapStateToProps = state => ({
    playlistShow: state.toggleState.playlistShow,
});

export default connect(mapStateToProps)(EmptyLoaderSwiper)