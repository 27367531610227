import React from 'react'

const EmptyLoaderCard = () => {
    return (
        <div className='p-2 rounded-2 h-100 theme-border-secondary w-100'>
            <div className="musicImageHeight " >
                <div className='square h-100 rounded-2'></div>
            </div>
            <h4 className='playCardTitle py-2 '>
                <div className='square h-100 rounded-1'> </div>
            </h4>
            <p className='playCardDescription '>
                <div className='square h-100 rounded-1'> </div>
            </p>
        </div>
    )
}

export default EmptyLoaderCard