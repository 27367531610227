export const MusicDefaultSettingReducer = (state = { settings: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_SETTING": return {
            ...state,
            settings: action.payload,
            isLoading: false,
        }
        case "GET_SETTING_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}