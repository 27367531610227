// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.musicHeight {
    height: calc(100vh - 16px);
}

.rightMusicContainer {
    width: calc(100% - 77px);
    margin-left: auto;
}

::-webkit-scrollbar {
    width: 6px;
    display: none;
}

::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #424242;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/MusicLayout.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,wBAAwB;IACxB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,yBAAyB;IAEzB,mBAAmB;AACvB;;AAEA;IAEI,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".musicHeight {\n    height: calc(100vh - 16px);\n}\n\n.rightMusicContainer {\n    width: calc(100% - 77px);\n    margin-left: auto;\n}\n\n::-webkit-scrollbar {\n    width: 6px;\n    display: none;\n}\n\n::-webkit-scrollbar-track {\n    background-color: #f0f0f0;\n    -webkit-border-radius: 10px;\n    border-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n    -webkit-border-radius: 10px;\n    border-radius: 10px;\n    background: #424242;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
