// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from 'react-redux';
import HomePageNav from '../../Components/CommonComponents/HomePageNav';
import MusicBasedArtistCard from '../../Components/CommonComponents/Cards/MusicBasedArtistCard';

const MusicBasedArtist = ({ item, swiperOptions,languageDetailsData }) => {
    return (
        <div className='mb-4'>
            <HomePageNav link={item?.slug} headerName={item?.albumname} store={item?.audios} seg={`${languageDetailsData?.Language_Code}/audio-albums/`} />
            <Swiper {...swiperOptions} className='musicHomePageSwiper'>
                {item?.audios?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicBasedArtistCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.musicLanguage.languageDetailsData
})
export default connect(mapStateToProps)(MusicBasedArtist)