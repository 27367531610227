import { URLGenerater } from "../../Method/CommonMethod";

export const MusicHomeReducer = (state = { musicHomePage: [], getSource: [], getSourceType: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_MUSIC_HOMEPAGE":
            return {
                ...state,
                musicHomePage: action.payload,
                getSource: (action.payload || []).map(item => URLGenerater(item.source) || []),
                getSourceType: (action.payload || []).map(item => URLGenerater(item.source_type) || []),
                isLoading: false,
            };
        case "GET_MUSIC_HOMEPAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
};

