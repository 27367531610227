// External Libraries and Packages
import { connect } from "react-redux";
import { useState } from "react";
import axios from "axios";
import { VscEyeClosed } from "react-icons/vsc";
import { RxEyeOpen } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
// Internal Styles
import "../../Style/Popup.css";
//Images
import google from "../../Assets/Images/Googl.png";
import fb from "../../Assets/Images/fb.png";
// import ForgotPassword from "./ForgotPassword";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import LazyLoadImage from "../CommonComponents/LazyLoadImage";
import { getItem, getItemToken, removeItem, setItem } from "../../Utils/localStorageUtils";
import { AUTH_MODAL_SIGNIN, AUTH_MODAL_SIGNUP } from "../../Redux/ActionTypes";
import ButtonWithLoader from "../CommonComponents/Loaders/ButtonWithLoader";
import { initializeI18n } from "../../Config/i18n";
// Internal Components
const SignInPopup = ({ themeMode, authModalSignIn }) => {

  const [inputField, setInputField] = useState({ email: "", password: "" });
  const [errField, setErrField] = useState({ emailerr: "", passworderr: "" });
  const [passwordType, setPasswordType] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();


  const userRoleLocal = getItem('role')
  const userIdLocal = getItem('user_id')
  const userAccessToken = getItemToken('access_token');


  const handleSwitchSignUp = () => {
    dispatch({ type: AUTH_MODAL_SIGNIN, payload: false })
    dispatch({ type: AUTH_MODAL_SIGNUP, payload: true })
  }

  const login = async () => {
    try {
      localStorage.clear()
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/users/signin`, { email: inputField?.email, password: inputField?.password });
      const result = response?.data;
      const resultUser = result?.user;
      if (result?.status) {
        await setItem("access_token", result?.access_token);
        setItem("user_id", resultUser?.id);
        setItem("role", resultUser?.role);
        setItem("username", resultUser?.username);
        setItem("image_url", resultUser?.image_url);
        setItem("email", resultUser?.email);
        setItem("expires_in", result?.expires_in);
        setLoading(false)
        toast.success(t(result?.message));
        dispatch({ type: "OPEN_PLAYLIST", payload: false });
        dispatch({ type: "GET_AUDIO_SUCCESS", payload: null });
        dispatch({ type: "GET_CURRENT_SUCCESS", payload: null });
        dispatch({ type: AUTH_MODAL_SIGNIN, payload: false });
        window.location.reload()
      }
    } catch (error) {
      toast.error(t("These credentials do not match our records"));
      console.log(error);
      setLoading(false)
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    if (!inputField.email) {
      setErrField((prevState) => ({ ...prevState, emailerr: `${t('Email cannot be empty')}*`, }));
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(inputField.email)) {
      setErrField((prevState) => ({ ...prevState, emailerr: `${t('Invalid email address')}*`, }));
      formIsValid = false;
    } else {
      setErrField((prevState) => ({ ...prevState, emailerr: "", }));
    }
    if (!inputField.password) {
      setErrField((prevState) => ({ ...prevState, passworderr: `${t('Password cannot be empty')}*`, }));
      formIsValid = false;
    } else {
      setErrField((prevState) => ({ ...prevState, passworderr: "", }));
    }
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      login()
    } else {
      console.log('Form validation failed');
    }
  };

  const togglePassword = () => {
    setPasswordType(!passwordType)
  };

  const closeModal = () => {
    dispatch({ type: AUTH_MODAL_SIGNIN, payload: false })
  }

  const handleChange = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  }


  return (
    <>
      {/* <ForgotPassword /> */}
      <div className={`modal  packageModel fade signin-popup-container ${authModalSignIn && 'd-flex show'}`} >
        <div className="modal-dialog ">
          <div class="modal-content theme-bg-color">
            <div class="modal-header flex-column border-0">
              <LazyLoadImage src={themeMode?.logoUrl} alt="SignInLogo" className="d-block mx-auto signin-logo-image mt-2" />
              <button type="button" className="closeIconSignin m-0 bgButton theme-text-color  p-1 d-flex align-items-center position-absolute " style={{ backgroundImage: "none" }} onClick={closeModal}><IoClose style={{ color: "#fff", width: "20px", height: "20px" }} /></button>
            </div>
            <div class="modal-body">
              <div className="signInMenu position-relative" >
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <div className="position-relative pb-4">
                    <div className="border-bottom">
                      <input id="signinemail" name="email" type="text" autoComplete="off" className=" p-2 signin-input border-0 theme-text-color" value={inputField?.username} onChange={handleChange} placeholder={`${t('Email')} *`} /> </div>
                    {errField?.emailerr !== "" && (<span className="d-flex text-danger signin-error py-2 position-absolute"> {errField?.emailerr} </span>)}
                  </div>
                  <div className="position-relative pb-4">
                    <div className="position-relative">
                      <input id="signinpassword" name="password" value={inputField?.password} type={passwordType ? 'password' : 'text'} autoComplete="off" className="p-2 signin-input border-0 theme-text-color border-bottom" onChange={handleChange} placeholder={`${t('Password')} *`} />
                      <button onClick={togglePassword} type="button" className="p-2 border-0 eyeButton">{passwordType ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
                    </div>
                    <span className="float-end theme-text-color py-2" data-bs-toggle="modal" data-bs-target="#exampleModalToggle3" data-bs-dismiss="modal">{t("Forgot Password")} ?</span>
                    {errField?.passworderr !== "" && (<span className="d-flex text-danger signin-error py-2 position-absolute"> {errField?.passworderr} </span>)}
                  </div>
                  <h4 className="signinLabelText py-3 mb-0 theme-text-color">{t("If your email is not registered with us, we will verify your email and sign you up")}</h4>
                  <ButtonWithLoader buttonType="submit" text='Sign In' loading={loading} className="signinbutton mb-0 mx-auto  text-center text-uppercase px-5 py-1 rounded-2 theme-text-color w-50" />
                  <h4 className="signinLabelText py-3 theme-text-color">{t("Or Continue With")}</h4>
                  <div className="d-flex justify-content-center gap-3">
                    <img src={google} alt="google" style={{ width: "39px", height: "40px" }} />
                    <img src={fb} alt="facebook" style={{ width: "43px", height: "43px" }} />
                  </div>
                  <p className="signinLabelText mb-0 pt-2 theme-text-color">{t("Don't have an account")}?<button type="button" className="border-0 bg-transparent theme-text-color" onClick={handleSwitchSignUp}>{t("Sign Up")}</button></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  authModalSignIn: state.toggleState.authModalSignIn,
  authModalSignUp: state.toggleState.authModalSignUp,
  themeMode: state.headerDetails.themeMode,
  languageDetailsData: state.musicLanguage.languageDetailsData
});
export default connect(mapStateToProps)(SignInPopup);
