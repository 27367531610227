import React from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import EmptyLoaderStatic from '../../Components/CommonComponents/Loaders/EmptyLoaderStatic';
import MusicFormatViewAll from './MusicFormatViewAll';


const MusicViewAllIndex = ({ getSource, isLoading }) => {
    const { source } = useParams();
    return (
        <div className='px-sm-2 px-0 px-md-4' >
            {isLoading ? <EmptyLoaderStatic />
                : source == "latest-audios" || source == "audio-genre" || source == "audio-albums" || source == "artists" || source == "musician" || source == "song-writer" || source == "playlist" || source == "music-station" && getSource.includes(source) ? <MusicFormatViewAll />
                    : <h1 className='text-center py-5'>No Data Found!</h1>
            }
        </div>
    )
}
const mapStateToProps = state => ({
    getSource: state.homeLists.getSource,
    isLoading: state.homeLists.isLoading
})

export default connect(mapStateToProps)(MusicViewAllIndex)