import axios from "axios";

export const LanguagePreferenceAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Translate-Languages`)
        let result = response?.data?.Translate_Languages;
        dispatch({ type: "GET_DISPLAYTRANSLATELANGUAGE", payload: result });
    }
    catch (error) {
        dispatch({ type: "GET_DISPLAYTRANSLATELANGUAGE_FAILED", payload: error });
    };
}