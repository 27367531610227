import "../HomePage/Style/musicSwiper.css"
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Navigation } from "swiper/modules";
import MusicBannerPanelHorizontal from './MusicBannerPanelHorizontal';
import MusicSuggestion from './MusicSuggestion';
import { MusicDetailsAction } from '../../Redux/Actions/MusicDetailsAction';
import { wishListAction } from '../../Method/CommonMethod';

const MusicDetails = ({ topArtists, playlistShow, otherAudios, songDetailsData }) => {
    const { songname } = useParams()
    const dispatch = useDispatch()
    const containerRef = useRef(null);
    const location = useLocation();

    const [containerWidth, setContainerWidth] = useState(992);
    const [domReload, setDomReLoad] = useState(false)

    const calculateSlidesPerView = () => {
        if (containerWidth < 320) { return 1; }
        else if (containerWidth < 450) { return 2; }
        else if (containerWidth < 650) { return 3; }
        else if (containerWidth < 820) { return 4; }
        else if (containerWidth < 992) { return 5; }
        else if (containerWidth < 1400) { return 7; }
        else { return 7; }
    };

    const swiperConfig = {
        slidesPerView: calculateSlidesPerView(),
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: calculateSlidesPerView(),
        modules: [Navigation],
    }

    const handleWishList = () => {
        wishListAction(songDetailsData?.id, 5);
        setDomReLoad(!domReload)
    }

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
            }
        };
        handleResize();
        calculateSlidesPerView()
    }, [playlistShow, containerRef?.current?.clientWidth]);

    useEffect(() => {
        dispatch(MusicDetailsAction('AUDIO', songname))
    }, [location, domReload])

    return (
        <div ref={containerRef} className="pb-5">
            <MusicBannerPanelHorizontal propsWishListAction={handleWishList} propsType="AUDIO" />
            <MusicSuggestion swiperOptions={swiperConfig} propsType="AUDIO" />
        </div>

    )
}
const mapStateToProps = state => ({
    settings: state.settingsDefault.settings,
    topArtists: state.musicDetails.topArtists,
    otherAudios: state.musicDetails.otherAudios,
    playlistShow: state.toggleState.playlistShow,
    songDetailsData: state.musicDetails.songDetailsData,
})
export default connect(mapStateToProps)(MusicDetails)