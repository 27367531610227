import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import EmptyLoaderStatic from '../../Components/CommonComponents/Loaders/EmptyLoaderStatic';
import PageError from '../NotFound/PageError';
import MusicLayoutViewAll from './MusicLayoutViewAll';
import { MusicViewAllAction } from '../../Redux/Actions/MusicViewAllAction';
import { getItem, getItemToken } from '../../Utils/localStorageUtils';
import { musicCategoryPageList } from '../../Config/Api/MusicApi';

const MusicBasedCategoryViewAll = ({ settings, viewAllData, isLoading, error }) => {
  const { slug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const userRoleLocal = getItem('role')
  const userIdLocal = getItem('user_id')
  const userAccessToken = getItemToken('access_token');

  useEffect(() => {

    if (settings && Object.keys(settings).length > 0) {
      dispatch(MusicViewAllAction('CATEGORY', musicCategoryPageList, slug));
    }

  }, [settings, userRoleLocal, userIdLocal, location]);
  return isLoading ? <EmptyLoaderStatic /> : error ? <PageError /> : <div className='px-2 px-md-4'><MusicLayoutViewAll mapData={viewAllData?.[0]?.category__audios} source={slug} path='music' headingClass='pb-4 text-center' basedCard /></div>

}
const mapStateToProps = (state) => ({
  viewAllData: state.musicViewAll.viewAllData,
  isLoading: state.musicViewAll.isLoading,
  error: state.musicViewAll.error,
  settings: state.settingsDefault.settings,
});


export default connect(mapStateToProps)(MusicBasedCategoryViewAll)