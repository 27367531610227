import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LazyLoadImage from '../LazyLoadImage';
import MessageBox from '../MessageBox';
import PlayButton from '../Button/PlayButton';
const MusicWriterCard = ({ store, languageDetailsData }) => {
    return (
        <div className='p-2 rounded-2 w-100 h-100 theme-border-secondary position-relative '>
            <LazyLoadImage key={store?.artist_name} src={store?.image_url} alt={store?.artist_name} title={store?.artist_name} imageSize="rounded-2  overflow-hidden musicImage object-fit-cover" classNameOptional="rounded-2 musicImageHeight" />
            <Link to={`/${languageDetailsData?.Language_Code}/artist/${store?.artist_slug}`}> <MessageBox classname='playCardTitle py-2 theme-text-color' text={store?.artist_name} /></Link>
            <MessageBox classname='playCardDescription theme-text-color opacity-75' text={store?.description} />
            <PlayButton type="ARTIST" store={store} />
        </div>
    )
}
const mapStateToProps = state => ({
    settings: state.settingsDefault.settings,
    currentToggle: state.currentMusic.currentToggle,
    playlistShow: state.toggleState.playlistShow,
    currentTrackId: state.currentMusic.currentTrackId,
    languageDetailsData: state.musicLanguage.languageDetailsData
})
export default connect(mapStateToProps)(MusicWriterCard)