// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import { connect } from 'react-redux';
import HomePageNav from '../../Components/CommonComponents/HomePageNav';
import MusicPlaylistCard from '../../Components/CommonComponents/Cards/MusicPlaylistCard';
const MusicPlaylist = ({ item, swiperOptions, languageDetailsData }) => {

    return (
        <div className='mb-4'>
            <HomePageNav link={item?.source} headerName={item?.header_name} store={item?.data} seg={`${languageDetailsData?.Language_Code}/`} />
            <Swiper {...swiperOptions} className='musicAlbumSwiper'>
                {item?.data?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicPlaylistCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.musicLanguage.languageDetailsData
})

export default connect(mapStateToProps)(MusicPlaylist)