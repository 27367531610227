import React from 'react'

const EmptyLoaderNav = () => {
    return (
        <div className='d-flex align-items-center justify-content-between mb-3'>
            <p className='navHeight'>
                <span className='square h-100 rounded-1 d-block'></span>
            </p>
            <p className='navHeight'>
                <span className='square h-100 rounded-1 d-block'></span>
            </p>
        </div>
    )
}

export default EmptyLoaderNav