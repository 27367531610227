import axios from "axios";
import { musicSetting } from "../../Config/Api/MusicApi";
import { musicSettings, musicSettings_FAILED } from "../ActionTypes";

export const MusicSettingsAction = () => async (dispatch) => {
    try {
        const response = await axios.get(`${musicSetting}`)
        const ListRes = response?.data?.Music_Setting
        dispatch({ type: musicSettings, payload: ListRes });
    }
    catch (error) {
        dispatch({ type: musicSettings_FAILED, payload: error });
    };
}
