import "./MusicLayout.css"
import { connect, useDispatch } from 'react-redux';
import PlaylistModal from "../Modals/PlaylistModal";
import MusicStationModal from "../Modals/MusicStationModal";
import PurchaseModal from "../Modals/PurchaseModal";
import MusicSideBar from "./Sidebar/MusicSideBar";
import MusicListPanel from "./Panel/MusicListPanel";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import SignInPopup from "../Auth/SignInPopup";
import SignUpPopup from "../Auth/SignUpPopup";
import MusicFloatTrack from "../Player/MusicFloatTrack";
import MusicMiniFloatTrack from "../Player/MusicMiniFloatTrack";
import LazyLoadImage from "../CommonComponents/LazyLoadImage";
import { useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import Header from "./Header/Header";

const MusicLayout = ({ authModalSignUp, authModalSignIn, playlistModal, stationModal, purchaseModal, playlistShow, getMusicData, minimizePlayer, languageDetailsData, themeMode }) => {



  const [toggleSideNav, setToggleSideNav] = useState(false)

  const toggleSideBar = () => {
    setToggleSideNav(!toggleSideNav)
  }

  return (
    <div className='musicHeight'>
      {playlistModal && <PlaylistModal />}
      {stationModal && <MusicStationModal />}
      {purchaseModal && <PurchaseModal />}
      {authModalSignIn && <SignInPopup />}
      {authModalSignUp && <SignUpPopup />}
      {getMusicData?.enable_floating && !minimizePlayer ? <MusicFloatTrack /> : null}
      {minimizePlayer && <MusicMiniFloatTrack />}
      <MusicSideBar className={toggleSideNav ? 'activeSideBar' : ''} closeSideBar={toggleSideBar} />
      {/* <div className='d-flex align-items-center justify-content-between px-3 py-2 theme-bg-color-secondary position-fixed mobileSideBar  d-lg-none'>
        <FaBars onClick={toggleSideBar} />
        <Link to="/" className="py-2 position-relative homeNavbarBrand" style={{ width: "171px", height: "42px", objectFit: "contain" }}>
          <LazyLoadImage src={themeMode?.logoUrl} alt="header-logo" imageSize="loaderMain object-fit-contain " classNameOptional="rounded-2" />
        </Link> */}
      {/* <div>
          <div className='d-flex align-items-center justify-content-between  '>
            <button type='button' className='theme-bg-color  border-0 p-0 rounded-circle musicGoBackBtn' onClick={goBack} disabled={location.pathname === `/${languageDetailsData?.Language_Code}`}><MdKeyboardArrowLeft className='musicArrow' /></button>
            <button type='button' className='theme-bg-color  border-0 p-0 rounded-circle' onClick={goForward}><MdKeyboardArrowRight className='musicArrow' /></button>
          </div>
        </div> */}
      {/* </div> */}
      <div className="rightMusicContainer paddingTop">
        <Header toggleSideBar={toggleSideBar} />
        {/* <img src="https://images.squarespace-cdn.com/content/v1/54ec2161e4b01dbc251cbdae/8109841b-663b-476b-beee-e72bd234ee95/best-ideas-For-Successful-Banner-Advertising-30.jpg" className="w-100 rounded-3 p-2 overflow-hidden" style={{height:"250px",objectFit:"cover"}}/> */}
        <div className='d-flex flex-wrap flex-md-row'>
          <div className={`${!playlistShow ? 'col-12' : 'col-lg-8 '} col-12  position-relative px-md-4 px-1`}>
            <div className={`rounded-3 musicListHeight ${getMusicData?.enable_floating && !minimizePlayer && 'activeFloat'}`}>
              <Outlet />
            </div>
          </div>
          {playlistShow && <div className='col-12 col-lg-4 pe-lg-2 slide-right'> <MusicListPanel /> </div>}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  playlistShow: state.toggleState.playlistShow,
  playlistModal: state.toggleState.playlistModal,
  stationModal: state.toggleState.stationModal,
  authModalSignIn: state.toggleState.authModalSignIn,
  authModalSignUp: state.toggleState.authModalSignUp,
  purchaseModal: state.toggleState.purchaseModal,
  minimizePlayer: state.toggleState.minimizePlayer,
  getMusicData: state.musicSettings.getMusicData,
  languageDetailsData: state.musicLanguage.languageDetailsData,
  themeMode: state.headerDetails.themeMode
})


export default connect(mapStateToProps)(MusicLayout)