// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbarWrapper {
    height: 66px;
}

.musicSearch {
    max-width: 300px;
    width: 300px;
    padding-left: 37px;
    margin-left: 15px;
}

.musicSearch:focus {
    border: none;
    outline: none;
}

.musicSearch::placeholder {
    font-size: 13px;
}

.profileNavigation,
.profileToggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;;IAEI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,UAAU;AACd","sourcesContent":[".navbarWrapper {\n    height: 66px;\n}\n\n.musicSearch {\n    max-width: 300px;\n    width: 300px;\n    padding-left: 37px;\n    margin-left: 15px;\n}\n\n.musicSearch:focus {\n    border: none;\n    outline: none;\n}\n\n.musicSearch::placeholder {\n    font-size: 13px;\n}\n\n.profileNavigation,\n.profileToggle {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
