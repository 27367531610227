import React, { useEffect, useState } from 'react'
import LazyLoadImage from '../../Components/CommonComponents/LazyLoadImage'
import "./ProfilePage.css"
import { connect, useDispatch } from 'react-redux'
import { IconMyCamera, IconMyEdit, IconMyUpload } from '../../Assets/Icons/IconComponents/MyIcons'
import MessageBox from '../../Components/CommonComponents/MessageBox'
import { getItem, getItemToken } from '../../Utils/localStorageUtils'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { ProfileDetailsAction } from '../../Redux/Actions/ProfileDetailsAction'
import { AUTH_MODAL_SIGNIN } from '../../Redux/ActionTypes'
import ButtonWithLoader from '../../Components/CommonComponents/Loaders/ButtonWithLoader'
import { FaCrown } from 'react-icons/fa'
const ProfilePage = ({ profileDetails }) => {
    const [profileData, setProfileData] = useState()
    const [errField, setErrField] = useState({})
    const dispatch = useDispatch()
    const [profileImage, setProfileImage] = useState()
    const [loading, setLoading] = useState(false)
    const [enable, setEnabled] = useState({
        userEdit: false,
        emailEdit: false,
    })

    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessToken = getItemToken('access_token');

    const { t } = useTranslation()

    useEffect(() => {
        setProfileData(profileDetails?.Profile_details)
    }, [profileDetails])

    const handleChange = (e) => {
        const { value, name } = e.target;
        setProfileData((prev) => ({
            ...prev,
            [name]: value

        }))

    }

    const handleEditEnable = (value) => {
        setEnabled((prev) => ({
            ...prev,
            [value]: !prev[value]
        }))
    }
    const data = {
        Login_user_type: userRoleLocal,
        Login_user_id: userIdLocal,
        Login_device_type: "web",
        name: profileData?.username,
        username: profileData?.username,
        email: profileData?.email,
        // ccode: profileData?.ccode,
        // mobile: profileData?.mobile,
        // avatar: profileImage,
    }

    const validForm = () => {
        let formIsValid = true;
        if (!profileData?.username || profileData?.username == null) {
            setErrField((prevState) => ({ ...prevState, usernameerr: `${t('Please Enter Username')}*` }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, usernameerr: "" }));
        }
        if (!profileData?.email) {
            setErrField((prevState) => ({ ...prevState, emailerr: `${t('Please Enter Email')}*`, }));
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(profileData?.email)) {
            setErrField((prevState) => ({ ...prevState, emailerr: `${t('Please Enter Valid Email ID')}*`, }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, emailerr: "" }));
        }
        if (!profileData?.mobile) {
            setErrField((prevState) => ({ ...prevState, numbererr: `${t('Please Enter Number')}*` }));
            formIsValid = false;
        }
        else if (profileData?.mobile?.length < 6) {
            setErrField((prevState) => ({ ...prevState, numbererr: `${t('Please Enter Minimum 6 Digit')}*` }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, numbererr: "" }));
        }
        return formIsValid;
    };

    const handleUpdate = async (e) => {
        if (validForm()) {
            try {
                setLoading(true)
                const response = await axios.post(
                    `${process.env.REACT_APP_Baseurl}/Front-End/Profile-details-update`, data,
                    { headers: userAccessToken })
                if (response) {
                    if (response?.data?.status === true) {
                        toast.success(t("Account Updated Successfully"));
                        dispatch(ProfileDetailsAction())
                        setEnabled((prev) => ({
                            userEdit: false,
                            emailEdit: false,
                        }))
                    }
                    setLoading(false)
                }
                else {
                    throw new Error("Request failed with status: " + response.status);
                }
            } catch (error) {
                alert("Error updating user");
                console.log("Error fetching user accesses:", error);
            }
        }
        else {
            console.log('Form validation failed');
        }

    };

    const handleProfileImageUpdate = async () => {
        const formDate = new FormData()
        formDate.append("user_id", userIdLocal)
        formDate.append("avatar", 'gtdfsadfas')
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Baseurl}/Front-End/users/avatar-update`, formDate,
                { headers: userAccessToken })
            if (response) {
                if (response?.data?.status === true) {
                    toast.success(t("Account Updated Successfully"));
                    dispatch(ProfileDetailsAction())
                    setProfileImage(null)
                }
            }
            else {
                throw new Error("Request failed with status: " + response.status);
            }
        } catch (error) {
            toast.error("Error updating image");
            setProfileImage(null)
            console.log("Error fetching user accesses:", error);
        }
    }

    const handleLoginPopup = () => {
        dispatch({ type: AUTH_MODAL_SIGNIN, payload: true })
    }

    const handleCoverImageChange = async (event, errorKey) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfileImage(selectedFile);
            };
            reader.readAsDataURL(selectedFile);
            // handleProfileImageUpdate()
        }
    };



    return (
        userRoleLocal ? <div className='px-md-5 px-3 pt-5 pb-4 theme-bg-color-secondary rounded-4 '>
            <div className='d-flex flex-wrap align-items-center '>
                <div className='d-flex align-items-center justify-content-center justify-content-md-start  col-12 col-lg-9 flex-wrap' >
                    <div className='position-relative profileImageUpdate mx-md-0 mx-auto' >
                        <LazyLoadImage key={'logo'} src={profileImage ? URL.createObjectURL(profileImage) : profileDetails?.Profile_details?.user_avatar} alt={'logo'} title={profileDetails?.Profile_details?.username.slice(0, 1)} imageSize="overflow-hidden  object-fit-cover" classNameOptional="rounded-circle profileImageUpdate" />
                        <span className='profileImageLens position-absolute theme-bg-color-secondary p-2 rounded-circle d-flex align-items-center justify-content-center' >
                            <IconMyCamera styled={{ width: "20px", height: "20px", className: "" }} />
                        </span>
                        <input type="file" onChange={(event) => handleCoverImageChange(event)} className='profileInput w-100 h-100 position-absolute' accept='image/*' />
                        <IconMyUpload styled={{ width: "20px", height: "20px", className: "position-absolute uploadIcon" }} />
                    </div>
                    <div className='mSmEnd text-center text-md-start mt-4 mt-md-0'>
                        <p className='userProfileName mb-0 text-break'>{profileDetails?.Profile_details?.username}</p>
                        <p className='userProfileEmail mb-0 opacity-75'>{profileDetails?.Profile_details?.email}</p>
                        <p className='userProfileEmail mb-0 mt-1 opacity-75'>{profileDetails?.Profile_details?.mobile}</p>
                    </div>
                </div>
                <div className='p-3 mt-4 mt-md-0 shadow rounded-3 col-12 col-lg-3 align-self-start text-center text-md-start'>
                    <h2 className='currentHeading mb-2'>Current Plan</h2>
                    <p className='currentPlan mb-0'> <FaCrown style={{ width: "25px", height: "25px", color: "gold" }} className='mSmEnd' /> {profileDetails?.Profile_details?.Profile_user_type == "Subscriber-User" ? "$99 / Month" : "0"} </p>
                </div>
            </div>

            <div className='d-flex mt-5 flex-wrap justify-content-between'>
                <div className="col-lg-6 pSmEnd">
                    <form autoComplete='off'>
                        <div className='position-relative'>
                            <span className='d-block text-start mb-2'>Name</span>
                            <input type='text' className='form-control profileInputPad rounded-2  theme-bg-color mb-4 theme-text-color' value={profileData?.username} name="username" onChange={(e) => handleChange(e)} disabled={!enable?.userEdit} />
                            {errField?.usernameerr !== "" && (
                                <span className="d-flex text-danger errortext position-absolute">{errField?.usernameerr}</span>
                            )}
                            {!enable?.userEdit && <button type='button' onClick={() => handleEditEnable("userEdit")} className='bg-transparent p-0 position-absolute editIcon theme-text-color'>
                                <IconMyEdit styled={{ width: "20px", height: "20px", className: "" }} />
                            </button>}
                        </div>
                        <div className='position-relative'>
                            <span className='d-block text-start mb-2'>Email </span>
                            <input type='email' className='form-control profileInputPad rounded-2 theme-bg-color theme-text-color' value={profileData?.email} name="email" onChange={(e) => handleChange(e)} disabled={!enable?.emailEdit} />
                            {errField?.emailerr !== "" && (
                                <span className="d-flex text-danger errortext position-absolute">{errField?.emailerr}</span>
                            )}
                            {!enable?.emailEdit && <button type='button' onClick={() => handleEditEnable("emailEdit")} className='bg-transparent p-0 position-absolute editIcon theme-text-color'>
                                <IconMyEdit styled={{ width: "20px", height: "20px", className: "" }} />
                            </button>}
                        </div>
                        <ButtonWithLoader text="Update" className="mt-5 btn updateBtn text-center d-flex align-items-center justify-content-center border-0 ms-auto" loading={loading} action={handleUpdate} />
                    </form>
                </div>
                <div className="col-lg-6 pSmEnd">
                    {/* <div className='p-4 shadow rounded-3'>
                        <h2 className='currentHeading mb-4'>Current Plan</h2>
                        <p className='currentPlan'> <FaCrown style={{ width: "25px", height: "25px", color: "gold" }} className='' /> $ 99 / Month</p>
                    </div> */}
                    <p className='textRight'>Transaction history </p>
                </div>
            </div>
        </div> : <div className='d-flex align-items-center justify-content-center py-5'>
            <button onClick={() => handleLoginPopup()} className='btn rounded-3 '><MessageBox text="Login" /></button>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.musicLanguage.languageDetailsData,
    profileDetails: state.profileDetails.profileDetails,
})


export default connect(mapStateToProps)(ProfilePage)
