import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "react-toastify/dist/ReactToastify.css";

import MusicLayout from './Components/Layout/MusicLayout';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NoPage from './Pages/NotFound/NoPage';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './Style/GlobalStyles';
import MusicHomePage from './Pages/HomePage/MusicHomePage';
import MyProvider from "./Context/CommonProvider.js"
import { Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { MusicLanguageAction } from './Redux/Actions/MusicLanguageAction.js';
import MusicViewAllIndex from './Pages/ViewAllPage/MusicViewAllIndex.jsx';
import MusicBasedWriterViewAll from './Pages/ViewAllPage/MusicBasedWriterViewAll.jsx';
import MusicBasedMusicianViewAll from './Pages/ViewAllPage/MusicBasedMusicianViewAll.jsx';
import MusicBasedCategoryViewAll from './Pages/ViewAllPage/MusicBasedCategoryViewAll.jsx';
import MusicBasedAlbumViewAll from './Pages/ViewAllPage/MusicBasedAlbumViewAll.jsx';
import MusicBasedLanguageViewAll from './Pages/ViewAllPage/MusicBasedLanguageViewAll.jsx';
import MusicPlaylistDetails from './Pages/DetailsPage/MusicPlaylistDetails.jsx';
import MusicDetails from './Pages/DetailsPage/MusicDetails.jsx';
import ArtistsDetails from './Pages/DetailsPage/ArtistsDetails.jsx';
import AlbumDetails from './Pages/DetailsPage/AlbumDetails.jsx';
import LyricsPage from './Pages/LyricsPage/LyricsPage.jsx';
import { MusicDefaultSettingAction } from './Redux/Actions/MusicDefaultSettingAction.js';
import { CommonThumbnailAction } from './Redux/Actions/CommonThumbnailAction.js';
import { MusicSettingsAction } from './Redux/Actions/MusicSettingsAction.js';
import { MusicHomePageAction } from './Redux/Actions/MusicHomeAction.js';
import { getItemToken, getItem } from './Utils/localStorageUtils.js';
import { ToastContainer } from 'react-toastify';
import { HeaderAction } from './Redux/Actions/HeaderAction.js';
import { ProfileDetailsAction } from './Redux/Actions/ProfileDetailsAction.js';
import SettingPage from './Pages/UserPage/SettingPage.jsx';
import StationDetails from './Pages/DetailsPage/StationDetails.jsx';
import PageLoader from './Components/CommonComponents/Loaders/PageLoader.jsx';
import ProfilePage from './Pages/UserPage/ProfilePage.jsx';
import SearchPage from './Pages/SearchPage/SearchPage.jsx';
import GenreDetails from './Pages/DetailsPage/GenreDetails.jsx';
import WishListPage from './Pages/UserPage/WishListPage.jsx';
function App({ languageDetailsData, themeMode }) {

  // localstrage
  const userRoleLocal = getItem('role')
  const userIdLocal = getItem('user_id')
  const userAccessToken = getItemToken('access_token');

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(MusicLanguageAction())
    dispatch(MusicDefaultSettingAction())
    dispatch(CommonThumbnailAction())
    dispatch(MusicSettingsAction());
    dispatch(HeaderAction())
  }, [])



  useEffect(() => {
    if (userRoleLocal) {
      dispatch(ProfileDetailsAction())
    }
    dispatch(MusicHomePageAction())
  }, [userRoleLocal])


  useEffect(() => {
    if (languageDetailsData?.Language_Code && location.pathname == '/') {
      navigate(`/${languageDetailsData?.Language_Code}/`)
    }
  }, [location])

  useEffect(() => {
    if (languageDetailsData) {
      const newPath = `${languageDetailsData?.Language_Code}${location.pathname.substring(location.pathname.indexOf('/', 1))}`;
      navigate(newPath);
    }
   
  }, [languageDetailsData])

  return (
    <ThemeProvider theme={themeMode}>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <GlobalStyles />
      <MyProvider>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route path="/:lang" element={<MusicLayout />}>
              <Route index element={<MusicHomePage />} />
              <Route path=":source" element={<MusicViewAllIndex />}></Route>
              <Route path="plan" element={<h3  className='text-center py-4'>Upcoming Plan </h3>}></Route>
              <Route path="song-writer/:slug" element={<MusicBasedWriterViewAll />}></Route>
              <Route path="musician/:slug" element={<MusicBasedMusicianViewAll />}></Route>
              <Route path="audio-genre/:slug" element={<MusicBasedCategoryViewAll />}></Route>
              <Route path="audio-albums/:slug" element={<MusicBasedAlbumViewAll />}></Route>
              <Route path="language/:slug" element={<MusicBasedLanguageViewAll />}></Route>
              <Route path="playlist/:songname" element={<MusicPlaylistDetails />} />
              <Route path="track/:songname" element={<MusicDetails />} />
              <Route path="artist/:artistname" element={<ArtistsDetails />} />
              <Route path="album/:albumname" element={<AlbumDetails />} />
              <Route path="genre/:genrename" element={<GenreDetails />} />
              <Route path="lyrics" element={<LyricsPage />} />
              <Route path="user/settings" element={<SettingPage />} />
              <Route path="user/profile" element={<ProfilePage />} />
              <Route path="station/:stationslug" element={<StationDetails />} />
              <Route path="search" element={<SearchPage />}></Route>
              <Route path="wishlist" element={<WishListPage />}></Route>
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </Suspense>
      </MyProvider>
    </ThemeProvider>

  );
}
const mapStateToProps = state => ({
  languageDetailsData: state.musicLanguage.languageDetailsData,
  themeMode: state.headerDetails.themeMode,
})

export default connect(mapStateToProps)(App)
