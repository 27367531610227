// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from 'react-redux';
import HomePageNav from '../../Components/CommonComponents/HomePageNav';
import MusicBasedWriterCard from '../../Components/CommonComponents/Cards/MusicBasedWriterCard';

const MusicBasedWriter = ({ item, swiperOptions,languageDetailsData }) => {
    return (
        <div className='mb-4'>
            <HomePageNav link={item?.artist_slug} headerName={item?.artist_name} store={item?.Song_Writer} seg={`${languageDetailsData?.Language_Code}/song-writer/`} />
            <Swiper {...swiperOptions} className='musicHomePageSwiper'>
                {item?.Song_Writer?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicBasedWriterCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.musicLanguage.languageDetailsData
})
export default connect(mapStateToProps)(MusicBasedWriter)