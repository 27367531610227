import { connect, } from 'react-redux';
import { Link } from 'react-router-dom';
import LazyLoadImage from '../LazyLoadImage';
import MessageBox from '../MessageBox';
import PlayButton from '../Button/PlayButton';
const MusicSearchCard = ({ store, currentAudio, languageDetailsData, type, path }) => {
    return (
        <div className='p-2 rounded-2 w-100 h-100 theme-border-secondary position-relative searchPageCard '>
            <LazyLoadImage key={store?.title} src={store?.image_url} alt={store?.title} title={store?.title} imageSize="rounded-2  overflow-hidden musicImage object-fit-cover" classNameOptional={`rounded-2 musicImageHeight position-relative ${currentAudio?.id == store?.id ? 'after' : ""}`} />
            <Link to={`/${languageDetailsData?.Language_Code}/${path}/${store?.slug ? store?.slug : store?.artist_slug}`}> <MessageBox classname='playCardTitle py-2 theme-text-color' text={store?.title ? store?.title : store?.artist_name ? store?.artist_name : store?.albumname ? store?.albumname : store?.name} /></Link>
            <MessageBox classname='playCardDescription theme-text-color opacity-75' text={store?.description} />
            <PlayButton type={type} store={store} />
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.musicLanguage.languageDetailsData,
    currentAudio: state.currentMusic.currentAudio,
})

export default connect(mapStateToProps)(MusicSearchCard)