import axios from "axios";
import { musicHomePageApi } from "../../Config/Api/MusicApi";
import { getItem, getItemToken } from "../../Utils/localStorageUtils";
import { GET_MUSIC_HOMEPAGE, GET_MUSIC_HOMEPAGE_FAILED } from "../ActionTypes";
import { demoUser } from "../../Method/CommonMethod";

export const MusicHomePageAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${musicHomePageApi}`, {
            Login_user_type: parseInt(getItem('role'), 10) || demoUser.userRole,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
        }, { headers: getItemToken('access_token') || null })
        const ListRes = response?.data?.Home_page;
        dispatch({ type: GET_MUSIC_HOMEPAGE, payload: ListRes });

    }
    catch (error) {
        dispatch({ type: GET_MUSIC_HOMEPAGE_FAILED, payload: error });
    };
}
