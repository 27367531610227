import React, { useState } from 'react'
import { connect } from 'react-redux';
import MessageBox from '../../Components/CommonComponents/MessageBox';
import { URLDashRemover } from '../../Method/CommonMethod';
import MusicBasedCardView from '../../Components/CommonComponents/Cards/MusicBasedCardView';
import MusicViewCard from '../../Components/CommonComponents/Cards/MusicViewCard';
import PaginationViewAll from './PaginationViewAll';
import ListLayout from './ListLayout';

const MusicLayoutViewAll = ({ mapData, source, path, headingClass, playlistShow, basedCard, settings, methodAction, layoutSwitcher }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const isPathExcluded = (excludedPaths) =>
        excludedPaths.some((path) => {
            return path == source;
        });

    return (
        <>
            {source ? <h2 className={`viewAllTitle ${headingClass ? headingClass : 'text-center'} mb-0 theme-text-color`}><MessageBox text={URLDashRemover(source)} /></h2> : ''}
            <div className="musicViewAllPage d-flex align-items-center  flex-wrap">
                {mapData?.slice((currentPage - 1) * settings?.videos_per_page, currentPage * settings?.videos_per_page)
                    .map((value, index) => (
                        layoutSwitcher == "list" && !isPathExcluded(["playlist", "audio-albums", "audio-genre", "music-station"]) ?
                            <ListLayout store={value} restrictUI={isPathExcluded} />
                            :
                            <div className={`${!playlistShow ? 'col-md-3 col-lg-2' : 'col-md-4 col-lg-3 '}  col-6 col-sm-4 musicViewAllPageList  mb-3 px-2 ${Number(Math.round((index + 1) % !playlistShow ? 6 : 4)) == '1' ? 'ps-0' : Number(Math.round((index + 1) % !playlistShow ? 6 : 4)) == '0' ? 'pe-0' : ''}`}>
                                {basedCard ?
                                    <MusicBasedCardView store={value} /> :
                                    <MusicViewCard store={value} methodAction={methodAction} />}
                            </div>
                    ))}
            </div>
            <PaginationViewAll data={mapData} handlePage={handlePageChange} currentPage={currentPage} visibleLength={settings?.videos_per_page} />
        </>
    )
}
const mapStateToProps = state => ({
    playlistShow: state.toggleState.playlistShow,
    settings: state.settingsDefault.settings,
    layoutSwitcher: state.toggleState.layoutSwitcher,
})


export default connect(mapStateToProps)(MusicLayoutViewAll)