import axios from "axios";

export const MusicDefaultSettingAction = () => async (dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Settings`)
        let result = response.data.setting;
        dispatch({ type: "GET_SETTING", payload: result });
    }
    catch (error) {
        dispatch({ type: "GET_SETTING_FAILED", payload: error });
    };
}