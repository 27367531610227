import "../../../Pages/HomePage/Style/style.css"
import { useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { connect, useDispatch } from 'react-redux'
import { FiPlusCircle } from 'react-icons/fi'
import EmptyPlaylistLoader from '../../CommonComponents/Loaders/EmptyPlaylistLoader'
import MessageBox from '../../CommonComponents/MessageBox';
import LazyLoadImage from '../../CommonComponents/LazyLoadImage';
import { MusicCurrentAction } from '../../../Redux/Actions/MusicCurrentAction';
import { MusicUpdateAction } from '../../../Redux/Actions/MusicUpdateAction';
import { OPEN_PLAYLIST_MODAL, OPEN_PURCHASE_MODAL } from "../../../Redux/ActionTypes"
import FollowButton from "../../CommonComponents/Button/FollowButton"
import PriceLabel from "../../CommonComponents/PriceLabel"
import defaultImage from '../../../Assets/Images/default-music.png'
import { IconMyPlay } from "../../../Assets/Icons/IconComponents/MyIcons"
import MusicEmbededTrack from "../../Player/MusicEmbededTrack"

const MusicListPanel = ({ playlists, currentAudio, getMusicData, minimizePlayer, isLoading, currentUserInteract, playlistData }) => {

  const dispatch = useDispatch();
  const [playlistToggle, setPlaylistToggle] = useState(true)

  const { lang } = useParams()

  const handleCurrentAudioPlay = async (audiolist, index) => {
    if (audiolist?.audio_access) {
      await dispatch(MusicUpdateAction('AUDIO', audiolist?.slug))
      await dispatch(MusicCurrentAction(index, playlists, 0))
    }
    else {
      dispatch({ type: OPEN_PURCHASE_MODAL, payload: true, upComingTitle: audiolist })
    }
  };
  const handlePlaylistToggle = () => {
    setPlaylistToggle(!playlistToggle)
  }

  const handlePlaylistModal = (id) => {
    if (currentAudio?.id) {
      dispatch({ type: OPEN_PLAYLIST_MODAL, payload: true })
      dispatch({ type: "GET_PLAYLIST_STORE_ID", payload: id })
    }
    else {
      return null
    }
  };
  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short'
  });

  return (
    <div className={`${getMusicData?.enable_embed ? 'theme-bg-color-secondary' : ''}  rounded-3 pb-5 musicListHeightPanel ${getMusicData?.enable_floating && !minimizePlayer && 'activeFloat'}`}>
      {getMusicData?.enable_embed ? <MusicEmbededTrack toggleAction={handlePlaylistToggle} /> : null}
      {currentAudio?.id && <div className='p-2'>
        {playlistToggle && <h4 className="theme-text-color px-3 px-md-2 px-lg-4  py-md-2 py-lg-3 py-2 playlistHeading position-relative">{playlistData?.id ? <> <MessageBox text="Song From" classname='theme-text-color' /> <MessageBox text={playlistData?.artist_name ? playlistData?.artist_name : playlistData?.albumname ? playlistData?.albumname : playlistData?.title ? playlistData?.title : playlistData?.name} classname='theme-text-color-button' /> </> : <MessageBox text="Playlist" classname='medium-heading' />}</h4>}
        {isLoading ? Array.from({ length: 5 }).map((data, index) => { return <EmptyPlaylistLoader /> }) : playlistToggle && playlists?.map((audio, index) => {
          return (
            <div key={audio?.id} className={`${audio?.id} d-flex align-items-center justify-content-between  px-2  py-2 listChip position-relative rounded-2 `} >
              <div className='col-7'>
                <div className='d-flex align-items-center position-relative '>
                  <div className={`ListplayButton`}>
                    <p className={`numberList mb-0`}>{index + 1}</p>
                    <button className="buttonList bg-transparent p-0 " onClick={() => handleCurrentAudioPlay(audio, index)}><IconMyPlay styled={{ width: "20px", height: "20px", className: "theme-text-color-button" }} /></button>
                  </div>
                  {getMusicData?.enable_playlist_image ? <div className='position-relative'>
                    <Link to={`/${lang}/track/${audio?.slug}`}>
                      <LazyLoadImage src={audio?.Player_thumbnail ? audio?.Player_thumbnail : audio?.Player_image_url} alt={audio?.title} title={audio?.title?.slice(0, 1)} imageSize="rounded-2 overflow-hidden audioImage" classNameOptional="rounded-2 audioImage" />
                    </Link>
                  </div> : null}
                  <div className='d-flex flex-column w-100 trackPlaylist'>
                    {getMusicData?.enable_playlist_title ? <MessageBox classname={`${currentAudio?.id == audio?.id ? "theme-text-color-button list-panel-active-music" : ""} audioListText d-block`} text={audio?.title} /> : null}
                    {getMusicData?.enable_playlist_artists ? <div className={`${currentAudio?.id == audio?.id ? "list-panel-active-music" : " opacity-75"} audioListName d-flex align-items-center  flex-wrap m-0`}>{audio?.artists?.slice(0, 2).map((name, index) => <Link to={`/${lang}/artist/${name.artist_slug}`} className=' d-block theme-text-color pe-1'> <MessageBox text={name?.name} />{audio?.artists?.length >= 2 && index + 1 !== 2 ? "," : ""} </Link>)}</div> : null}
                  </div>
                </div>
              </div>
              <div className='col-5'>
                <div className='d-flex align-items-center justify-content-end flex-wrap'>
                  <span className='d-none'>{audio?.id}</span>
                  <PriceLabel item={audio} className='position-relative rounded-2 listPanelPrice text-end text-md-center' />
                  <FiPlusCircle className="moreOption  mx-2 d-block likeIcon theme-text-color" onClick={() => handlePlaylistModal(audio?.id)} />
                  <span className='othersTime'>{audio?.duration.slice(3, 8)}</span>
                </div>
              </div>
            </div>
          )
        })}

        <div className=' mt-3 p-4 '>
          <h4 className="theme-text-color pb-3"><MessageBox text="Credits" classname='medium-heading' /></h4>
          {
            currentUserInteract?.artists?.map((item, index) => {
              return (
                <div className={`d-flex align-items-center justify-content-between ${currentUserInteract?.artists?.length == index + 1 ? ' ' : 'mb-4'}`}>
                  <div className='d-flex align-items-center '>
                    <Link to={`/${lang}/artist/${item.artist_slug}`}>
                      <LazyLoadImage key={item?.id} src={item?.Thumbnail ? item?.Thumbnail : defaultImage} alt={item?.Thumbnail ? item?.Thumbnail : defaultImage} title={item?.name?.slice(0, 1)} imageSize="artistsImageMusic rounded-circle  overflow-hidden object-fit-cover" classNameOptional="rounded-circle artistsImageMusic" />
                    </Link>
                    <div className="trackPlaylist">
                      <p className='theme-text-color  mb-0 audioLink text-decoration-none'><MessageBox text={item.artist_name} /></p>
                      <p className="d-block mb-0 mini-heading theme-text-color ">{formatter.format(item?.followers_count)} Followers , <MessageBox text={item?.artist_type} /></p>
                    </div>
                  </div>
                  <FollowButton artistDetails={item} current={currentUserInteract?.slug} pageType="CURRENT" />
                </div>
              )
            })
          }
        </div>

        <div className='p-4'>
          <h4 className="theme-text-color pb-3"><MessageBox text="Now Playing" classname='medium-heading ' /></h4>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center gap-2 '>
              <Link to={`/${lang}/track/${currentAudio.slug}`} >
                <LazyLoadImage key={currentAudio?.id} src={currentAudio?.image_url ? currentAudio?.image_url : currentAudio?.Thumbnail ? currentAudio?.Thumbnail : defaultImage} title={currentAudio?.name?.slice(0, 1)} imageSize="artistsImageMusic rounded-circle  overflow-hidden object-fit-cover" classNameOptional="rounded-circle artistsImageMusic" />
              </Link>
              <div className=''>
                <MessageBox classname='theme-text-color  audioLink d-block' text={currentAudio?.title} />
                <MessageBox classname='theme-text-color mini-heading d-inline' text={`${formatter.format(currentUserInteract?.Monthly_istener)}`} /> <MessageBox classname='theme-text-color mini-heading d-inline' text={`Monthly Listener`} />
              </div>
            </div>
            <Link to={`/${lang}/track/${currentAudio.slug}`} className={`playingViewAll playingcategory  px-3 py-2 bg-transparent text-center theme-text-color ${getMusicData?.enable_embed ? 'theme-border-color-reverse' : 'theme-border-secondary'} audioBorder"} `}><MessageBox text={"View"} classname='' /></Link>
          </div>
          <MessageBox classname='mt-3 d-block musicDescription' text={currentAudio?.description} />
          <div className='d-flex align-items-center gap-2 mt-4'>
            {currentAudio?.categories?.map((item, index) => {
              return (
                <Link to={`/${lang}/genre/${item.slug}`} className={`theme-text-color mini-heading text-decoration-none px-3 py-2 text-capitalize  audioBorder playingcategory ${getMusicData?.enable_embed ? 'theme-border-color-reverse' : 'theme-border-secondfffary'} `}><MessageBox text={item.name} /></Link>
              )
            })}
          </div>
        </div>

      </div>}
    </div>
  )
}

const mapStateToProps = state => ({
  currentAudio: state.currentMusic.currentAudio,
  playlists: state.playlists.playlists,
  isLoading: state.toggleState.isLoading,
  getMusicData: state.musicSettings.getMusicData,
  minimizePlayer: state.toggleState.minimizePlayer,
  currentUserInteract: state.currentMusic.currentUserInteract,
  playlistData: state.currentMusic.playlistData,
});
export default connect(mapStateToProps)(MusicListPanel)