import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  button {
    background-color:${({ theme }) => theme.button} !important;
    color:${({ theme }) => theme.buttonText} !important;
  }
  header{
    background-color: ${({ theme }) => theme.body};
  }
  a{
    color: ${({ theme }) => theme.text};
  }
  a:hover{
    text-decoration: none;
    color: ${({ theme }) => theme.text};
  }
  input::placeholder{
    color: ${({ theme }) => theme.text} !important;
  }
 

  //----- text-color  -----------

  .theme-text-color {
    color: ${({ theme }) => theme.text} !important;
  }
  .theme-text-color-secondary{
    color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .theme-text-color-body{
    color: ${({ theme }) => theme.body} !important;
  }
  .theme-text-color-button{
    color: ${({ theme }) => theme.button} !important;
  }


  //------ background color --------


  .theme-bg-color{
    background-color: ${({ theme }) => theme.body} !important;
  }
  .theme-bg-color-secondary{
    background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
 .theme-button-text-color{
    color:${({ theme }) => theme.buttonText} !important;
  }
  .theme-bg-color-reverse{
    background-color: ${({ theme }) => theme.text} !important;
  }
  .theme-button-bg-color{
    background-color:${({ theme }) => theme.button} !important;
  }


  //------- border color ----------


  .theme-border-color{
    border-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .theme-border-color-reverse{
    border: 2px solid ${({ theme }) => theme.body} !important;
  }
  .theme-border-secondary{
    border: 2px solid ${({ theme }) => theme.secondarycolor} !important;
  }
  .theme-border-bottom-secondary{
    border-bottom: 2px solid ${({ theme }) => theme.secondarycolor} !important;
  }

  //------- others ------

  .css-1fdsijx-ValueContainer,css-1xc3v61-indicatorContainer{
    background-color: ${({ theme }) => theme.secondarycolor} !important;
 }
  .playlistHeading::before{
     background-color:${({ theme }) => theme.button} !important;
  }
  .listChip:hover {
    background-color: ${({ theme }) => theme.body};
  }
  .css-13cymwt-control{
  border-color:transparent !important;
  border-width:0px !important;
  background-color:transparent !important;
  }

  .custom-placeholder::placeholder{
    color:${({ theme }) => theme.text} !important;
    font-family:"Poppins";
  }
  .sideMenu.active{
    background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .jodit-toolbar__box:not(:empty):not(:empty){
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
    width:100% !important;
      }
  .jodit-container:not(.jodit_inline) .jodit-workplace{
    background-color: ${({ theme }) => theme.secondarycolor} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .jodit-workplace+.jodit-status-bar:not(:empty){
    background-color: ${({ theme }) => theme.secondarycolor} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .form-select-full-border{
    border: 1px solid ${({ theme }) => theme.text} !important;
  }
  .css-d7l1ni-option{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .css-1nmdiq5-menu{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .css-d7l1ni-option:hover{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .css-1nmdiq5-menu:hover{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .rti--container{
    background-color: ${({ theme }) => theme.secondarycolor};
    color: ${({ theme }) => theme.text} !important;
    border:0 !important;
  }
  .rti--input{
    background-color: ${({ theme }) => theme.secondarycolor};
    color: ${({ theme }) => theme.text} !important;
  }
  .rti--tag{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .css-t3ipsp-control{
    background-color: ${({ theme }) => theme.secondarycolor};
  }
  .theme-text-color-list::after {
    background: ${({ theme }) => theme.text} !important;
  }
  .theme-bg-color-border-right{
    border-right: 1px solid ${({ theme }) => theme.text} !important;
  }
  .theme-text-color-bg {
    background-color: ${({ theme }) => theme.text} !important;
  }
  .headingText{
  color: ${({ theme }) => theme.text};
  }
  .theme-button-top-border {
    border-top: 1px solid ${({ theme }) => theme.text} !important;
    }
  .theme-button-bottom-border {
    border-bottom: 1px solid ${({ theme }) => theme.text} !important;
    }
  .theme-border-border-color {
    color: ${({ theme }) => theme.body};
    }
  .theme-border-border-color-all {
    border-bottom: 1px solid ${({ theme }) => theme.text} !important;
    }
  .toggleButton-border{
    border: 1px solid ${({ theme }) => theme.button} !important;
  }
  .bg-image-gradian::before{
    background-image: linear-gradient(to bottom, transparent, ${({ theme }) => theme.secondarycolor}, ${({ theme }) => theme.secondarycolor});
  }
  .linearShadow::before{
   background: linear-gradient(90deg, ${({ theme }) => theme.body} 15%, transparent 80%);
  }
  .bgButton{
    background-color:${({ theme }) => theme.button} !important;
    color: ${({ theme }) => theme.text} !important;
}.plan-lineargrade{
  background: linear-gradient(to right, ${({ theme }) => theme.button}, #0000) !important;
}

  #pageDropDown{
    background-color: ${({ theme }) => theme.body};
    margin-right:13px;
    border: 1px solid ${({ theme }) => theme.secondarycolor} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .react-bs-table-sizePerPage-dropdown .dropdown-menu {
    background-color: ${({ theme }) => theme.body} !important;
     color: ${({ theme }) => theme.text} !important;
  }
  .css-1p3m7a8-multiValue{
    background-color: ${({ theme }) => theme.body} !important;
  
  }
  .css-wsp0cs-MultiValueGeneric{
    color: ${({ theme }) => theme.text} !important;
  }
  .react-tel-input .country-list .search{
    background-color: ${({ theme }) => theme.body} !important;
  }
  .react-tel-input .country-list .search .search-box{
    background-color: ${({ theme }) => theme.body} !important;
    border: 1px solid ${({ theme }) => theme.text} !important;
  }
 .musicFollowBtn.inActive:hover{
  background-color: ${({ theme }) => theme.secondarycolor} !important;
 }
  .musicHomePageSwiper .swiper-slide:hover, .musicAlbumSwiper .swiper-slide:hover {
  background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .purchaseBtn:hover .infoIcon{
  color: ${({ theme }) => theme.body} !important;
  }
  .custom-shadow{
  box-shadow: 0px 0px 71px -18px  ${({ theme }) => theme.text} !important;
  }
  .progress{
    background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .highlighted {
   color: ${({ theme }) => theme.buttonText} !important;
  }
  .fillColor>path{
   fill:${({ theme }) => theme.text} !important;
   }
  .music-player-mini::after {
  //  background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .audioBanner::after{
    background-image: linear-gradient(90deg,  ${({ theme }) => theme.body} 35%, transparent);
  }
  .playingcategory:hover{
     background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .playingcategory.theme-border-color-reverse:hover{
     background-color: ${({ theme }) => theme.body} !important;
  }
  .musicFollowBtn.inActive.theme-border-color-reverse:hover{
    background-color: ${({ theme }) => theme.body} !important;
  }
  .playButton.yes::before{
    background-color:${({ theme }) => theme.button} !important;
  }
  .playButton.one::after{
  color:${({ theme }) => theme.button} !important;
  }
  .profileImageUpdate:hover:before{
  background-color: ${({ theme }) => theme.body} !important;
  }
  @media screen and (max-width: 768px) {
  .navbarWrapper{
    background-color: ${({ theme }) => theme.body} !important;
  }
    // .musicSideBar {
    //  background-image: linear-gradient(90deg,  ${({ theme }) => theme.body} 10%,${({ theme }) => theme.body} 20%, transparent);
    // }
  }
}
`;