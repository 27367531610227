import axios from "axios";
import { artistPageList, musicAlbum, musicArtist, musicDetails, musicGenrePlay, musicPlaylist, musicQueue, musicStationPlay, musicianPageList, writerPageList } from "../Config/Api/MusicApi";
import { toast } from "react-toastify";
import { getItem, getItemToken } from "../Utils/localStorageUtils";
import { MusicUpdateAction } from "../Redux/Actions/MusicUpdateAction";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { IconMyDisLike, IconMyDisLikeFill, IconMyHeart, IconMyHeartFill, IconMyLike, IconMyLikeFill } from "../Assets/Icons/IconComponents/MyIcons";

const URLGenerater = (URLNAME) => {
    return URLNAME.replace(/ /g, '-').replace(/_/g, '-').toLowerCase();
}
const URLDashRemover = (URLNAME) => {
    return capitalizeFirstLetter(URLNAME.replace(/-/g, ' '));
}

const capitalizeFirstLetter = (str) => {
    return str?.split(' ').map(word => word?.charAt(0).toUpperCase() + word?.slice(1)).join(' ');
};

const apiGenerate = (type, chooseobject) => {

    let resultObject;
    switch (type) {
        case 'AUDIO':
            resultObject = {
                slug: "audio_slug",
                details: "audios_details",
                playlistdetails: "audios",
                artists: "TopArtist",
                api: musicDetails,
                playapi: musicQueue
            };
            break;

        case 'ARTIST':
            resultObject = {
                slug: "artist_slug",
                details: "artist_details",
                playlistdetails: "artist_audios",
                artists: "TopArtist",
                api: musicArtist,
                playapi: musicArtist,
                pagelistApi: artistPageList
            };
            break;

        case 'PLAYLIST':
            resultObject = {
                slug: "playlist_slug",
                details: "playlist",
                playlistdetails: "data",
                artists: "TopArtist",
                api: musicPlaylist,
                playapi: musicPlaylist
            };
            break;

        case 'ALBUM':
            resultObject = {
                slug: "album_slug",
                details: "album_details",
                playlistdetails: "album_audios",
                artists: "TopAudioAlbum",
                api: musicAlbum,
                playapi: musicAlbum
            };
            break;
        case 'WRITER':
            resultObject = {
                slug: "writer_slug",
                pagelistApi: writerPageList
            };
            break;
        case 'CATEGORY':
            resultObject = {
                slug: "category_slug",
            };
            break;
        case 'MUSICIAN':
            resultObject = {
                slug: "musician_slug",
                pagelistApi: musicianPageList
            };
            break;

        case 'LANGUAGE':
            resultObject = {
                slug: "language_slug",
            };
            break;
        case 'STATION':
            resultObject = {
                slug: "station_slug",
                details: "MusicStation",
                playlistdetails: "data",
                artists: "",
                api: musicStationPlay,
                playapi: musicStationPlay
            };
            break;
        case 'GENRE':
            resultObject = {
                slug: "audio_genre_slug",
                details: "AudioGenre",
                playlistdetails: "category__audios",
                artists: "",
                api: musicGenrePlay,
                playapi: musicGenrePlay
            };
            break;
        default:
            throw new Error('Invalid type');
    }
    return resultObject[chooseobject];
}

export const LikeButtonAction = ({ source, sourceType, status, className }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleLike = async () => {
        if (source?.id) {
            await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Like-Update`, {
                Login_user_type: parseInt(getItem('role'), 10) || demoUser.userRole,
                Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
                Login_device_type: "web",
                source_id: source?.id,
                source_type: sourceType,
                like_status: status,
            }, { headers: getItemToken('access_token') || null }).then(response => {
                toast.success(t(response.data.message));
                dispatch(MusicUpdateAction('AUDIO', source?.slug))
            })
                .catch(err => {
                    const errorMessage = err.response?.data?.message || 'An error occurred';
                    toast.error(t(errorMessage));
                });
        }
        else {
            return null
        }
    }
    return (
        <button type="button" className={`playButton bg-transparent toolTipParent ${source?.id ? '' : 'opacity-75'} ${className} `} onClick={handleLike}>
            {/* <FiThumbsUp className={`likeIcon theme-text-color  ${source?.like == 1 ? "like-active" : ""}`} style={{ width: "22px", height: "23px" }} /> */}
            {source?.like !== 1 ? <IconMyLike styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />
                : <IconMyLikeFill styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />}
        </button>

    );
};

export const DisLikeButtonAction = ({ source, sourceType, status, className }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleDisLike = async () => {
        if (source?.id) {
            await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Dislike-Update`, {
                Login_user_type: parseInt(getItem('role'), 10) || demoUser.userRole,
                Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
                Login_device_type: "web",
                source_id: source?.id,
                source_type: sourceType,
                dislike_status: status,
            }, { headers: getItemToken('access_token') || null }).then(response => {
                toast.success(t(response.data.message));
                dispatch(MusicUpdateAction('AUDIO', source?.slug))
            })
                .catch(err => {
                    const errorMessage = err.response?.data?.message || 'An error occurred';
                    toast.error(t(errorMessage));
                });
        }
        else {
            return null
        }
    }
    return (
        <button type="button" className={`playButton bg-transparent toolTipParent  ${source?.id ? '' : 'opacity-75'} ${className}`} onClick={handleDisLike}>
            {/* <FiThumbsDown className={`likeIcon  theme-text-color ${source?.dislike == 1 ? "dislike-active" : ""}`} style={{ width: "22px", height: "23px" }} /> */}
            {source?.dislike !== 1 ? <IconMyDisLike styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />
                : <IconMyDisLikeFill styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />}</button>

    );
};

export const WishListButtonAction = ({ source, sourceType }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleWishlist = async () => {
        await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Wishlist-Update`, {
            Login_user_type: parseInt(getItem('role'), 10) || demoUser.userRole,
            Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
            Login_device_type: "web",
            source_id: source?.id,
            source_type: sourceType,
        }, { headers: getItemToken('access_token') || null }).then(response => {
            toast.success(t(response.data.message));
            dispatch(MusicUpdateAction('AUDIO', source?.slug))
        })
            .catch(err => {
                const errorMessage = err.response?.data?.message || 'An error occurred';
                toast.error(t(errorMessage));
            });
    }
    return (
        <button type="button" className={`bg-transparent  theme-text-color p-0 ${source?.id ? '' : 'opacity-75'}`} onClick={handleWishlist}>
            {source?.wishlist ? <IconMyHeartFill styled={{ width: "16px", height: "16px", className: "musicSideBarIcons" }} /> : <IconMyHeart styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />}
        </button>
    );
};

const wishListAction = async (sourceId, sourceType) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Wishlist-Update`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            source_id: sourceId,
            source_type: sourceType
        }, { headers: getItemToken('access_token') || null });
        if (response.data.status) {
            return response?.data
        } else {
        }
        return response.data.status;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


const demoUser = {
    userRole: 9,
    userId: null
}
const userTranslateLanguage = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/User-Translate-Language`, {
            Login_user_type: parseInt(getItem('role'), 10) || demoUser.userRole,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
        }, { headers: getItemToken('access_token') || null }
        )
        const languageResponse = response?.data;

        return languageResponse;
    }
    catch (error) {

    };
}


export { URLGenerater, apiGenerate, capitalizeFirstLetter, URLDashRemover, demoUser, userTranslateLanguage, wishListAction }