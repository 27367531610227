import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";

import "./LyricsPage.css";
const LyricsPage = ({ currentTime, currentAudio }) => {
  
  const dispatch = useDispatch()

  var lyrics = currentAudio?.lyrics_json?.lyrics !== null && currentAudio?.lyrics_json?.lyrics;
  // console.log(lyrics)
  const lyricsContainerRef = useRef(null);
  const offset = 150;
  const currentLineIndex = lyrics !== null && lyrics?.findIndex((line, index) => {
    const nextLineTime = lyrics[index + 1] ? lyrics[index + 1].time : Number.MAX_VALUE;
    return (
      line.time <= currentTime + offset && currentTime < nextLineTime
    );
  });
  const handleLyrics = (gettime) => {
    // dispatch({
    //   type: "GET_LYRIC_SUCCESS",
    //   currenttime: gettime,
    // });
  };

  useEffect(() => {
    if (lyricsContainerRef.current && currentLineIndex !== -1) {
      const highlightedElement =
        lyricsContainerRef.current.getElementsByClassName("highlighted")[0];

      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [currentLineIndex]);



  return lyrics != null ? (
    <div className="">
      <div className="lyrics-container w-100 h-100" ref={lyricsContainerRef}>
        {lyrics?.map((line, index) => (
          <div
            key={index + 1}
            onClick={() => handleLyrics(line.time)}
            className={`lyrics-line ${index == currentLineIndex ? "highlighted" : ""
              }`}
          >
            {line.line}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="lyrics-container-not-result lyrics-container  rounded-3 w-100 h-100">Lyrics Not Found</div>
  );
};


const mapStateToProps = state => ({
  currentAudio: state.currentMusic.currentAudio,
  currentTime: state.currentMusic.currentTime,
});
export default connect(mapStateToProps)(LyricsPage)