import axios from "axios";
import { apiGenerate, demoUser } from "../../Method/CommonMethod";
import { getItem, getItemToken } from "../../Utils/localStorageUtils";
import { PLAYLIST_SUCCESS } from "../ActionTypes";
import { MusicUpdateAction } from "./MusicUpdateAction";

export const MusicPlayQueueAction = (type, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${apiGenerate(type, 'playapi')}`, {
            Login_user_type: parseInt(getItem('role'), 10) || demoUser.userRole,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
            [apiGenerate(type, 'slug')]: slug,
        }, { headers: getItemToken('access_token') || null })

        const liveResponse = type == "GENRE" ? response?.data?.[apiGenerate(type, 'details')][0] : response?.data;
        if (liveResponse[apiGenerate(type, 'playlistdetails')].length > 0 && liveResponse[apiGenerate(type, 'playlistdetails')][0]?.mp3_url) {
            dispatch({ type: PLAYLIST_SUCCESS, payload: liveResponse?.[apiGenerate(type, 'playlistdetails')] });
            dispatch({ type: "GET_CURRENT_SUCCESS", payload: liveResponse?.[apiGenerate(type, 'playlistdetails')][0] })
            dispatch({ type: "GET_CURRENT_PLAYLIST_OR_ALBUM", payload: type == "GENRE" ? liveResponse : liveResponse?.[apiGenerate(type, 'details')] })
            dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: true })
            dispatch({ type: "GET_CURRENT_INDEX", currentIndex: 0 })
            dispatch({ type: "GET_CURRENT_DURATION", isDuration: liveResponse?.[apiGenerate(type, 'playlistdetails')][0]?.duration })
            dispatch({ type: "MINIMIZE_PLAYER", payload: false })
            await dispatch(MusicUpdateAction('AUDIO', liveResponse?.audios?.[0]?.slug))
        }
        else {
            console.log("data not found")
        }
    }
    catch (error) {
        console.error("Uh...! , An unexpected error occurred");
    };
}
