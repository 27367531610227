import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { userTranslateLanguage } from '../Method/CommonMethod';
import axios from 'axios';
const initI18n = async () => {
  try {
    const [translations, response] = await new Promise((resolve, reject) => {
      let fetchedData = null;
      const fetchData = async () => {
        try {
          const result = await userTranslateLanguage();
          const data = await axios.post(result?.translationFilePath);
          fetchedData = [data?.data, result];
          resolve(fetchedData);
        } catch (error) {
          reject(error);
        }
      };
      fetchData();
    });

    const resources = {
      [response?.Language_Code]: { translation: translations }
    };

    await i18n
      .use(initReactI18next)
      .init({
        resources,
        lng: response?.Language_Code,
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
      });
  } catch (error) {
    console.error("Error initializing i18n:", error);
  }
};


export const initializeI18n = initI18n;
export default i18n;
