import axios from "axios";
import { getItem, getItemToken } from "../../Utils/localStorageUtils";
import { demoUser } from "../../Method/CommonMethod";

export const HeaderAction = () => async (dispatch) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Header-index`, {
            Login_user_type: parseInt(getItem('role'), 10) || demoUser?.userRole,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
        }, { headers: getItemToken('access_token') || null })
        const ListRes = response.data
        dispatch({ type: "GET_HEADERINDEX_ACTION", payload: ListRes });
    }
    catch (error) {
        dispatch({ type: "GET_HEADERINDEX_ACTION_FAILED", payload: error });
    };
}