import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { MusicViewAllAction } from '../../Redux/Actions/MusicViewAllAction';
import { musicWriterPageList } from '../../Config/Api/MusicApi';
import EmptyLoaderStatic from '../../Components/CommonComponents/Loaders/EmptyLoaderStatic';
import PageError from '../NotFound/PageError';
import MusicLayoutViewAll from './MusicLayoutViewAll';
import { getItem, getItemToken } from '../../Utils/localStorageUtils';

const MusicBasedWriterViewAll = ({ settings, viewAllData, isLoading, error }) => {
    const { slug } = useParams();

    // localstrage
    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessToken = getItemToken('access_token');

    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            dispatch(MusicViewAllAction("WRITER", musicWriterPageList, slug));
        }

    }, [settings, userRoleLocal, userIdLocal, location]);
    return isLoading ? <EmptyLoaderStatic /> : error ? <PageError /> : <div className='px-2 px-md-4'> <MusicLayoutViewAll mapData={viewAllData?.[0]?.Song_Writer} source={slug} path='music' headingClass='pb-4 text-center' basedCard /></div>

}
const mapStateToProps = (state) => ({
    viewAllData: state.musicViewAll.viewAllData,
    isLoading: state.musicViewAll.isLoading,
    error: state.musicViewAll.error,
    settings: state.settingsDefault.settings,
});
export default connect(mapStateToProps)(MusicBasedWriterViewAll)