import axios from "axios";

export const CommonThumbnailAction = () => async (dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Thumbnail-Status`)
        const ListRes = response.data.Thumbnail_Status
        dispatch({ type: "GET_THUMBNAIL_ACTION", payload: ListRes });
    }
    catch (error) {
        dispatch({ type: "GET_THUMBNAIL_ACTION_FAILED", payload: error });
    };
}