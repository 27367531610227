import { connect, useDispatch } from 'react-redux';
import { MdOutlineInfo } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import defaultImage from '../../Assets/Images/default-music.png'
import { Link, useParams } from 'react-router-dom';
import { MusicUpdateAction } from '../../Redux/Actions/MusicUpdateAction';
import { MusicCurrentAction } from '../../Redux/Actions/MusicCurrentAction';
import MessageBox from '../CommonComponents/MessageBox';
import PriceLabel from '../CommonComponents/PriceLabel';
import { capitalizeFirstLetter } from '../../Method/CommonMethod';
import { OPEN_PURCHASE_MODAL } from '../../Redux/ActionTypes';
import { IconMyClose } from '../../Assets/Icons/IconComponents/MyIcons';
import LazyLoadImage from '../CommonComponents/LazyLoadImage';
import { useEffect, useState } from 'react';


const PurchaseModal = ({ playlists, upComingTitle }) => {
    const dispatch = useDispatch();
    const { lang } = useParams()

    const formatter = new Intl.NumberFormat('en', {
        notation: 'compact',
        compactDisplay: 'short'
    });

    const playNext = () => {
        const currentIndex = playlists.findIndex(item => item.id == upComingTitle?.id);
        const nextIndex = (currentIndex + 1) % playlists?.length;
        let nextAudio = playlists?.[nextIndex];
        if (nextAudio?.audio_access) {
            if (nextIndex >= 0) {
                dispatch({ type: OPEN_PURCHASE_MODAL, payload: false, upComingTitle: playlists?.[nextIndex + 1] })
                dispatch(MusicCurrentAction(nextIndex, playlists, 0));
                dispatch(MusicUpdateAction('AUDIO', playlists?.[nextIndex]?.slug))
            }
        }
        else {
            dispatch({ type: OPEN_PURCHASE_MODAL, payload: true, upComingTitle: nextAudio })
        }

    };
    const handleClose = () => {
        dispatch({ type: OPEN_PURCHASE_MODAL, payload: false })
    }
    return (
        <div className='playlistModal w-100 h-100 d-flex align-items-center justify-content-center position-fixed'>
            <div className='theme-bg-color playlistWrapper p-4 position-relative'>
                <div onClick={handleClose} className='position-absolute purchaseClose'>
                    <IconMyClose styled={{ className: "likeIcon theme-text-color " }} />
                </div>
                <div className='d-flex align-items-center gap-3 mt-4'>
                    <Link to={`/${lang}/track/${upComingTitle?.slug}`}>
                        <LazyLoadImage key={upComingTitle?.id} src={upComingTitle?.image_url ? upComingTitle?.image_url : upComingTitle?.Thumbnail ? upComingTitle?.Thumbnail : defaultImage} title={upComingTitle?.name?.slice(0, 1)} imageSize="purchaseImage rounded-circle overflow-hidden object-fit-cover" classNameOptional="rounded-circle purchaseImage" />
                    </Link>
                    <div className='d-flex flex-column'>
                        <MessageBox classname='purchaseTitle text-start' text={`${upComingTitle?.title}`} />

                        <div className='audioListName  opacity-75 d-flex align-items-center  flex-wrap m-0'> <MessageBox classname='me-2' text={`${formatter.format(upComingTitle?.Monthly_istener)} Monthly listeners`} />{upComingTitle?.artists?.slice(0, 2).map((name, index) => <Link to={`/${lang}/artist/${name.artist_slug}`} className=' d-block theme-text-color pe-1'> <MessageBox text={name?.name} />{upComingTitle?.artists?.length >= 2 && index + 1 !== 2 ? "," : ""} </Link>)}</div>
                    </div>
                    <PriceLabel item={upComingTitle} />
                </div>
                <h5 className='text-center  purchaseHeading'>This audio is exclusively for  <span className=''>{capitalizeFirstLetter(upComingTitle?.access)}</span></h5>
                <MessageBox classname='text-start mt-3 px-4 d-block opacity-75 purchaseInfo' text=" Purchase a plan to enjoy endless music and exclusive content. " />
                <div className='d-flex align-items-center justify-content-between mt-5 position-relative '>
                    <button className='purchaseBtn btn btn-outline-light py-2 d-flex align-items-center justify-content-center' onClick={playNext}>
                        <p className=' me-2 mb-0'>Skip</p>
                        <div className='infoBox d-inline-block position-relative '>
                            <MdOutlineInfo className='theme-button-text-color  infoIcon' />
                            <p className='position-absolute infoContent p-2 text-start text-white rounded-2 bg-primary'> Click skip to move to the next track in active playlist. </p>
                        </div>
                    </button>
                    <Link to={`/${lang}/plan`} className='purchaseBtn theme-button-bg-color py-2 rounded-3 text-center '><MessageBox text="Subscribe" classname='theme-button-text-color' /></Link>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    createModal: state.toggleState.createModal,
    currentAudio: state.currentMusic.currentAudio,
    playlistMusicId: state.currentMusic.playlistMusicId,
    playlists: state.playlists.playlists,
    currentIndex: state.currentMusic.currentIndex,
    upComingTitle: state.toggleState.upComingTitle,
});
export default connect(mapStateToProps)(PurchaseModal)

