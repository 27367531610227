// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-filled {
    height: 100%;
    border-radius: 25px;
    position: relative;
    width: 0%;
    transition: width 0.1s ease-out;
}

.track-info {
    display: flex;
    justify-content: space-around;
    padding-top: 5px;
}

.progress-filled-circle {
    left: 0;
    transition: left 0.1s ease-out;
}`, "",{"version":3,"sources":["webpack://./src/Components/Player/ProgressBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,OAAO;IACP,8BAA8B;AAClC","sourcesContent":[".progress-filled {\n    height: 100%;\n    border-radius: 25px;\n    position: relative;\n    width: 0%;\n    transition: width 0.1s ease-out;\n}\n\n.track-info {\n    display: flex;\n    justify-content: space-around;\n    padding-top: 5px;\n}\n\n.progress-filled-circle {\n    left: 0;\n    transition: left 0.1s ease-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
