export const MusicCurrentAction = (nextIndex, playlists, currentTime) => async (dispatch, getState) => {
    let getPrevState = getState();
    if (getPrevState.id !== playlists?.[nextIndex]?.id) {
        dispatch({
            type: "GET_CURRENT_SUCCESS",
            payload: playlists?.[nextIndex],
        });
        dispatch({
            type: "GET_PLAYING_SUCCESS",
            isPlaying: true,
        });
        dispatch({
            type: "GET_CURRENT_INDEX",
            currentIndex: nextIndex,
        });
        dispatch({
            type: "GET_LYRIC_SUCCESS",
            currentTimes: currentTime || 0,
        });
    }
    else {
        console.log("same id")
    }
};
