import React, { useState } from "react";
import "../../Style/Popup.css";
import axios from "axios";
import { VscEyeClosed } from "react-icons/vsc";
import { RxEyeOpen } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { connect, useDispatch } from "react-redux";
import google from "../../Assets/Images/Googl.png";
import fb from "../../Assets/Images/fb.png";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LazyLoadImage from "../CommonComponents/LazyLoadImage";
import { AUTH_MODAL_SIGNIN, AUTH_MODAL_SIGNUP } from "../../Redux/ActionTypes";
import { setItem } from "../../Utils/localStorageUtils";

const SignUpPopup = ({ themeMode, authModalSignUp, action, toggleAction }) => {
    const { t } = useTranslation();
    const [passwordTypeCheck, setPasswordTypeCheck] = useState(true)
    const [passwordTypeDoubleCheck, setPasswordTypeDoubleCheck] = useState(true)
    const [phoneNumber, setPhoneNumber] = useState('');
    const [inputField, setInputField] = useState({ email: "", username: "", psetValidationassword: "", mobile: "", ccode: "", confirmPassword: "", check: false });
    const [errField, setErrField] = useState({ emailerr: "", usernameerr: "", passworderr: "", numbererr: "", ccodeerr: "", confirmPassworderr: "", checkerr: "" });

    const navigate = useNavigate();
    const dispatch = useDispatch()


    const handleSwitchSignIn = () => {
        dispatch({ type: AUTH_MODAL_SIGNIN, payload: true })
        dispatch({ type: AUTH_MODAL_SIGNUP, payload: false })
      }

    const handleOnChange = (value, country, e, formattedValue) => {
        setPhoneNumber(value);
        setInputField({
            ...inputField,
            ccode: country?.name + country?.dialCode,
            mobile: value.slice(country.dialCode.length)
        });
    };

    const togglePasswordCheck = () => {
        setPasswordTypeCheck(!passwordTypeCheck)
    }

    const togglePasswordDoubleCheck = () => {
        setPasswordTypeDoubleCheck(!passwordTypeDoubleCheck)
    }

    const signup = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/users/signup`, {
                username: inputField?.username,
                email: inputField?.email,
                password: inputField?.password,
                ccode: inputField?.ccode,
                mobile: inputField?.mobile,
                confirm_password: inputField?.confirmPassword,
            });
            const result = response?.data;
            if (result?.status) {
                let username = result?.User?.username;
                let user_id = result?.User?.id;
                let email = result?.User?.email;
                let role = result?.User?.role;
            setItem("signup_user_id", user_id);
                setItem("signup_email", email);
                // localStorage.setItem("signup_role", role);
                setItem("signup_username", username);
                toast.success(t(result?.message))
                navigate("/plan")
            }
            else {
                toast.error(t(result?.message))
            }
        } catch (error) {
            var errorMessage = error?.response?.data?.message;
            toast.error(t(errorMessage))
        }
    }

    function handleChange(e) {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setInputField((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const validForm = () => {
        let formIsValid = true;
        // debugger;
        if (!inputField?.username) {
            setErrField((prevState) => ({ ...prevState, usernameerr: `${t('Please Enter Username')}*` }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, usernameerr: "" }));
        }
        if (!inputField?.email) {
            setErrField((prevState) => ({ ...prevState, emailerr: `${t('Please Enter Email')}*`, }));
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(inputField.email)) {
            setErrField((prevState) => ({ ...prevState, emailerr: `${t('Please Enter Valid Email ID')}*`, }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, emailerr: "" }));
        }
        if (!inputField?.mobile) {
            setErrField((prevState) => ({ ...prevState, numbererr: `${t('Please Enter Number')}*` }));
            formIsValid = false;
        }
        else if (inputField?.mobile?.length < 6) {
            setErrField((prevState) => ({ ...prevState, numbererr: `${t('Please Enter Minimum 6 Digit')}*` }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, numbererr: "" }));
        }
        if (!inputField?.password) {
            setErrField((prevState) => ({ ...prevState, passworderr: `${t('Please Enter Password')}*` }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, passworderr: "" }));
        }
        if (!inputField?.confirmPassword) {
            setErrField((prevState) => ({ ...prevState, confirmPassworderr: `${t('Please Enter Confirm Password')}*` }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, confirmPassworderr: "" }));
        }
        if (inputField?.confirmPassword !== inputField?.password) {
            setErrField((prevState) => ({ ...prevState, confirmPassworderr: `${t('Password and Confirm Password do not match')}*`, }));
            formIsValid = false;
        }

        if (!inputField?.check) {
            setErrField((prevState) => ({ ...prevState, checkerr: `${t('Please Tick CheckBox')}*`, }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, checkerr: "" }));
        }
        return formIsValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validForm()) {
            signup()
        } else {
            console.log('Form validation failed');
        }
    };

    const closeModal = () => {
        dispatch({ type: AUTH_MODAL_SIGNUP, payload: false })
    }

    return (
        <div className={`modal  packageModel fade signin-popup-container ${authModalSignUp && 'd-block show'}`}>
            <div className="modal-dialog">
                <div class="modal-content theme-bg-color">
                    <div class="modal-header flex-column border-0 pb-0">
                        <LazyLoadImage src={themeMode?.logoUrl} alt="SignInLogo" className="d-block mx-auto signin-logo-image mt-3 mt-sm-0" />
                        <h4 className="signinText theme-text-color  pt-2 mb-0">{t("Sign Up")}</h4>
                        <button type="button" className="closeIconSignin m-0  bgButton theme-text-color  p-1 d-flex align-items-center position-absolute" style={{ backgroundImage: "none" }} onClick={closeModal} > <IoClose style={{ color: "#fff", width: "20px", height: "20px" }} /></button>
                    </div>
                    <div class="modal-body">
                        <div className="signInMenu position-relative" >
                            <form onSubmit={handleSubmit} autoComplete="on">
                                <div className="position-relative pb-4">
                                    <div className="border-bottom">
                                        <input
                                            className=" p-2 signin-input border-0 theme-text-color"
                                            type="text"
                                            id="username"
                                            name="username"
                                            autoComplete="on"
                                            value={inputField?.username}
                                            onChange={handleChange}
                                            placeholder={`${t("Enter your Name")} *`}
                                        />
                                    </div>
                                    {errField?.usernameerr !== "" && (
                                        <span className="d-flex text-danger signin-error py-2 position-absolute">{errField?.usernameerr}</span>
                                    )}
                                </div>
                                <div className="position-relative pb-4">
                                    <div className="border-bottom">
                                        <input
                                            className="p-2 signin-input border-0 theme-text-color"
                                            id="signupemail"
                                            type="email"
                                            name="email"
                                            value={inputField?.email}
                                            placeholder={`${t("Enter your Email")} *`}
                                            autoComplete="on"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errField?.emailerr !== "" && (
                                        <span className="d-flex text-danger signin-error py-2 position-absolute">{errField?.emailerr}</span>
                                    )}
                                </div>
                                <div className="position-relative">
                                    <PhoneInput country={'us'} value={phoneNumber} onChange={handleOnChange} inputStyle={{ width: '100%' }} countryCodeEditable={false} disableCountryCode={false} disableSearchIcon enableSearch={true} placeholder="Enter your phone number" inputClass="bg-transparent border-bottom border-0 py-4 theme-text-color  rounded-0 ownInputFlag" containerClass="mb-4" dropdownClass="theme-bg-color theme-text-color ownInputDropDown" buttonClass="bg-transparent border-0 ownButtonClass" />
                                    {errField?.numbererr !== "" && (
                                        <div className="d-flex text-danger signin-error py-2 position-absolute">{errField?.numbererr}</div>
                                    )}
                                </div>

                                <div className="position-relative pb-4">
                                    <div className="position-relative">
                                        <input
                                            id="signuppassword"
                                            name="password"
                                            value={inputField?.password}
                                            type={passwordTypeCheck ? 'password' : 'text'}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            className="p-2 signin-input border-0 theme-text-color border-bottom"
                                            placeholder={`${t('Enter your Password')} *`}
                                        />
                                        <button onClick={togglePasswordCheck} type="button" className="p-2  border-0 eyeButton">{passwordTypeCheck ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
                                    </div>
                                    {errField?.passworderr !== "" && (
                                        <span className="d-flex text-danger signin-error py-2 position-absolute">{errField?.passworderr}</span>
                                    )}
                                </div>
                                <div className="position-relative pb-4">
                                    <div className="position-relative">
                                        <input
                                            className="p-2 signin-input border-0 theme-text-color border-bottom "
                                            type={passwordTypeDoubleCheck ? 'password' : 'text'}
                                            placeholder={`${t('Enter your confirm Password')} *`}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            autoComplete="off"
                                            value={inputField?.confirmPassword}
                                            onChange={handleChange}
                                        />
                                        <button onClick={togglePasswordDoubleCheck} type="button" className="p-2  border-0 eyeButton">{passwordTypeDoubleCheck ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
                                    </div>
                                    {errField?.confirmPassworderr !== "" && (
                                        <label className="d-flex text-danger signin-error py-2 position-absolute">{errField?.confirmPassworderr}</label>
                                    )}
                                </div>
                                <div className="d-flex align-items-center position-relative gap-3 checkboxHeight">
                                    <input
                                        type="checkbox"
                                        name="check" checked={inputField?.check}
                                        className="d-block"
                                        autoComplete="off"
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="theme-text-color">{t("Yes , I Agree to Terms and Conditions")}</label>
                                    {errField?.checkerr !== "" && (
                                        <label className="d-flex text-danger signin-error py-2 position-absolute">{errField?.checkerr}</label>
                                    )}
                                </div>
                                <div className="d-flex align-items-center justify-content-around flex-wrap-reverse  my-3 ">
                                    <div className="col-sm-2 col-12 p-0 pe-2 d-flex justify-content-sm-between justify-content-center align-items-center gap-2">
                                        <img src={google} alt="google" style={{ width: "39px", height: "40px" }} />
                                        <img src={fb} alt="facebook" style={{ width: "43px", height: "43px" }} />
                                    </div>
                                    <div className="col-5 d-none d-sm-flex  justify-content-center" >
                                        <span className="code "></span>
                                    </div>
                                    <div className="col-sm-5 mb-3 mb-sm-0 col-12  position-relative d-flex justify-content-sm-end justify-content-center">
                                        <button className="signinbutton before d-block text-uppercase px-5 py-1 rounded-2 theme-text-color" type="submit">{t("Sign Up")}</button>
                                    </div>
                                </div>

                                <p className="signinLabelText mb-0 pt-2 theme-text-color">{t("Already have an account")} ?<button type="button" className="border-0 bg-transparent theme-text-color" onClick={handleSwitchSignIn}>{t("Sign In")}</button></p>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    authModalSignUp: state.toggleState.authModalSignUp,
    settings: state.settingsDefault.settings,
    isLoading: state.settingsDefault.isLoading,
    props: ownProps,
    themeMode: state.headerDetails.themeMode,
});
export default connect(mapStateToProps)(SignUpPopup);