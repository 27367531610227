import "./Style/style.css"
import "./Style/musicSwiper.css"
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import MusicListFormat from './MusicListFormat';
import MusicCategory from './MusicCategory';
import MusicAlbum from "./MusicAlbum";
import MusicBasedCategory from "./MusicBasedCategory";
import MusicArtist from "./MusicArtist";
import MusicBasedWriter from "./MusicBasedWriter";
import MusicBasedArtist from "./MusicBasedArtist";
import MusicBasedLanguage from "./MusicBasedLanguage";
import MusicMusician from "./MusicMusician";
import MusicWriter from "./MusicWriter";
import MusicPlaylist from "./MusicPlaylist";
import MusicBasedMusician from "./MusicBasedMusician";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import EmptyLoaderSwiper from "../../Components/CommonComponents/Loaders/EmptyLoaderSwiper";
import MusicStation from "./MusicStation";

const MusicHomePage = ({ musicHomePage, isLoading, error, settings, playlistShow }) => {
  const [containerWidth, setContainerWidth] = useState(820);
  const containerRef = useRef(null);


  const calculateSlidesPerView = () => {
    if (containerWidth < 450) { return 2; }
    else if (containerWidth < 650) { return 3; }
    else if (containerWidth < 992) { return 4; }
    else if (containerWidth < 1440) { return 6; }
    else if (containerWidth < 2000) { return 8; }
    else { return 11; }
  };
  console.log(containerWidth)

  const swiperConfig = {
    slidesPerView: calculateSlidesPerView(),
    spaceBetween: 15,
    navigation: true,
    lazy: true,
    slidesPerGroup: calculateSlidesPerView(),
    modules: [Navigation],
  }

  useEffect(() => {
    const handleResize = () => {
      if (containerRef?.current?.clientWidth) {
        setContainerWidth(containerRef?.current?.clientWidth);
      }
    };
    handleResize();
    calculateSlidesPerView()
  }, [playlistShow, containerRef?.current?.clientWidth]);


  return (
    <div className='pb-5' ref={containerRef}>
      {isLoading ? Array.from({ length: 6 }).map((data, index) => { return <EmptyLoaderSwiper /> })
        : musicHomePage?.map((item, index) => {
          return (
            item?.source == "latest_audios" ? <MusicListFormat item={item} key={item.id} swiperOptions={swiperConfig} /> :
              item?.source == "Audio_Genre" ? <MusicCategory item={item} key={item.id} /> :
                item?.source == "Audio_Albums" ? <MusicAlbum item={item} key={item.id} swiperOptions={swiperConfig} /> :
                  item?.source == "Artists" ? <MusicArtist item={item} key={item.id} swiperOptions={swiperConfig} /> :
                    item?.source == "musician" ? <MusicMusician item={item} key={item.id} swiperOptions={swiperConfig} /> :
                      item?.source == "music_station" ? <MusicStation item={item} key={item.id} swiperOptions={swiperConfig} /> :
                        item?.source == "song_writer" ? <MusicWriter item={item} key={item.id} swiperOptions={swiperConfig} /> :
                          item?.source == "Playlist" ? <MusicPlaylist item={item} key={item.id} swiperOptions={swiperConfig} /> :
                            item?.source == "Audio_based_on_Genre" ? item?.data?.map((item) => <MusicBasedCategory item={item} key={item.id} swiperOptions={swiperConfig} />) :
                              item?.source == "Audio_based_on_Albums" ? item?.data?.map((item) => <MusicBasedArtist item={item} key={item.id} swiperOptions={swiperConfig} />) :
                                item?.source == "Audio_based_on_Song_Writer" ? item?.data?.map((item) => <MusicBasedWriter item={item} key={item.id} swiperOptions={swiperConfig} />) :
                                  item?.source == "Audio_based_on_Musician" ? item?.data?.map((item) => <MusicBasedMusician item={item} key={item.id} swiperOptions={swiperConfig} />) :
                                    item?.source == "Audio_based_on_Language" ? item?.data?.map((item) => <MusicBasedLanguage item={item} key={item.id} swiperOptions={swiperConfig} />) : null
          )
        }
        )}
    </div>
  )
}

const mapStateToProps = state => ({
  musicHomePage: state.homeLists.musicHomePage,
  isLoading: state.homeLists.isLoading,
  error: state.homeLists.error,
  settings: state.musicSettings.settings,
  playlistShow: state.toggleState.playlistShow,
});


export default connect(mapStateToProps)(MusicHomePage)