import React from 'react'
import EmptyLoaderCard from './EmptyLoaderCard'
import { connect } from 'react-redux'

const EmptyLoaderStatic = ({ playlistShow }) => {
    return (
        <>
            <h2 className={`musicViewTitle pb-4`}>
                <div className='square h-100 rounded-1'></div>
            </h2>
            <div className='d-flex align-items-center flex-wrap '>
                {Array.from({ length: 12 }).map((data, index) => { return <div key={"count" + index + "item"} className={`${!playlistShow ? 'col-md-3 col-lg-2' : 'col-md-4 col-lg-3 '}  col-12 col-sm-4 px-2 mb-3 ${Number(Math.round((index + 1) % 4)) == '1' ? 'ps-0' : Number(Math.round((index + 1) % 4)) == '0' ? 'pe-0' : ''}`}><EmptyLoaderCard /></div> })}
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    playlistShow: state.toggleState.playlistShow,
});

export default connect(mapStateToProps)(EmptyLoaderStatic)