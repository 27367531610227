import React, { useEffect } from 'react'
import MessageBox from '../../Components/CommonComponents/MessageBox'
import { connect, useDispatch } from 'react-redux'
import { LanguagePreferenceAction } from '../../Redux/Actions/LanguagePreferenceAction'
import { initializeI18n } from '../../Config/i18n'
import { getItem, getItemToken } from '../../Utils/localStorageUtils'
import axios from 'axios'
import { MusicLanguageAction } from '../../Redux/Actions/MusicLanguageAction'
import { HeaderAction } from '../../Redux/Actions/HeaderAction'

const SettingPage = ({ languages, languageDetailsData, themeMode }) => {

    const dispatch = useDispatch()


    // localstrage
    const userRoleLocal = getItem('role')

    const changeLanguage = async (code) => {
        try {
            await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Choose-Language`, {
                Language_Code: code,
                Login_user_type: parseInt(getItem('role'), 10) || 9,
                Login_user_id: parseInt(getItem('user_id'), 10) || null,
                Login_device_type: "web",
            });
            dispatch(MusicLanguageAction())
            await initializeI18n()
        } catch (error) {
        }
    }
    const changeMode = async (theme) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/theme-mode`, {
                Login_user_type: parseInt(getItem('role'), 10) || 9,
                Login_user_id: parseInt(getItem('user_id'), 10) || null,
                Login_device_type: "web",
                theme_mode: theme
            }, { headers: getItemToken('access_token') || null });
            dispatch(HeaderAction())
            return response.data;
        } catch (error) {
            console.error("Error in themeToggleMethod:", error);
            throw error;
        }
    }


    useEffect(() => {
        dispatch(LanguagePreferenceAction())
    }, [])
    return (
        <div className='py-5 px-2 col-lg-6 col-md-5 mx-auto'>
            <div className='mx-auto'>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <div className="col-8">
                        <MessageBox text="Choose Language" />
                    </div>
                    <div className="col-4">
                        <select value={languageDetailsData?.Language_Code} className="form-select  theme-bg-color-secondary border-0 theme-text-color rounded-5 languageOption" aria-label=".form-select example" onChange={(e) => changeLanguage(e.target.value)}>
                            {languages?.map((item, index) => {
                                return <option value={item.code} key={item.id} className='theme-text-color textLeft' >{item.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                {userRoleLocal ? <div className='d-flex align-items-center justify-content-between w-100 mt-4'>
                    <div className="col-8">
                        <MessageBox text="Theme Mode" />
                    </div>
                    <div className="col-4">
                        <select value={themeMode?.currentMode == "dark" ? 0 : 1} className="form-select  theme-bg-color-secondary border-0 theme-text-color rounded-5  languageOption" onChange={(e) => changeMode(e.target.value)}>
                            <option value={0} key={'dark'} className='theme-text-color textLeft' >Dark</option>
                            <option value={1} key={'light'} className='theme-text-color textLeft' >Light</option>
                        </select>
                    </div>
                </div>
                    : null}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    languages: state.languageList.languages,
    languageDetailsData: state.musicLanguage.languageDetailsData,
    themeMode: state.headerDetails.themeMode
})

export default connect(mapStateToProps)(SettingPage)

