import React from 'react'

const IconMyHome = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="M23.121,9.069,15.536,1.483a5.008,5.008,0,0,0-7.072,0L.879,9.069A2.978,2.978,0,0,0,0,11.19v9.817a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V11.19A2.978,2.978,0,0,0,23.121,9.069ZM15,22.007H9V18.073a3,3,0,0,1,6,0Zm7-1a1,1,0,0,1-1,1H17V18.073a5,5,0,0,0-10,0v3.934H3a1,1,0,0,1-1-1V11.19a1.008,1.008,0,0,1,.293-.707L9.878,2.9a3.008,3.008,0,0,1,4.244,0l7.585,7.586A1.008,1.008,0,0,1,22,11.19Z" /></svg> }
const IconMyPlaylist = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="m23,0h-1c-2.757,0-5,2.243-5,5v9.261c-.952-.787-2.172-1.261-3.5-1.261-3.032,0-5.5,2.467-5.5,5.5s2.468,5.5,5.5,5.5,5.5-2.467,5.5-5.5V5c0-1.654,1.346-3,3-3h1c.553,0,1-.448,1-1s-.447-1-1-1Zm-9.5,22c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5,3.5,1.57,3.5,3.5-1.57,3.5-3.5,3.5ZM0,1C0,.448.447,0,1,0h11c.553,0,1,.448,1,1s-.447,1-1,1H1c-.553,0-1-.448-1-1Zm0,6c0-.552.447-1,1-1h11c.553,0,1,.448,1,1s-.447,1-1,1H1c-.553,0-1-.448-1-1Zm8,6c0,.552-.447,1-1,1H1c-.553,0-1-.448-1-1s.447-1,1-1h6c.553,0,1,.448,1,1Z" /></svg> }
const IconMyAddPlaylist = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="m6,1c0-.553.448-1,1-1h10c.552,0,1,.447,1,1s-.448,1-1,1H7c-.552,0-1-.447-1-1Zm-2,6h16c.552,0,1-.447,1-1s-.448-1-1-1H4c-.552,0-1,.447-1,1s.448,1,1,1Zm15.5,9h-1.5v-1.5c0-.553-.448-1-1-1s-1,.447-1,1v1.5h-1.5c-.552,0-1,.447-1,1s.448,1,1,1h1.5v1.5c0,.553.448,1,1,1s1-.447,1-1v-1.5h1.5c.552,0,1-.447,1-1s-.448-1-1-1Zm4.5,1c0,3.859-3.14,7-7,7s-7-3.141-7-7,3.14-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-13,5h-4c-1.654,0-3-1.346-3-3v-4c0-1.654,1.346-3,3-3h4c.552,0,1-.447,1-1s-.448-1-1-1h-4c-2.757,0-5,2.243-5,5v4c0,2.757,2.243,5,5,5h4c.552,0,1-.447,1-1s-.448-1-1-1Z" /></svg> }
const IconMyAddStation = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="m18 9.064a3.049 3.049 0 0 0 -.9-2.164 3.139 3.139 0 0 0 -4.334 0l-11.866 11.869a3.064 3.064 0 0 0 4.33 4.331l11.87-11.869a3.047 3.047 0 0 0 .9-2.167zm-14.184 12.624a1.087 1.087 0 0 1 -1.5 0 1.062 1.062 0 0 1 0-1.5l7.769-7.77 1.505 1.505zm11.872-11.872-2.688 2.689-1.5-1.505 2.689-2.688a1.063 1.063 0 1 1 1.5 1.5zm-10.825-6.961 1.55-.442.442-1.55a1.191 1.191 0 0 1 2.29 0l.442 1.55 1.55.442a1.191 1.191 0 0 1 0 2.29l-1.55.442-.442 1.55a1.191 1.191 0 0 1 -2.29 0l-.442-1.55-1.55-.442a1.191 1.191 0 0 1 0-2.29zm18.274 14.29-1.55.442-.442 1.55a1.191 1.191 0 0 1 -2.29 0l-.442-1.55-1.55-.442a1.191 1.191 0 0 1 0-2.29l1.55-.442.442-1.55a1.191 1.191 0 0 1 2.29 0l.442 1.55 1.55.442a1.191 1.191 0 0 1 0 2.29zm-5.382-14.645 1.356-.387.389-1.358a1.042 1.042 0 0 1 2 0l.387 1.356 1.356.387a1.042 1.042 0 0 1 0 2l-1.356.387-.387 1.359a1.042 1.042 0 0 1 -2 0l-.387-1.355-1.358-.389a1.042 1.042 0 0 1 0-2z" /></svg> }
const IconMyMic = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}> <path d="M18.757,16a5.214,5.214,0,0,0-3.707,1.535A15.14,15.14,0,0,1,4.272,22H3.122a1.121,1.121,0,0,1-.794-1.914l.693-.693a7.768,7.768,0,0,0,5.1-2.272L12.447,12.8a6.01,6.01,0,0,0,1.292.2Q13.868,13,14,13a6.018,6.018,0,0,0,4.246-1.758l1-1a6.01,6.01,0,0,0,1.751-4.5A5.946,5.946,0,0,0,18.848,1.4a6.179,6.179,0,0,0-8.274.544l-.633.634A6.33,6.33,0,0,0,8.247,8.511L3.879,12.879a7.759,7.759,0,0,0-2.272,5.1l-.693.693A3.121,3.121,0,0,0,3.122,24h1.15a17.13,17.13,0,0,0,12.192-5.05A3.243,3.243,0,0,1,22,21.242V23a1,1,0,0,0,2,0V21.242A5.249,5.249,0,0,0,18.757,16Zm-7.4-12.012.633-.634a4.413,4.413,0,0,1,3.1-1.3,3.815,3.815,0,0,1,2.471.877A3.971,3.971,0,0,1,19,5.826a4.013,4.013,0,0,1-1.167,3l-1,1a4,4,0,0,1-5.9-.266A4.169,4.169,0,0,1,11.355,3.988ZM5.293,14.293l3.836-3.837c.091.13.164.27.267.393a6.119,6.119,0,0,0,1.131,1.038l-3.82,3.82a5.789,5.789,0,0,1-3.018,1.6A5.789,5.789,0,0,1,5.293,14.293Z" /></svg> }
const IconMyGrid = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="M7,0H4A4,4,0,0,0,0,4V7a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V4A4,4,0,0,0,7,0ZM9,7A2,2,0,0,1,7,9H4A2,2,0,0,1,2,7V4A2,2,0,0,1,4,2H7A2,2,0,0,1,9,4Z" /><path d="M20,0H17a4,4,0,0,0-4,4V7a4,4,0,0,0,4,4h3a4,4,0,0,0,4-4V4A4,4,0,0,0,20,0Zm2,7a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V4a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z" /><path d="M7,13H4a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V17A4,4,0,0,0,7,13Zm2,7a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2H7a2,2,0,0,1,2,2Z" /><path d="M20,13H17a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4h3a4,4,0,0,0,4-4V17A4,4,0,0,0,20,13Zm2,7a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z" /></svg> }
const IconMyLike = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="M22.773,7.721A4.994,4.994,0,0,0,19,6H15.011l.336-2.041A3.037,3.037,0,0,0,9.626,2.122L7.712,6H5a5.006,5.006,0,0,0-5,5v5a5.006,5.006,0,0,0,5,5H18.3a5.024,5.024,0,0,0,4.951-4.3l.705-5A5,5,0,0,0,22.773,7.721ZM2,16V11A3,3,0,0,1,5,8H7V19H5A3,3,0,0,1,2,16Zm19.971-4.581-.706,5A3.012,3.012,0,0,1,18.3,19H9V7.734a1,1,0,0,0,.23-.292l2.189-4.435A1.07,1.07,0,0,1,13.141,2.8a1.024,1.024,0,0,1,.233.84l-.528,3.2A1,1,0,0,0,13.833,8H19a3,3,0,0,1,2.971,3.419Z" /></svg> }
const IconMyDisLike = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="M23.951,12.3l-.705-5A5.024,5.024,0,0,0,18.3,3H5A5.006,5.006,0,0,0,0,8v5a5.006,5.006,0,0,0,5,5H7.712l1.914,3.878a3.037,3.037,0,0,0,5.721-1.837L15.011,18H19a5,5,0,0,0,4.951-5.7ZM5,5H7V16H5a3,3,0,0,1-3-3V8A3,3,0,0,1,5,5Zm16.264,9.968A3,3,0,0,1,19,16H13.833a1,1,0,0,0-.987,1.162l.528,3.2a1.024,1.024,0,0,1-.233.84,1.07,1.07,0,0,1-1.722-.212L9.23,16.558A1,1,0,0,0,9,16.266V5h9.3a3.012,3.012,0,0,1,2.97,2.581l.706,5A3,3,0,0,1,21.264,14.968Z" /></svg> }
const IconMyDisLikeFill = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="M23.951,12.3l-.705-5A5.024,5.024,0,0,0,18.3,3H8V18.584l1.626,3.3a3.038,3.038,0,0,0,5.721-1.838L15.011,18H19a5,5,0,0,0,4.951-5.7Z" /><path d="M0,8v5a5.006,5.006,0,0,0,5,5H6V3H5A5.006,5.006,0,0,0,0,8Z" /></svg> }
const IconMyLikeFill = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="M22.773,7.721A4.994,4.994,0,0,0,19,6H15.011l.336-2.041A3.037,3.037,0,0,0,9.626,2.122L8,5.417V21H18.3a5.024,5.024,0,0,0,4.951-4.3l.705-5A4.994,4.994,0,0,0,22.773,7.721Z" /><path d="M0,11v5a5.006,5.006,0,0,0,5,5H6V6H5A5.006,5.006,0,0,0,0,11Z" /></svg> }
const IconMyPlayPrev = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M10.42,20.656a3.77,3.77,0,0,1-2.233-.735l-6.641-4.87a3.784,3.784,0,0,1,0-6.1l6.641-4.87A3.783,3.783,0,0,1,14.2,6.853l3.782-2.774A3.784,3.784,0,0,1,24,7.13v9.74a3.784,3.784,0,0,1-6.021,3.051L14.2,17.147a3.79,3.79,0,0,1-3.777,3.509Zm2.787-6.475a1,1,0,0,1,.592.194l5.363,3.933A1.784,1.784,0,0,0,22,16.87V7.13a1.785,1.785,0,0,0-2.839-1.438L13.8,9.625a1,1,0,0,1-1.592-.806V7.13A1.784,1.784,0,0,0,9.369,5.692l-6.64,4.87a1.783,1.783,0,0,0,0,2.876l6.64,4.87a1.784,1.784,0,0,0,2.838-1.438V15.181a1,1,0,0,1,1-1Z" /></svg> }
const IconMyPrevArrow = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="M10.6,12.71a1,1,0,0,1,0-1.42l4.59-4.58a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L9.19,9.88a3,3,0,0,0,0,4.24l4.59,4.59a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.42Z" /></svg> }
const IconMyNextArrow = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="M15.4,9.88,10.81,5.29a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L14,11.29a1,1,0,0,1,0,1.42L9.4,17.29a1,1,0,0,0,1.41,1.42l4.59-4.59A3,3,0,0,0,15.4,9.88Z" /></svg> }
const IconMySearch = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z" /></svg> }
const IconMyBell = ({ styled }) => { return <svg fill="currentColor" className={styled?.className} viewBox="0 0 24 24" width={styled?.width} height={styled?.height}><path d="m20,8c2.206,0,4-1.794,4-4s-1.794-4-4-4-4,1.794-4,4,1.794,4,4,4Zm0-6c1.103,0,2,.897,2,2s-.897,2-2,2-2-.897-2-2,.897-2,2-2Zm1.868,8.301c-.127-.538-.675-.869-1.202-.745-.538.127-.871.665-.745,1.202l1.057,4.489c.184.658.051,1.348-.365,1.893-.419.547-1.056.86-1.748.86H5.135c-.67,0-1.294-.297-1.712-.815-.417-.517-.573-1.183-.439-1.786l2.094-7.777c.74-3.31,3.642-5.621,7.056-5.621.43,0,.859.038,1.275.114.551.098,1.064-.264,1.163-.806.099-.544-.263-1.064-.806-1.163-.534-.097-1.083-.146-1.633-.146C7.776,0,4.072,2.955,3.136,7.144l-2.094,7.776c-.278,1.244.022,2.527.824,3.521.799.991,1.991,1.56,3.269,1.56h1.966c.465,2.279,2.484,4,4.899,4s4.434-1.721,4.899-4h1.965c1.32,0,2.537-.6,3.337-1.646.801-1.048,1.057-2.377.713-3.605l-1.046-4.448Zm-9.868,11.699c-1.302,0-2.402-.839-2.816-2h5.631c-.414,1.161-1.514,2-2.816,2Z" /></svg> }
const IconMyGear = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" /><path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" /></svg> }
const IconMyLogOut = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M11.476,15a1,1,0,0,0-1,1v3a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H7.476a3,3,0,0,1,3,3V8a1,1,0,0,0,2,0V5a5.006,5.006,0,0,0-5-5H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H7.476a5.006,5.006,0,0,0,5-5V16A1,1,0,0,0,11.476,15Z" /><path d="M22.867,9.879,18.281,5.293a1,1,0,1,0-1.414,1.414l4.262,4.263L6,11a1,1,0,0,0,0,2H6l15.188-.031-4.323,4.324a1,1,0,1,0,1.414,1.414l4.586-4.586A3,3,0,0,0,22.867,9.879Z" /></svg> }
const IconMyLogIn = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="m24,12c0,.552-.448,1-1,1h-14.036l3.243,3.243c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-4.243-4.243c-.78-.78-.78-2.048,0-2.828l4.243-4.243c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-3.243,3.243h14.036c.552,0,1,.448,1,1Zm-1.386,3.068c-.516-.201-1.095.054-1.295.568-1.51,3.866-5.167,6.364-9.318,6.364-5.514,0-10-4.486-10-10S6.486,2,12,2c4.151,0,7.809,2.498,9.318,6.364.201.514.778.768,1.295.568.515-.201.769-.781.568-1.295C21.37,2.998,16.981,0,12,0,5.383,0,0,5.383,0,12s5.383,12,12,12c4.981,0,9.37-2.998,11.182-7.636.201-.515-.053-1.094-.568-1.295Z" /></svg> }
const IconMyLoop = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="m24,14.5c0,4.136-3.364,7.5-7.5,7.5H7.5c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5,7.5-7.5h6.133c-.032-.039-.059-.08-.095-.116L8.401,1.704c-.389-.392-.386-1.025.006-1.414.392-.388,1.026-.386,1.414.006l5.134,5.177c.673.673,1.045,1.571,1.045,2.527s-.372,1.854-1.048,2.53l-5.131,5.174c-.195.197-.453.296-.71.296-.254,0-.509-.097-.704-.29-.392-.389-.395-1.022-.006-1.414l5.134-5.177c.037-.037.065-.079.098-.119h-6.133c-3.033,0-5.5,2.467-5.5,5.5s2.467,5.5,5.5,5.5h9c3.033,0,5.5-2.467,5.5-5.5,0-2.227-1.327-4.22-3.382-5.078-.509-.213-.75-.799-.537-1.309.214-.509.797-.749,1.309-.537,2.801,1.17,4.61,3.888,4.61,6.923Z" /></svg> }
const IconMyVolumn = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M20.807,4.29a1,1,0,0,0-1.415,1.415,8.913,8.913,0,0,1,0,12.59,1,1,0,0,0,1.415,1.415A10.916,10.916,0,0,0,20.807,4.29Z" /><path d="M18.1,7.291A1,1,0,0,0,16.68,8.706a4.662,4.662,0,0,1,0,6.588A1,1,0,0,0,18.1,16.709,6.666,6.666,0,0,0,18.1,7.291Z" /><path d="M13.82.2A12.054,12.054,0,0,0,6.266,5H5a5.008,5.008,0,0,0-5,5v4a5.008,5.008,0,0,0,5,5H6.266A12.059,12.059,0,0,0,13.82,23.8a.917.917,0,0,0,.181.017,1,1,0,0,0,1-1V1.186A1,1,0,0,0,13.82.2ZM13,21.535a10.083,10.083,0,0,1-5.371-4.08A1,1,0,0,0,6.792,17H5a3,3,0,0,1-3-3V10A3,3,0,0,1,5,7h1.8a1,1,0,0,0,.837-.453A10.079,10.079,0,0,1,13,2.465Z" /></svg> }
const IconMyMini = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M7,0A1,1,0,0,0,6,1V3A3,3,0,0,1,3,6H1A1,1,0,0,0,1,8H3A5.006,5.006,0,0,0,8,3V1A1,1,0,0,0,7,0Z" /><path d="M23,16H21a5.006,5.006,0,0,0-5,5v2a1,1,0,0,0,2,0V21a3,3,0,0,1,3-3h2a1,1,0,0,0,0-2Z" /><path d="M21,8h2a1,1,0,0,0,0-2H21a3,3,0,0,1-3-3V1a1,1,0,0,0-2,0V3A5.006,5.006,0,0,0,21,8Z" /><path d="M3,16H1a1,1,0,0,0,0,2H3a3,3,0,0,1,3,3v2a1,1,0,0,0,2,0V21A5.006,5.006,0,0,0,3,16Z" /></svg> }
const IconMyPlay = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M20.492,7.969,10.954.975A5,5,0,0,0,3,5.005V19a4.994,4.994,0,0,0,7.954,4.03l9.538-6.994a5,5,0,0,0,0-8.062Z" /></svg> }
const IconMyPause = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M6.5,0A3.5,3.5,0,0,0,3,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,6.5,0Z" /><path d="M17.5,0A3.5,3.5,0,0,0,14,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,17.5,0Z" /></svg> }
const IconMyPlayNextFill = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M3.787,20.656A3.789,3.789,0,0,1,0,16.87V7.13A3.783,3.783,0,0,1,6.021,4.079L9.8,6.853a3.783,3.783,0,0,1,6.01-2.774l6.641,4.87a3.784,3.784,0,0,1,0,6.1l-6.641,4.87A3.783,3.783,0,0,1,9.8,17.147L6.021,19.921A3.775,3.775,0,0,1,3.787,20.656Z" /></svg> }
const IconMyPlayPrevFill = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M10.42,20.656a3.77,3.77,0,0,1-2.233-.735l-6.641-4.87a3.784,3.784,0,0,1,0-6.1l6.641-4.87A3.783,3.783,0,0,1,14.2,6.853l3.782-2.774A3.784,3.784,0,0,1,24,7.13v9.74a3.784,3.784,0,0,1-6.021,3.051L14.2,17.147a3.79,3.79,0,0,1-3.777,3.509Z" /></svg> }
const IconMyHeart = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z" /></svg> }
const IconMyHeartFill = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z" /></svg> }
const IconMyVolumnMute = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="m13.644.418c-.228-.19-.529-.268-.821-.215-3.001.555-5.754,2.302-7.554,4.794h-.271C2.241,4.998-.002,7.241-.002,9.998v4.005C-.002,16.76,2.241,19.003,4.998,19.003h.271c1.802,2.495,4.555,4.243,7.554,4.794.06.011.121.017.181.017.232,0,.459-.081.64-.231.228-.19.36-.472.36-.769V1.187c0-.297-.131-.579-.36-.769Zm-1.64,21.117c-2.03-.646-3.851-1.954-5.113-3.703l-.299-.415c-.188-.26-.489-.415-.811-.415h-.782c-1.654,0-3-1.346-3-3v-4.005c0-1.654,1.346-3,3-3h.782c.321,0,.623-.154.811-.415l.299-.415c1.261-1.747,3.083-3.054,5.114-3.702v19.068Zm11.729-7.242c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2.293-2.293-2.293,2.293c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l2.293-2.293-2.293-2.293c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l2.293,2.293,2.293-2.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-2.293,2.293,2.293,2.293Z" /></svg> }
const IconMyEarPhone = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M21,12.424V11A9,9,0,0,0,3,11v1.424A5,5,0,0,0,5,22a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2V11a7,7,0,0,1,14,0v1a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2,5,5,0,0,0,2-9.576ZM5,20H5a3,3,0,0,1,0-6Zm14,0V14a3,3,0,0,1,0,6Z" /></svg> }
const IconMyWishList = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M17,12c0,.553-.448,1-1,1h-3v3c0,.553-.448,1-1,1s-1-.447-1-1v-3h-3c-.552,0-1-.447-1-1s.448-1,1-1h3v-3c0-.553,.448-1,1-1s1,.447,1,1v3h3c.552,0,1,.447,1,1Zm7-7v14c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5V5C0,2.243,2.243,0,5,0h14c2.757,0,5,2.243,5,5Zm-2,0c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V5Z" /></svg> }
const IconMyWishListFill = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm-3,13h-3v3c0,.553-.448,1-1,1s-1-.447-1-1v-3h-3c-.552,0-1-.447-1-1s.448-1,1-1h3v-3c0-.553,.448-1,1-1s1,.447,1,1v3h3c.552,0,1,.447,1,1s-.448,1-1,1Z" /></svg> }
const IconMyShuffle = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" > <path d="M23.421,16.583,20.13,13.292a1,1,0,1,0-1.413,1.414L21.007,17A9.332,9.332,0,0,1,14.321,14.2a.982.982,0,0,0-1.408.08L12.9,14.3a1,1,0,0,0,.075,1.382A11.177,11.177,0,0,0,21.01,19l-2.293,2.293A1,1,0,1,0,20.13,22.7l3.291-3.291A2,2,0,0,0,23.421,16.583Z" /><path d="M21.007,7l-2.29,2.29a.892.892,0,0,0-.054.082.992.992,0,0,0,1.467,1.332L21.836,9l1.586-1.585a2,2,0,0,0,.457-2.1,1.969,1.969,0,0,0-.458-.728L20.13,1.3a1,1,0,1,0-1.413,1.413L21.01,5.005c-4.933.012-7.637,2.674-10.089,5.474C8.669,7.937,6,5.4,1.487,5.046L1.006,5a1,1,0,0,0-1,1,1.02,1.02,0,0,0,1,1c.072,0,.287.033.287.033C5.189,7.328,7.425,9.522,9.6,12c-2.162,2.466-4.383,4.7-8.247,4.96l-.4.021a.994.994,0,1,0,.124,1.985c.156-.007.41-.013.535-.023,5.02-.387,7.743-3.6,10.171-6.409C14.235,9.7,16.551,7.018,21.007,7Z" /></svg> }
const IconMyClose = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" /></svg> }
const IconMyUser = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z" /><path d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z" /></svg> }
const IconMyList = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z" /><path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><circle cx="2" cy="5" r="2" /><circle cx="2" cy="12" r="2" /><circle cx="2" cy="19" r="2" /></svg> }
const IconMyCamera = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M19,4h-.508L16.308,1.168A3.023,3.023,0,0,0,13.932,0H10.068A3.023,3.023,0,0,0,7.692,1.168L5.508,4H5A5.006,5.006,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,19,4ZM9.276,2.39A1.006,1.006,0,0,1,10.068,2h3.864a1.008,1.008,0,0,1,.792.39L15.966,4H8.034ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V9A3,3,0,0,1,5,6H19a3,3,0,0,1,3,3Z" /><path d="M12,8a6,6,0,1,0,6,6A6.006,6.006,0,0,0,12,8Zm0,10a4,4,0,1,1,4-4A4,4,0,0,1,12,18Z" /></svg> }
const IconMyUpload = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M17.974,7.146c-.331-.066-.602-.273-.742-.569-1.55-3.271-5.143-5.1-8.734-4.438-3.272,.6-5.837,3.212-6.384,6.501-.162,.971-.15,1.943,.033,2.89,.06,.309-.073,.653-.346,.901-1.145,1.041-1.801,2.524-1.801,4.07,0,3.032,2.467,5.5,5.5,5.5h11c4.136,0,7.5-3.364,7.5-7.5,0-3.565-2.534-6.658-6.026-7.354Zm-1.474,12.854H5.5c-1.93,0-3.5-1.57-3.5-3.5,0-.983,.418-1.928,1.146-2.59,.786-.715,1.155-1.773,.963-2.763-.138-.712-.146-1.445-.024-2.181,.403-2.422,2.365-4.421,4.771-4.862,.385-.07,.768-.104,1.146-.104,2.312,0,4.405,1.289,5.422,3.434,.413,.872,1.2,1.482,2.158,1.673,2.56,.511,4.417,2.779,4.417,5.394,0,3.032-2.468,5.5-5.5,5.5Zm-1.379-7.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1.707-1.707v5c0,.553-.448,1-1,1s-1-.447-1-1v-5l-1.707,1.707c-.391,.391-1.023,.391-1.414,0s-.391-1.023,0-1.414l2.707-2.707c.386-.386,.893-.58,1.4-.583l.014-.003,.014,.003c.508,.003,1.014,.197,1.4,.583l2.707,2.707Z" /></svg> }
const IconMyEdit = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="m19,0H5C2.243,0,0,2.243,0,5v14c0,2.757,2.243,5,5,5h14c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm3,19c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V5c0-1.654,1.346-3,3-3h14c1.654,0,3,1.346,3,3v14ZM13.879,6.379l-6.707,6.707c-.755.755-1.172,1.76-1.172,2.828v1.586c0,.553.448,1,1,1h1.586c1.068,0,2.073-.416,2.828-1.172l6.707-6.707c1.17-1.17,1.17-3.072,0-4.242-1.134-1.133-3.11-1.133-4.243,0Zm-3.879,9.535c-.373.372-.888.586-1.414.586h-.586v-.586c0-.534.208-1.036.586-1.414l4.25-4.25,1.414,1.414-4.25,4.25Zm6.707-6.707l-1.043,1.043-1.414-1.414,1.043-1.043c.377-.379,1.036-.379,1.414,0,.39.39.39,1.024,0,1.414Z" /></svg> }
const IconMySave = ({ styled }) => { return <svg fill="currentColor" style={styled} className={styled?.className} viewBox="0 0 24 24" ><path d="M20.137,24a2.8,2.8,0,0,1-1.987-.835L12,17.051,5.85,23.169a2.8,2.8,0,0,1-3.095.609A2.8,2.8,0,0,1,1,21.154V5A5,5,0,0,1,6,0H18a5,5,0,0,1,5,5V21.154a2.8,2.8,0,0,1-1.751,2.624A2.867,2.867,0,0,1,20.137,24ZM6,2A3,3,0,0,0,3,5V21.154a.843.843,0,0,0,1.437.6h0L11.3,14.933a1,1,0,0,1,1.41,0l6.855,6.819a.843.843,0,0,0,1.437-.6V5a3,3,0,0,0-3-3Z" /></svg> }
export { IconMySave, IconMyEdit, IconMyUpload, IconMyCamera, IconMyList, IconMyUser, IconMyShuffle, IconMyClose, IconMyWishListFill, IconMyEarPhone, IconMyVolumnMute, IconMyHeartFill, IconMyHeart, IconMyPlayPrevFill, IconMyPlayNextFill, IconMyPlay, IconMyPause, IconMyHome, IconMyPlaylist, IconMyAddPlaylist, IconMyAddStation, IconMyGrid, IconMyMic, IconMyLike, IconMyDisLike, IconMyDisLikeFill, IconMyLikeFill, IconMyPlayPrev, IconMySearch, IconMyNextArrow, IconMyPrevArrow, IconMyBell, IconMyGear, IconMyLogOut, IconMyLogIn, IconMyLoop, IconMyMini, IconMyVolumn, IconMyWishList }