import { musicSettings, musicSettings_FAILED } from "../ActionTypes";

export const MusicSettingsReducer = (state = { getMusicData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case musicSettings:
            return {
                ...state,
                getMusicData: action.payload,
                isLoading: false
            };
        case musicSettings_FAILED:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        default:
            return state;
    }
};