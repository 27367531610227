export const MusicViewAllReducer = (state = { viewAllData: [], error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_MUSIC_VIEWALLPAGE_ACTION":
            return {
                ...state,
                viewAllData: action.payload,
                isLoading: false
            };
        case "GET_MUSIC_VIEWALLPAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case "CLEAR_MUSIC_VIEWALLPAGE":
            return {
                ...state,
                viewAllData: [],
            };
        default:
            return state;
    }
};