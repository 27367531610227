export const MusicCurrentReducer = (
    state = { currentAudio: [], currentIndex: 0, currentToggle: false, currentPlaying: true, currentRepeat: 'disabled', currentTrackId: null, currentTime: null, currentDuration: 0, currentloadings: true, currentPage: "audio", currentUserInteract: null, playlistMusicId: null, error: null, actionType: null }, action) => {
    switch (action.type) {
        case "GET_CURRENT_SUCCESS":
            return {
                ...state,
                currentloadings: false,
                currentAudio: action.payload,
                currentPage: "audio",
            };
        case "GET_CURRENT_PAGE":
            return {
                ...state,
                currentPage: action.inPage,
            };
        case "GET_TOGGLE_SUCCESS": {
            return {
                ...state,
                currentToggle: action.toggle,
            };
        }

        case "GET_PLAYING_SUCCESS": {
            return {
                ...state,
                currentPlaying: action.isPlaying,
            };
        }
        case "GET_REPEAT_SUCCESS": {
            return {
                ...state,
                currentRepeat: action.isRepeat,
            };
        }
        case "GET_LYRIC_SUCCESS": {
            return {
                ...state,
                currentTime: action.currentTimes,
            };
        }
        case "GET_CURRENT_TIME_INDEX":
            return {
                ...state,
                currentTime: action.currentTime,
            };
        case "GET_CURRENT_INDEX":
            return {
                ...state,
                currentIndex: action.currentIndex,
            };

        case "GET_INTERACT_DATA": {
            return {
                ...state,
                currentUserInteract: action.payload
            }
        }
        case "GET_CURRENT_DURATION":
            return {
                ...state,
                currentDuration: action.isDuration,
            };
        case "GET_PLAYLIST_STORE_ID":
            return {
                ...state,
                playlistMusicId: action.payload,
            };
        case "GET_CURRENT_PLAYLIST_OR_ALBUM":
            return {
                ...state,
                playlistData: action.payload,
            };
        case "GET_CURRENT_FAILED":
            return {
                currentToggle: false,
                currentloadings: false,
                currentPlaying: false,
                currenterror: action.payload,
                error: action.payload
            };
        case "GET_ACTION_TYPE":
            return {
                ...state,
                actionType: action.payload,
            };

        default:
            return state;
    }
};