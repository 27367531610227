import axios from "axios";
import { apiGenerate, demoUser } from "../../Method/CommonMethod";
import { getItem, getItemToken } from "../../Utils/localStorageUtils";

export const MusicDetailsAction = (type, slug) => async (dispatch) => {
    try {
        const response = await axios.post(`${apiGenerate(type, 'api')}`, {
            Login_user_type: parseInt(getItem('role'), 10) || demoUser?.userRole,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
            [apiGenerate(type, 'slug')]: slug,
        }, { headers: getItemToken('access_token') || null })
        const liveResponse = response?.data;
        dispatch({
            type: "GET_SONG_DETAILS_ACTION",
            payload: type == "GENRE" ? liveResponse?.[apiGenerate(type, 'details')][0] : liveResponse?.[apiGenerate(type, 'details')],
            TopArtist: liveResponse?.[apiGenerate(type, 'artists')],
            otherAudios: liveResponse?.other_audios ? liveResponse?.other_audios : liveResponse?.data ? liveResponse?.data : liveResponse?.artist_audios ? liveResponse?.artist_audios : liveResponse?.album_audios ? liveResponse?.album_audios : liveResponse?.AudioGenre[0]?.category__audios,
            otherPlaylist: liveResponse?.other_playlist ? liveResponse?.other_playlist : liveResponse?.TopAudioAlbum ? liveResponse?.TopAudioAlbum : liveResponse?.other_music_station ? liveResponse?.other_music_station : liveResponse?.Other_AudioGenre
        });
    }
    catch (error) {
        dispatch({ type: "GET_SONG_DETAILS_ACTION_FAILED", payload: error });
    };
}