import "./MusicSideBar.css"
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { AUTH_MODAL_SIGNIN, CLOSE_PLAYER, LAYOUT_SWITCHER, OPEN_PLAYLIST, OPEN_PLAYLIST_MODAL, OPEN_STATION_MODAL } from "../../../Redux/ActionTypes";
import { getItemToken, getItem, } from "../../../Utils/localStorageUtils";
import MessageBox from "../../CommonComponents/MessageBox";
import { IconMyHome, IconMyPlaylist, IconMyAddPlaylist, IconMyAddStation, IconMyGrid, IconMyLogOut, IconMyLogIn, IconMyList } from "../../../Assets/Icons/IconComponents/MyIcons";
import LazyLoadImage from "../../CommonComponents/LazyLoadImage";
import MusicLogo from "../../../Assets/Images/musiclogo.png"
const MusicSideBar = ({ getMusicData, playlistShow, languageDetailsData, minimizePlayer, layoutSwitcher, className, closeSideBar }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()

  const userRoleLocal = getItem('role')
  const userIdLocal = getItem('user_id')
  const userAccessToken = getItemToken('access_token');
  const accessOnly = getItem('access_token')

  const goBack = () => {
    navigate(-1);
  };
  const goForward = () => {
    navigate(1);
  };

  const handlePlaylistModal = () => {
    dispatch({ type: OPEN_PLAYLIST_MODAL, payload: true })
    closeSideBar()
  };

  const handleShowPlaylist = () => {
    dispatch({ type: OPEN_PLAYLIST, payload: !playlistShow })
    closeSideBar()
  }

  const handleShowMusicStation = () => {
    dispatch({ type: OPEN_STATION_MODAL, payload: true })
    closeSideBar()
  }

  const handleHomepage = () => {
    dispatch({ type: CLOSE_PLAYER, payload: "home" })
    navigate(`/${languageDetailsData?.Language_Code}/`);
    closeSideBar()
  }

  const handleSettings = () => {
    navigate(`/${languageDetailsData?.Language_Code}/user/settings`);
  }

  const handleLayout = (value) => {
    dispatch({ type: LAYOUT_SWITCHER, payload: value })
    closeSideBar()
  }

  const handleLoginPopup = () => {
    dispatch({ type: AUTH_MODAL_SIGNIN, payload: true })
    closeSideBar()
  }

  const handleLogOut = () => {
    localStorage.clear()
    navigate(`/`);
    window.location.reload()
  }

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("music") && currentPath.includes("track")) {
      dispatch({ type: CLOSE_PLAYER, payload: "player" });
    } else if (currentPath.includes("music")) {
      dispatch({ type: CLOSE_PLAYER, payload: "home" });
    }
  }, [location]);


  return (
    <div className={`musicSideBar px-2 py-4 py-md-2  ${getMusicData?.enable_floating && !minimizePlayer && 'activeFloat'} slide-left ${className}`}>
      <div className='py-lg-1 py-2 w-100 h-100 d-flex justify-content-between  flex-column px-2  rounded-3 '>
        <LazyLoadImage key={'logo'} src={MusicLogo} alt={'logo'} title={'M'} imageSize="rounded-2  overflow-hidden musicLogoImage object-fit-contain " classNameOptional="rounded-circle musicLogoImageHeight  align-items-center  justify-content-center mx-auto d-none d-md-flex " />
        <div className='d-flex align-items-center flex-column'>
          <button type="button" onClick={handleHomepage} className='music-link   d-flex  justify-content-lg-center bg-transparent theme-text-color'><IconMyHome styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /><MessageBox classname='sideBarText d-none' text="Home" /></button>
          <button type="button" onClick={handleShowPlaylist} className='music-link   d-flex  justify-content-lg-center bg-transparent theme-text-color'><IconMyPlaylist styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /><MessageBox classname='sideBarText d-none' text="Playlist" /></button>
          <button type="button" onClick={handlePlaylistModal} className='music-link  d-flex  justify-content-lg-center bg-transparent theme-text-color' ><IconMyAddPlaylist styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /><MessageBox classname='sideBarText d-none' text="Move playlist" /></button>
          <button type="button" onClick={handleShowMusicStation} className='music-link   d-flex  justify-content-lg-center bg-transparent theme-text-color'><IconMyAddStation styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /><MessageBox classname='sideBarText d-none' text="Make station" /></button>
          <div class="dropdown position-relative music-link">
            <a class="bg-transparent border-0  p-0 dropdown-toggle " href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"><IconMyGrid styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /> <MessageBox classname='sideBarText d-none' text="Layout" />  </a>
            <ul class="dropdown-menu theme-bg-color" aria-labelledby="dropdownMenuLink">
              <li><div onClick={() => handleLayout("grid")} className={`p-2 d-flex gap-2 align-items-center w-100 ${layoutSwitcher == "grid" && "theme-bg-color-secondary"}`}> <IconMyGrid styled={{ width: "12px", height: "12px", className: " theme-text-color" }} /><span className='theme-text-color'>Grid</span> </div></li>
              <li><div onClick={() => handleLayout("list")} className={`p-2 d-flex gap-2 align-items-center w-100 ${layoutSwitcher == "list" && "theme-bg-color-secondary"}`}><IconMyList styled={{ width: "12px", height: "12px", className: "theme-text-color" }} /><span className='theme-text-color'>List</span></div></li>
            </ul>
          </div>
        </div>

        {userRoleLocal ?
          <button onClick={() => handleLogOut()} className={`bg-transparent theme-text-color`}> <IconMyLogOut styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} /></button>
          :
          <button type="button" onClick={handleLoginPopup} className={`bg-transparent theme-text-color`}><IconMyLogIn styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} /></button>
        }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  getMusicData: state.musicSettings.getMusicData,
  playlistShow: state.toggleState.playlistShow,
  stationModal: state.toggleState.stationModal,
  minimizePlayer: state.toggleState.minimizePlayer,
  layoutSwitcher: state.toggleState.layoutSwitcher,
  languageDetailsData: state.musicLanguage.languageDetailsData,
  profileDetails: state.profileDetails.profileDetails,
});
export default connect(mapStateToProps)(MusicSideBar)