export const CommonThumbnailReducer = (state = { thumbnail: [], thumbnailTitle: null, thumbnailAge: null, thumbnailRating: null, thumbnailPublishedYear: null, thumbnailPlayButtonPath: null, thumbnailPlayButton: null, thumbnailTrailer: null, thumbnailReelsVideos: null, thumbnailFreeORCostLabel: null, thumbnailFeatured: null, thumbnailCategory: null, thumbnailDuration: null, thumbnailPublishedOn: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_THUMBNAIL_ACTION":
            return {
                ...state,
                thumbnail: action.payload,
                thumbnailTitle: action.payload[0].title,
                thumbnailAge: action.payload[0].age,
                thumbnailRating: action.payload[0].rating,
                thumbnailPublishedYear: action.payload[0].published_year,
                thumbnailPublishedOn: action.payload[0].published_on,
                thumbnailDuration: action.payload[0].duration,
                thumbnailCategory: action.payload[0].category,
                thumbnailFeatured: action.payload[0].featured,
                thumbnailFreeORCostLabel: action.payload[0].free_or_cost_label,
                thumbnailReelsVideos: action.payload[0].reels_videos,
                thumbnailTrailer: action.payload[0].trailer,
                thumbnailPlayButton: action.payload[0].play_button,
                thumbnailPlayButtonPath: action.payload[0].play_button_path,
                isLoading: false
            };
        case "GET_THUMBNAIL_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
}