import React from 'react'
import { useMyContext } from '../../../Context/CommonProvider';
import { connect } from 'react-redux';
import { IconMyPause, IconMyPlay } from '../../../Assets/Icons/IconComponents/MyIcons';
const PlayButton = ({ currentAudio, currentPlaying, type, store, className, playlistData, actionType }) => {
    const { handlePlay } = useMyContext();
    return (
        <button onClick={() => handlePlay(type, store?.slug ? store?.slug : store?.artist_slug, store?.id)} className={`  ${className ? className : ' position-absolute theme-button-bg-color musicPlayButton'}  mx-auto my-0 rounded-circle d-flex bg-transparent align-items-center justify-content-center ${currentAudio?.id == store?.id || playlistData?.id == store?.id && type == actionType ? 'visible' : ""} `}>
            {(!currentPlaying || (type !== 'ARTIST' && type !== 'ALBUM' && type !== "PLAYLIST" && type !== "STATION" && type !== "GENRE"? currentAudio?.id !== store?.id : playlistData?.id !== store?.id || type !== actionType))
                ? <IconMyPlay styled={{ width: "20px", height: "20px", className: "triangle" }} />
                : <IconMyPause styled={{ width: "20px", height: "20px", className: "doublecode" }} />}
        </button>
    )
}

const mapStateToProps = state => ({
    currentAudio: state.currentMusic.currentAudio,
    currentPlaying: state.currentMusic.currentPlaying,
    actionType: state.currentMusic.actionType,
    playlistData: state.currentMusic.playlistData,
})

export default connect(mapStateToProps)(PlayButton)