export const ProfileDetailsReducer = (state = { profileDetails: null, error: null, isLoading: true }, action) => {
    switch (action.type) {
        case "GET_PROFILE_ACTION":
            return {
                ...state,
                profileDetails: action.payload,
                isLoading: false
            };
        case "GET_PROFILE_ACTION_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };

        default:
            return state;
    }
}