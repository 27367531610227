import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useMyContext } from '../../Context/CommonProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { getItem, getItemToken } from '../../Utils/localStorageUtils';
import PageLoader from '../../Components/CommonComponents/Loaders/PageLoader';
import MessageBox from '../../Components/CommonComponents/MessageBox';
import PaginationViewAll from '../ViewAllPage/PaginationViewAll';
import { connect } from 'react-redux';
import { musicSearch } from '../../Config/Api/MusicApi';
import MusicSearchCard from '../../Components/CommonComponents/Cards/MusicSearchCard';


const SearchPage = ({ settings, playlistShow, languageDetailsData }) => {
    const [searchData, setSearchData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [containerWidth, setContainerWidth] = useState(820);
    const containerRef = useRef(null);
    const navigate = useNavigate()

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const { searchValue, setSearchValue } = useMyContext()
    const { lang } = useParams()

    const fetchData = async () => {
        if (searchValue?.length > 0) {
            const response = await axios.post(`${musicSearch}`, {
                Login_user_type: parseInt(getItem('role'), 10) || 9,
                Login_device_type: "web",
                Login_user_id: parseInt(getItem('user_id'), 10) || null,
                search_value: searchValue?.trim()
            }, { headers: getItemToken('access_token') || null })
            setSearchData(response?.data?.data)
            setLoading(false)
        }
    }


    const calculateSlidesPerView = () => {
        if (containerWidth < 450) { return 2; }
        else if (containerWidth < 650) { return 3; }
        else if (containerWidth < 992) { return 3; }
        else if (containerWidth < 1440) { return 2; }
        else if (containerWidth < 2000) { return 8; }
        else { return 11; }
    };


    useEffect(() => {
        const fetchDataAsync = async () => {
            await fetchData();
            if (searchValue?.length === 0) {
                navigate(`/${languageDetailsData?.Language_Code}`);
            }
        };

        fetchDataAsync();
    }, [searchValue, languageDetailsData?.Language_Code]);



    useEffect(() => {
        const handleResize = () => {
            if (containerRef?.current?.clientWidth) {
                setContainerWidth(containerRef?.current?.clientWidth);
            }
        };
        handleResize();
        calculateSlidesPerView()

    }, [playlistShow, containerRef?.current?.clientWidth]);


    const sourceTypeMap = {
        "Artists": "ARTIST",
        "Audios_Albums": "ALBUM",
        "audio": "AUDIO",
        "Audios_Genre": "GENRE"
    };
    const pathTypeMap = {
        "Artists": "artist",
        "Audios_Albums": "album",
        "audio": "track",
        "Audios_Genre": "audio-genre"
    };

    return (
        <div className="px-lg-3  px-2 paddingTop w-100" ref={containerRef} >
            {loading ? <PageLoader /> : searchData && searchData.length > 0 ?
                (<div className="d-flex align-items-center flex-wrap pt-5 " >
                    {searchData?.slice((currentPage - 1) * settings?.videos_per_page, currentPage * settings?.videos_per_page)
                        .map((data, index) => (
                            <div className={`col-xl-${calculateSlidesPerView()} p-2 col-lg-4 col-3`}>
                                <MusicSearchCard store={data} type={sourceTypeMap[data?.source || data?.source_type]} path={pathTypeMap[data?.source || data?.source_type]} />
                            </div>))}
                </div>)
                :
                <MessageBox text="No Data found !!!" classname='text-center  d-block py-2 heading' />
            }
            <PaginationViewAll data={searchData} handlePage={handlePageChange} currentPage={currentPage} visibleLength={settings?.videos_per_page} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    settings: state.settingsDefault.settings,
    playlistShow: state.toggleState.playlistShow,
    languageDetailsData: state.musicLanguage.languageDetailsData,
});
export default connect(mapStateToProps)(SearchPage)