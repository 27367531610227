export const LanguagePreferenceReducer = (state = { languages: [], error: null, isLoading: false }, action) => {
    switch (action.type) {
        case "GET_DISPLAYTRANSLATELANGUAGE_REQUEST":
            return {
                ...state,
                isLoading: true,
            };
        case "GET_DISPLAYTRANSLATELANGUAGE": return {
            ...state,
            languages: action.payload,
            isLoading: false,
        }
        case "GET_DISPLAYTRANSLATELANGUAGE_FAILED":
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}