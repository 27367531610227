import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LazyLoadImage from '../LazyLoadImage';
import MessageBox from '../MessageBox';
import PlayButton from '../Button/PlayButton';

const MusicBasedWriterCard = ({ store, languageDetailsData }) => {
    return (
        <div className='p-2 rounded-2 w-100 h-100 theme-border-secondary position-relative '>
            <LazyLoadImage key={store?.title} src={store?.image_url} alt={store?.title} title={store?.title} imageSize="rounded-2  overflow-hidden musicImage object-fit-cover" classNameOptional="rounded-2 musicImageHeight" />
            <Link to={`/${languageDetailsData?.Language_Code}/track/${store?.slug}`}><MessageBox classname='playCardTitle py-2 theme-text-color' text={store?.title} /></Link>
            <MessageBox classname='playCardDescription theme-text-color opacity-75' text={store?.description} />
            <PlayButton type="AUDIO" store={store} />
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.musicLanguage.languageDetailsData
})
export default connect(mapStateToProps)(MusicBasedWriterCard)