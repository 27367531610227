import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MusicUpdateAction } from '../Redux/Actions/MusicUpdateAction';
import { MusicCurrentAction } from '../Redux/Actions/MusicCurrentAction';
import { MusicPlayQueueAction } from '../Redux/Actions/MusicPlayQueueAction';
import { OPEN_PURCHASE_MODAL, PLAYLIST_SUCCESS } from '../Redux/ActionTypes';
const MyContext = createContext();
const MyProvider = ({ children, currentAudio, currentTimes, currentRepeat, currentIndex, playlists, currentPlaying, playlistShow, page, languageDetailsData, minimizePlayer, playlistData, actionType, getMusicData }) => {

    const dispatch = useDispatch()
    const audioRef = useRef(new Audio());
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [searchValue, setSearchValue] = useState(null)
    const navigate = useNavigate()

    const prograssBarPercentage = (currentTime / 1000 / duration) * 100 || 0;

    const handlePlayPause = () => {
        if (audioRef.current.readyState !== null && audioRef.current.readyState > 2) {
            if (audioRef.current.paused) {
                audioRef.current.play();
                dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: true })
            } else {
                audioRef.current.pause();
                dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: false });
            }
        }
    };

    //custom audio action
    const playNextLoop = async () => {
        const nextIndex = (currentIndex + 1) % playlists?.length;
        if (playlists?.length !== currentIndex + 1) {
            const nextAudio = playlists?.[nextIndex]
            if (nextAudio?.audio_access) {
                await dispatch(MusicCurrentAction(nextIndex, playlists));
                await dispatch(MusicUpdateAction('AUDIO', playlists?.[nextIndex]?.slug))
            }
            else {
                dispatch({ type: OPEN_PURCHASE_MODAL, payload: true, upComingTitle: nextAudio })
                audioRef.current.pause();
                audioRef.current.currentTime = 0
                dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: false });
                dispatch({ type: "GET_CURRENT_TIME_INDEX", currentTime: 0 });
                // setTimeout(async () => {
                //     await dispatch(MusicCurrentAction(nextIndex + 1, playlists));
                //     await dispatch(MusicUpdateAction('AUDIO', playlists?.[nextIndex + 1]?.slug))
                //     dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: true });
                //     audioRef.current.play()
                //     dispatch({ type: "GET_CURRENT_TIME_INDEX", currentTime: 0 });
                //     dispatch({ type: OPEN_PURCHASE_MODAL, payload: false, upComingTitle: null })
                // }, 6000)
            }
        }
        else {
            if (currentRepeat == "enabled") {
                await dispatch(MusicCurrentAction(0, playlists));
                await dispatch(MusicUpdateAction('AUDIO', playlists?.[0]?.slug))
                if (audioRef.current && audioRef.current.readyState > 2) {
                    audioRef.current.currentTime = 0;
                    audioRef.current.pause();
                    dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: true });
                    dispatch({ type: "GET_CURRENT_TIME_INDEX", currentTime: 0 });
                    setTimeout(() => {
                        audioRef.current.play().catch(error => {
                            console.error("Failed to play audio:", error);
                        });
                    }, 10);
                }
            }
            else {
                audioRef.current.pause();
                audioRef.current.currentTime = 0
                dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: false });
                dispatch({ type: "GET_CURRENT_TIME_INDEX", currentTime: 0 });
            }
        }
    };

    const handlePlayNext = async () => {
        const nextIndex = currentIndex + 1;
        if (nextIndex < playlists?.length) {
            const nextAudio = playlists?.[nextIndex]
            if (nextAudio?.audio_access) {
                console.log("next audio acess ")
                if (audioRef.current) {
                    await dispatch(MusicCurrentAction(nextIndex, playlists, 0));
                    dispatch(MusicUpdateAction("AUDIO", playlists?.[nextIndex]?.slug))
                    if (audioRef.current.readyState > 2) {
                        audioRef.current.play();
                    }
                    else {
                        audioRef.current.pause();
                    }
                }
            }
            else {
                console.log("no access")
                dispatch({ type: OPEN_PURCHASE_MODAL, payload: true, upComingTitle: nextAudio })
            }
        }
    };

    const handlePlayPrev = () => {
        const nextIndex = currentIndex - 1;
        if (nextIndex >= 0) {
            const prevAudio = playlists?.[nextIndex]
            if (prevAudio?.audio_access) {
                if (audioRef.current) {
                    dispatch(MusicCurrentAction(nextIndex, playlists, 0));
                    dispatch(MusicUpdateAction('AUDIO', playlists?.[nextIndex]?.slug))
                    if (audioRef.current.readyState > 2) {
                        audioRef.current.play();
                    }
                }
            }
            else {
                dispatch({ type: OPEN_PURCHASE_MODAL, payload: true, upComingTitle: prevAudio })
            }
        }
    };

    const handleSeek = (newTime) => {
        if (getMusicData?.enable_progress_bar) {
            const currentMilliSeconds = newTime * 1000;
            setCurrentTime(currentMilliSeconds);
            audioRef.current.currentTime = newTime;
        }

    };

    const repeatStates = {
        disabled: 'enabled',
        enabled: 'currentloop',
        currentloop: 'disabled'
    };

    const handleEnableLoop = () => {
        dispatch({ type: "GET_REPEAT_SUCCESS", isRepeat: repeatStates[currentRepeat] });
    };

    const handleVolume = () => {
        setVolume((prevVolume) => (prevVolume == 1 ? 0 : 1));
    };

    const handlePlay = (type, slug, id) => {

        const isSpecialType = ["PLAYLIST", "ARTIST", "ALBUM", "STATION", "GENRE"].includes(type);

        if (!isSpecialType) {
            if (currentAudio?.id !== id) {
                dispatch(MusicPlayQueueAction(type, slug));
                if (!playlistShow) dispatch({ type: "OPEN_PLAYLIST", payload: true });
            } else {
                handlePlayPause();
            }
        } else {
            if (type !== actionType || playlistData?.id !== id) {
                dispatch(MusicPlayQueueAction(type, slug));
                if (!playlistShow) dispatch({ type: "OPEN_PLAYLIST", payload: true });
            } else {
                handlePlayPause();
            }
        }
        dispatch({ type: "GET_ACTION_TYPE", payload: type });
    }

    //initial audio action
    const handleTimeUpdate = () => {
        const currentMilliSeconds = audioRef.current.currentTime * 1000;
        setCurrentTime(currentMilliSeconds);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const onAudioEnded = () => {
        if (currentRepeat === "currentloop") {
            if (audioRef.current && audioRef.current.readyState > 2) {
                audioRef.current.currentTime = 0;
                audioRef.current.pause();
                dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: true });
                dispatch({ type: "GET_CURRENT_TIME_INDEX", currentTime: 0 });
                setTimeout(() => {
                    audioRef.current.play().catch(error => {
                        console.error("Failed to play audio:", error);
                    });
                }, 10);
            }
        } else {
            playNextLoop();
        }
    };

    const minimizePlayerToggle = () => {
        dispatch({ type: "MINIMIZE_PLAYER", payload: !minimizePlayer })
        dispatch({ type: "OPEN_PLAYLIST", payload: minimizePlayer })
    }

    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        if (e.target.value.length === 0) {
            navigate(`/${languageDetailsData?.Language_Code}`);
        } else {
            navigate(`/${languageDetailsData?.Language_Code}/search`);
        }
    }
    const shufflePlaylist = () => {
        const shuffledPlaylists = [...playlists];
        for (let i = shuffledPlaylists.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledPlaylists[i], shuffledPlaylists[j]] = [shuffledPlaylists[j], shuffledPlaylists[i]];
        }
        dispatch({ type: PLAYLIST_SUCCESS, payload: shuffledPlaylists });
    };


    useEffect(() => {
        if (currentAudio && Object.keys(currentAudio).length > 0 && !currentAudio?.audio_access) {
            dispatch({ type: OPEN_PURCHASE_MODAL, payload: true, upComingTitle: currentAudio })
        }
        if (audioRef.current.readyState > 2 && currentPlaying) {
            audioRef?.current?.play()
        } else {
            audioRef?.current?.pause()
        }
    }, [currentAudio?.id, audioRef.current.readyState]);

    useEffect(() => {
        if (currentTime !== null) {
            setCurrentTime(currentTimes);
            audioRef.current.currentTime = currentTimes / 1000
        }
    }, [])

    useEffect(() => {
        audioRef.current.volume = volume;
    }, [volume]);

    useEffect(() => {
        if (currentTime !== null) {
            dispatch({ type: "GET_LYRIC_SUCCESS", currentTimes: currentTime, });
        }
    }, [currentTime]);

    useEffect(() => {
        if (playlistShow && page !== "home") {
            navigate(`/${languageDetailsData?.Language_Code}/track/${currentAudio?.slug}`)
        }
    }, [currentAudio?.id])

    useEffect(() => {
        if (languageDetailsData?.Language_Code === 'ar') {
            document.body.setAttribute('dir', 'rtl');
            document.body.classList.add('rtl');
        } else {

            document.body.removeAttribute('dir', 'rtl');
            document.body.classList.remove('rtl');
        }
    }, [languageDetailsData])



    return <MyContext.Provider value={{ shufflePlaylist, handlePlayPause, handlePlayNext, handlePlayPrev, handleVolume, handleSeek, handleEnableLoop, minimizePlayerToggle, handlePlay, handleSearch, setSearchValue, searchValue, prograssBarPercentage, duration, currentTime, readyState: audioRef?.current.readyState, audioReference: audioRef, volume }}>
        <audio id="musicPlayerId" ref={audioRef} src={currentAudio?.audio_access ? currentAudio?.mp3_url : ""} onTimeUpdate={handleTimeUpdate} onLoadedMetadata={handleLoadedMetadata} onEnded={onAudioEnded} />
        {children}</MyContext.Provider>;
}

const mapStateToProps = state => ({
    currentAudio: state.currentMusic.currentAudio,
    currentTimes: state.currentMusic.currentTime,
    currentRepeat: state.currentMusic.currentRepeat,
    playlists: state.playlists.playlists,
    isLoading: state.toggleState.isLoading,
    error: state.toggleState.error,
    currentIndex: state.currentMusic.currentIndex,
    currentPlaying: state.currentMusic.currentPlaying,
    playlistShow: state.toggleState.playlistShow,
    page: state.toggleState.page,
    languageDetailsData: state.musicLanguage.languageDetailsData,
    minimizePlayer: state.toggleState.minimizePlayer,
    playlistData: state.currentMusic.playlistData,
    actionType: state.currentMusic.actionType,
    getMusicData: state.musicSettings.getMusicData,

});
export default connect(mapStateToProps)(MyProvider);

export function useMyContext() {
    return useContext(MyContext);
}