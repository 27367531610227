import React from 'react'

const EmptyPlaylistLoader = () => {
    return (
        <div className='d-flex align-items-center playlistLoader justify-content-between mb-3 px-3'>
            <div className='d-flex align-items-center'>
                <div className='emptyImageLoader square rounded-2'></div>
                <div className='ps-3'>
                    <p className='square rounded-1'></p>
                    <p className='square rounded-1'></p>
                </div>
            </div>
            <div className='d-flex align-items-center gap-3'>
                <span className='square rounded-circle'></span>
                <span className='square rounded-circle'></span>
                <span className='square rounded-circle'> </span>
            </div>

        </div>
    )
}

export default EmptyPlaylistLoader