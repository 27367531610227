import React from 'react'
import MessageBox from '../MessageBox'


const ButtonWithLoader = ({ action, text, loading, className,buttonType }) => {
    return (
        <button className={`${className ? className : 'px-4 py-2 rounded-2'}`} type={`${buttonType ? buttonType : "button"}`} onClick={action} disabled={loading}>{loading ? <div className='custom-loading'></div> : <MessageBox text={text} classname='playlistSub' />} </button>
    )
}

export default ButtonWithLoader