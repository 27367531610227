import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import LazyLoadImage from '../LazyLoadImage'
import MessageBox from '../MessageBox'

const MusicCategoryCard = ({ store, languageDetailsData }) => {
    return (
        <div className='rounded-2 w-100 h-100  position-relative theme-bg-color-secondary '>
            <LazyLoadImage key={store?.name} src={store?.image_url} alt={store?.name} title={store?.name} imageSize="rounded-2  overflow-hidden  object-fit-contain" classNameOptional="rounded-2 " />
            <Link to={`/${languageDetailsData?.Language_Code}/genre/${store?.slug}`} className='theme-text-color position-absolute musicCategoryTitle '> <MessageBox text={store?.name} /></Link>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.musicLanguage.languageDetailsData
})

export default connect(mapStateToProps)(MusicCategoryCard)