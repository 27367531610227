import React from 'react'
import MessageBox from '../MessageBox'
import { connect, useDispatch } from 'react-redux'
import { getItem, getItemToken } from '../../../Utils/localStorageUtils'
import { MusicDetailsAction } from '../../../Redux/Actions/MusicDetailsAction'
import { MusicViewAllAction } from '../../../Redux/Actions/MusicViewAllAction'
import { MusicUpdateAction } from '../../../Redux/Actions/MusicUpdateAction'
import { artistFollowUpate } from '../../../Config/Api/MusicApi'
import axios from 'axios'
import { apiGenerate, demoUser } from '../../../Method/CommonMethod'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const FollowButton = ({ artistDetails, pageType, current, getMusicData }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const userFollowTheArtist = async (artistDetails, pageType, current) => {
        try {
            const response = await axios.post(`${artistFollowUpate}`, {
                Login_user_type: parseInt(getItem('role'), 10) || demoUser.userRole,
                Login_device_type: "web",
                Login_user_id: parseInt(getItem('user_id'), 10) || demoUser?.userId,
                source_id: artistDetails?.id ? artistDetails?.id : artistDetails?.artist_id,
                source_type: 5
            }, { headers: getItemToken('access_token') || null });
            if (response.data.status) {
                toast.success(t(response.data.message));
                if (pageType == "DETAILS") {
                    dispatch(MusicDetailsAction('ARTIST', artistDetails?.artist_slug))
                }
                else if (pageType == "MUSICIAN") {
                    dispatch(MusicViewAllAction("AUDIO", apiGenerate(pageType, 'pagelistApi')));
                }
                else if (pageType == "ARTIST") {
                    dispatch(MusicViewAllAction("AUDIO", apiGenerate(pageType, 'pagelistApi')));
                }
                else if (pageType == "WRITER") {
                    dispatch(MusicViewAllAction("AUDIO", apiGenerate(pageType, 'pagelistApi')));
                }
                else if (pageType == "CURRENT") {
                    dispatch(MusicUpdateAction('AUDIO', current))
                }
            } else {
                toast.error(t(response.data.message));
            }
        } catch (error) {
            toast.error(t(error?.response?.data?.message));
        }
    }

    return (
        <button className={`musicFollowBtn ${pageType == "CURRENT" ? getMusicData?.enable_embed ? 'theme-border-color-reverse' : 'theme-border-secondary' : 'audioBorder'}  px-3 py-2 ${artistDetails?.user_follow_artist_exist || artistDetails?.is_following ? "theme-button-bg-color" : "bg-transparent inActive theme-text-color"} `} onClick={() => userFollowTheArtist(artistDetails, pageType, current)}><MessageBox text={artistDetails?.user_follow_artist_exist || artistDetails?.is_following  ? "Following" : "Follow"} classname='' /></button>
    )
}

const mapStateToProps = state => ({
    getMusicData: state.musicSettings.getMusicData,
});
export default connect(mapStateToProps)(FollowButton)
