import { OPEN_PLAYLIST, OPEN_PLAYLIST_MODAL, PLAYLIST_CREATE_PAGE, OPEN_STATION_MODAL, CHOOSE_STATION_PAGE, CLOSE_PLAYER, OPEN_PURCHASE_MODAL, MINIMIZE_PLAYER, LAYOUT_SWITCHER, AUTH_MODAL_SIGNIN, AUTH_MODAL_SIGNUP } from "../ActionTypes";

export const ToggleReducer = (state = { authModalSignIn: false, authModalSignUp: false, playlistShow: false, playlistModal: false, createModal: false, stationModal: false, chooseStation: false, next: false, purchaseModal: false, page: "home", upComingTitle: "", minimizePlayer: false, layoutSwitcher: "grid" }, action) => {
    switch (action.type) {
        case OPEN_PLAYLIST:
            return {
                ...state,
                playlistShow: action.payload,
            };
        case OPEN_PLAYLIST_MODAL:
            return {
                ...state,
                playlistModal: action.payload,
            };
        case PLAYLIST_CREATE_PAGE:
            return {
                ...state,
                createModal: action.payload,
            };

        case OPEN_STATION_MODAL:
            return {
                ...state,
                stationModal: action.payload,
            };

        case CHOOSE_STATION_PAGE:
            return {
                ...state,
                chooseStation: action.payload,
            };

        case OPEN_PURCHASE_MODAL:
            return {
                ...state,
                purchaseModal: action.payload,
                upComingTitle: action.upComingTitle
            };

        case MINIMIZE_PLAYER:
            return {
                ...state,
                minimizePlayer: action.payload,
            };

        case LAYOUT_SWITCHER:
            return {
                ...state,
                layoutSwitcher: action.payload,
            };
        case AUTH_MODAL_SIGNIN:
            return {
                ...state,
                authModalSignIn: action.payload,
            };
        case AUTH_MODAL_SIGNUP:
            return {
                ...state,
                authModalSignUp: action.payload,
            };

        case "NEXT":
            return {
                ...state,
                next: action.payload,
            };

        case CLOSE_PLAYER:
            return {
                ...state,
                page: action.payload,
            };

        default:
            return state;
    }
};
