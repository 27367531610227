import React, { useEffect, useState } from "react";
import { FaCrown } from "react-icons/fa";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const PriceLabel = ({ item, thumbnailFreeORCostLabel, className }) => {
    const { t } = useTranslation();

    const [userCurrency, setUserCurrency] = useState('');
    const [convertedPrice, setConvertedPrice] = useState({ ppv_price: null, global_ppv: null, video_ppv_price: null });
    const [currency, setCurrency] = useState({ code: '', country: '', symbol: '', enable_multi_currency: '' });
    const [locationData, setLocationData] = useState({
        city: '',
        region: '',
        country: '',
        latitude: '',
        longitude: '',
        ip: '',
        symbol: '',
        code: '',
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCurrencyData = async () => {
            try {
                const currencyResponse = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Currency-Index`);
                const currencyData = currencyResponse?.data;
                setCurrency(currencyData?.CurrencySetting);
            } catch (error) {
                console.error('Error fetching currency data:', error);
            }
        };

        const fetchLocationData = async () => {
            try {
                const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
                const { city, region, country_code: country, IPv4: ip, latitude, longitude, state, postal } = locationResponse?.data;

                // console.log(city, region, country, ip, latitude, longitude, state, postal, " city, region, country_code: country, ip, latitude, longitude ")

                // Ensure country code is defined before making the API request
                if (country) {
                    const currencyResponse = await axios.get(`${process.env.REACT_APP_country_code}/${country}`);
                    const symbol = currencyResponse?.data[0]?.currencies[Object.keys(currencyResponse?.data[0]?.currencies)[0]].symbol;
                    const code = Object.keys(currencyResponse?.data[0]?.currencies)[0];
                    setLocationData({ city, region, country, latitude, longitude, ip, symbol, code });
                    // console.log(city, region, country, latitude, longitude, ip, symbol, code, "{ city, region, country, latitude, longitude, ip, symbol, code }");
                    setUserCurrency(code);
                } else {
                    console.error('Country code is undefined.');
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCurrencyData();
        fetchLocationData();
    }, []);

    useEffect(() => {
        const fetchConversionRate = async () => {
            if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
                const data = response.data;
                const conversionRate = data.rates[userCurrency];
                if (conversionRate) {
                    const convertedPPVPrice = item?.ppv_price ? item?.ppv_price * conversionRate : null;
                    const convertedGlobalPPV = item?.global_ppv ? item?.global_ppv * conversionRate : null;
                    const convertedVideoPPVPrice = item?.video_ppv_price ? item?.video_ppv_price* conversionRate : null;
                    setConvertedPrice({ ppv_price: convertedPPVPrice, global_ppv: convertedGlobalPPV,video_ppv_price:convertedVideoPPVPrice});
                } else {
                    console.error('Conversion rate not found for currency:', userCurrency);
                }
            } catch (error) {
                console.error('Error fetching conversion rate:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchConversionRate();
    }, [userCurrency, currency?.code, currency?.enable_multi_currency, item?.ppv_price, item?.global_ppv]);

    const renderPriceLabel = () => {
        if (loading) {
            return <p className="priceText d-flex align-items-center">{t("Loading...")}</p>;
        }

        if (item?.access === "guest") {
            return <p className="priceText d-flex align-items-center mb-0">{t("Free")}</p>;
        } else if (item?.access === "subscriber") {
            return <p className="priceText d-flex align-items-center mb-0"><FaCrown style={{ width: "18px", height: "18px", color: "gold" }} /></p>;
        } else if (item?.access === "registered") {
            return <p className="priceText d-flex align-items-center mb-0">{t("Registered")}</p>;
        } else if (currency?.enable_multi_currency === 1 && convertedPrice) {
            // const foundCurrency = allcurrency?.find(curr => curr.code === userCurrency);
            const symbol = locationData?.symbol || '';
            if (convertedPrice?.ppv_price !== null) {
                return <p className="priceText d-flex align-items-center mb-0">{symbol} {Number(convertedPrice?.ppv_price).toFixed(2)}</p>;
            } else if (convertedPrice?.global_ppv !== null) {
                return <p className="priceText d-flex align-items-center mb-0">{symbol} {Number(convertedPrice?.global_ppv).toFixed(2)}</p>;
            } else if (convertedPrice?.video_ppv_price !== null) {
                return <p className="priceText d-flex align-items-center mb-0">{symbol} {Number(convertedPrice?.video_ppv_price).toFixed(2)}</p>;
            }
        } else if (currency?.enable_multi_currency === 0) {
            const symbol = currency?.symbol;
            if (item?.ppv_price !== null) {
                return <p className="priceText d-flex align-items-center mb-0">{symbol} {Number(item?.ppv_price).toFixed(2)}</p>;
            } else if (item?.global_ppv !== null) {
                return <p className="priceText d-flex align-items-center mb-0">{symbol} {Number(item?.global_ppv).toFixed(2)}</p>;
            } else if (item?.video_ppv_price !== null) {
                return <p className="priceText d-flex align-items-center mb-0">{symbol} {Number(item?.video_ppv_price).toFixed(2)}</p>;
            }
        }
    };

    return (
        <>
            {thumbnailFreeORCostLabel ? (
                <div className={`${className ? className : 'position-absolute priceLabelRadius theme-button-bg-color theme-button-text-color '} priceLabel`} >
                    {renderPriceLabel()}
                </div>
            ) : ""}
        </>
    );
};

const mapStateToProps = state => ({
    thumbnailFreeORCostLabel: state.commonThumbnail.thumbnailFreeORCostLabel
});

export default connect(mapStateToProps)(PriceLabel);