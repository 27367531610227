import React, { useState } from 'react'
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaCheck, FaXmark } from "react-icons/fa6";
import MessageBox from '../MessageBox';
import LazyLoadImage from '../LazyLoadImage';
import PlayButton from '../Button/PlayButton';

const MusicViewCard = ({ store, methodAction, languageDetailsData }) => {
    const { source } = useParams();

    const [deleteTrigger, setDeleteTrigger] = useState({
        modal: false,
        deleteId: null
    })

    const propsType = source == "playlist" ? "PLAYLIST" : source == "musician" || source == "song-writer" || source == "artists" ? "ARTIST" : source == "audio-albums" ? "ALBUM" : source == "music-station" ? "STATION" : source == "audio-genre" ? "GENRE" : "AUDIO"
    let navigateTrack = source == "playlist" ? "playlist" : source == "musician" || source == "song-writer" || source == "artists" ? "artist" : source == "audio-albums" ? "album" : source == "music-station" ? "station" : source == "audio-genre" ? "genre" : "track"

    const handleDeletePlayList = (deleteId) => {
        setDeleteTrigger((prev) => ({
            modal: true,
            deleteId: deleteId
        }))
    }
    const handleDeleteAction = (deleteId) => {
        methodAction(deleteId)
        setDeleteTrigger((prev) => ({
            modal: false,
            deleteId: null
        }))
    }
    const handleCloseAction = () => {
        setDeleteTrigger((prev) => ({
            modal: false,
            deleteId: null
        }))
    }

    return (
        <div className={`p-2 rounded-2 w-100 h-100 theme-border-secondary position-relative viewAllMusic ${store?.description && "viewAllMusicHeight"} `}>
            {deleteTrigger?.modal && <div className='position-absolute musicPlayconfirmDeleteBox theme-bg-color px-2 py-1'>
                <MessageBox text={`Are you sure delete ?`} classname="theme-text-color d-block text-center deleteTextPlaylist"></MessageBox>
                <div className='d-flex align-items-center justify-content-between'>
                    <button type='button' className='bg-transparent p-0' onClick={handleCloseAction}><FaXmark className='theme-text-color deleteIconPlaylist ' /></button>
                    <button type='button' className='bg-transparent p-0' onClick={() => handleDeleteAction(deleteTrigger?.deleteId)}><FaCheck className='theme-text-color deleteIconPlaylist ' /></button>
                </div>
            </div>}
            <LazyLoadImage key={store?.title ? store?.title : store.name ? store?.name : store?.albumname ? store?.albumname : store?.artist_name} src={store?.Player_image_url ? store?.Player_image_url : store?.image_url} alt={store?.title ? store?.title : store.name ? store?.name : store?.albumname ? store?.albumname : store?.artist_name} title={store?.title ? store?.title : store.name ? store?.name : store?.albumname ? store?.albumname : store?.artist_name} imageSize="rounded-2  overflow-hidden musicImage object-fit-cover" classNameOptional="rounded-2 musicImageHeight" />
            <Link to={`/${languageDetailsData?.Language_Code}/${navigateTrack}/${store?.slug ? store?.slug : store?.artist_slug}`}><MessageBox classname={`playCardTitle  theme-text-color ${store?.description ? "py-2" : "pt-2"}`} text={store?.title ? store?.title : store?.name ? store?.name : store?.albumname ? store?.albumname : store?.artist_name} /></Link>
            <MessageBox classname='playCardDescription theme-text-color opacity-75' text={store?.description} />
            {source == "playlist" && <AiOutlineDelete className='playListDeleteButton theme-text-color theme-bg-color' onClick={() => handleDeletePlayList(store?.id)} />}
            {source == "music-station" && <AiOutlineDelete className='playListDeleteButton theme-text-color theme-bg-color' onClick={() => handleDeletePlayList(store?.id)} />}
            <PlayButton type={propsType} store={store} />
        </div>
    )
}

const mapStateToProps = state => ({
    currentToggle: state.currentMusic.currentToggle,
    languageDetailsData: state.musicLanguage.languageDetailsData,
    currentUserInteract: state.currentMusic.currentUserInteract,
})
export default connect(mapStateToProps)(MusicViewCard)