import React from 'react'
import "./Header.css"
import { IconMyBell, IconMyGear, IconMyHeart, IconMyNextArrow, IconMyPrevArrow, IconMySave, IconMySearch, IconMyUser } from '../../../Assets/Icons/IconComponents/MyIcons'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import MessageBox from '../../CommonComponents/MessageBox'
import LazyLoadImage from '../../CommonComponents/LazyLoadImage'
import UserLogo from "../../../Assets/Images/user.jpg"
import { getItem } from '../../../Utils/localStorageUtils'
import { AUTH_MODAL_SIGNIN } from '../../../Redux/ActionTypes'
import MusicLogo from "../../../Assets/Images/musiclogo.png"
import { useMyContext } from '../../../Context/CommonProvider'

const Header = ({ languageDetailsData, profileDetails, toggleSideBar }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const { handleSearch, searchValue } = useMyContext()

  const goBack = () => {
    navigate(-1);
  };
  const goForward = () => {
    navigate(1);
  };

  const userRoleLocal = getItem('role')

  const handleLoginPopup = () => {
    dispatch({ type: AUTH_MODAL_SIGNIN, payload: true })
  }

  return (
    <div className="navbarWrapper w-100 align-items-center justify-content-between py-3 px-md-4 px-2 mb-md-2 d-flex ">
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center justify-content-between  d-none d-md-block '>
          <button type='button' className='bg-transparent border-0 p-0 rounded-circle musicGoBackBtn navigationPrev theme-text-color' onClick={goBack} disabled={location.pathname === `/${languageDetailsData?.Language_Code}`}><IconMyPrevArrow styled={{ width: "30px", height: "30px", className: "" }} /></button>
          <button type='button' className='bg-transparent border-0 p-0 rounded-circle theme-text-color navigationNext' onClick={goForward}><IconMyNextArrow styled={{ width: "30px", height: "30px", className: "" }} /></button>
        </div>
        <Link to={`/${languageDetailsData?.Language_Code}`} className="d-md-none d-block ">
          <LazyLoadImage key={'logo'} src={MusicLogo} alt={'logo'} title={'M'} imageSize="rounded-2  overflow-hidden musicLogoImage object-fit-contain " classNameOptional="rounded-circle musicLogoImageHeight  d-flex align-items-center  justify-content-center mx-auto " />
        </Link>
        <div className='position-relative d-none d-sm-block'>
          <input type="text" onChange={handleSearch} value={searchValue} className="musicSearch py-2 pe-2  theme-border-color rounded-4 bg-transparent  theme-text-color" placeholder='Search for songs, artist, etc' style={{ borderWidth: "2px", borderStyle: "solid" }} />
          <IconMySearch styled={{ width: "20px", height: "20px", className: "position-absolute searchIconHeader", left: "26px", top: "13px" }} />
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center justify-content-between me-5 gap-4'>
          {/* <IconMyBell styled={{ width: "20px", height: "20px", className: "" }} /> */}
          <NavLink to={`/${languageDetailsData?.Language_Code}/wishlist`} className={``}><IconMySave styled={{ width: "20px", height: "20px", className: "" }} /></NavLink>
          <NavLink to={`/${languageDetailsData?.Language_Code}/user/settings`} className={``}><IconMyGear styled={{ width: "20px", height: "20px", className: "" }} /></NavLink>
        </div>
        <Link to={'plan'} className='btn ms-5 theme-button-bg-color d-none d-lg-block  me-4 px-4'><MessageBox text="Purchase" classname='theme-button-text-color' /></Link>
        {userRoleLocal ?
          <div className='position-relative' >
            <button onClick={toggleSideBar} className='profileToggle bg-transparent'></button>
            <Link to={`/${languageDetailsData?.Language_Code}/user/profile`} className={`profileNavigation `}> </Link>
            <LazyLoadImage key={'logo'} src={profileDetails?.Profile_details?.user_avatar} alt={'logo'} title={profileDetails?.Profile_details?.username.slice(0, 1)} imageSize="rounded-2 overflow-hidden musicLogoImageUser object-fit-cover" classNameOptional="rounded-circle musicLogoImageUser" />
          </div>
          : <button onClick={() => handleLoginPopup()} className='bg-transparent p-0'><IconMyUser styled={{ width: "20px", height: "20px", className: "theme-text-color" }} /></button>}
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  languageDetailsData: state.musicLanguage.languageDetailsData,
  profileDetails: state.profileDetails.profileDetails,
})


export default connect(mapStateToProps)(Header)